import Theme from "./theme.json"

export const ORIGIN =
  process.env.REACT_APP_ORIGIN || "https://www.voceqpad.com.br"
export const STORE_NAME = process.env.REACT_APP_STORE_NAME || "VocêQpad Teste"
export const DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://app-voceqpad-stage.azurewebsites.net/api/api/"
  // "http://voceqpad-dev.azurewebsites.net/api/api/"
  // "https://www.voceqpad.com.br/api/api/"
  // "https://voceqpad-staging.azurewebsites.net/api/api/"
export const ESTABELECIMENTO_ID = process.env.REACT_APP_ESTABELECIMENTO_ID || 10
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyBUKUg5XlcJFnqSQ1R8phCnbRe7owwMVYI"
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || "824946647884421"
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "82003366480-siij1o32fe9vrfse8s2koekdllq9dsda.apps.googleusercontent.com"
export const GTM_KEY = process.env.REACT_APP_GTM_KEY || "false"
export const APP_ID = process.env.REACT_APP_APP_ID || 11
export const PLATAFORM_ID = process.env.REACT_APP_PLATAFORM_ID || 3
export const ANDROID_APP_URL = process.env.REACT_APP_ANDROID_APP_URL
export const IPHONE_APP_URL = process.env.REACT_APP_IPHONE_APP_URL
export const WEBSITE_QRCODE_URL = process.env.REACT_APP_WEBSITE_QRCODE_URL
export const MUNDIPAGG_PUBLIC_KEY = process.env.REACT_APP_MUNDIPAGG_PUBLIC_KEY || "pk_nwBRe4zcBs1qba2d"
export const THEME = Theme
export const MULTI_STORE = process.env.REACT_APP_MULTI_STORE || "false"
export const CARDAPIO_CONSULTA = process.env.REACT_APP_CARDAPIO_CONSULTA || "false"
export const GELADEIRA_PEGUEEPAGUE = process.env.REACT_APP_GELADEIRA_PEGUEEPAGUE || "false"
export const BALCAO = process.env.REACT_APP_BALCAO || "false"
export const MESA = process.env.REACT_APP_MESA || "false"
export const TERMS_OF_USE = process.env.REACT_APP_TERMS_OF_USE || "http://institucional.voceqpad.com.br/termoscondicoes.html"
