import React from 'react'
import { connect } from "react-redux"
import styled from 'styled-components';

import { device } from '../../constants/responsive'

import { LoadingOverlay } from '../common'

import { Modal, Button } from '../common'

import { toggleErrorProductsModal } from '../../redux/actions/ui'

import {
  resetCart,
  removeProductsError
} from '../../redux/actions/cart'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 35px;
  min-width: 320px;

  @media ${ device.desktop } {
  	min-width: 440px;
  }
`

const Title = styled.h1`
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  border-bottom: #d2d2d2 1px solid;
  min-width: 100%;
  text-align: center;
	margin: 0px;
	padding: 0px;
  margin-bottom: 30px;
  padding-bottom: 20px;
`

const List = styled.ul`
  flex: 1;
  padding: 10px;
  color: #000
  text-align: left;
  margin-bottom: 30px;
`

const ListItem = styled.li`
  font-size: 16px;
  font-weight: bold;
`

const Paragraph = styled.p`
  flex: 1;
  font-size: 14px;
  color: #5e5e5e;
  min-width: 100%;
  margin-bottom: 20px;
`

const ButtonRemove = styled(Button)`
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
  flex: 1;
  min-width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`

const ButtonLimpar = styled(Button)`
  background: #FFF
  height: 40px;
  line-height: 40px;
  border: #fff 1px solid;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
  border-radius: 10px;
  flex: 1;
  min-width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`
class ErrorProductsModal extends React.Component {

  constructor(props) {
		super(props);
  }

  handleClose = () => {
    this.props.toggleErrorProductsModal(false)
  }

  clearCart = () => {
    this.props.resetCart()
    this.props.toggleErrorProductsModal(false)
  }
  removeProducts = (products) => {
    this.props.toggleErrorProductsModal(false)
    this.props.removeProductsError(products)
  }

  render() {
    const {error_items} = this.props
    return (
      <Modal name="address" isOpen={this.props.isOpen} onClose={this.handleClose} disableClose={true}>
        {/* <Notification type="error" text={this.state.error} onDismiss={this.dismissError} timeout={5} /> */}

        <Wrapper>
            <LoadingOverlay visible={this.props.loading} margin={"5px"} />

            <Title>Ops... Algo deu errado!</Title>
         
            <Paragraph>Infelizmente alguns itens selecionados estão indisponiveis!</Paragraph>
            <Paragraph>Para prosseguir, por favor, retire do carrinho o(s) produto(s) abaixo e tente novamente</Paragraph>

            <List>
              {
                 error_items && error_items.length > 0 && error_items.map( (item,i) => (
                   <ListItem>{item.Titulo}</ListItem>
                 ))
              }
            </List>

            <ButtonRemove className={"primary"} type="button" onClick={() => {
              this.removeProducts(error_items)
							}}>
								RETIRAR ITEM DO CARRINHO
						</ButtonRemove>

            <ButtonLimpar type="button" onClick={() => {
                this.clearCart()
							}}>
								LIMPAR CARRINHO
						</ButtonLimpar>


          </Wrapper>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    storeId: state.store && state.store.id,
    isOpen: state.ui.modals.errorProductsModal,
    currentState: state.ui.modals.addressModal.state,
    cepLoading: state.requests.searchCep.loading,
    saveAddressLoading: state.requests.saveAddress.loading,
    deliveryModality: state.cart.delivery.modality,
    deliveryTable: state.cart.delivery.table,
    address: state.requests.saveAddress && state.requests.saveAddress.address,
    error_items: state.cart.error_items
  }
}

export default connect(
  mapStateToProps, {
    toggleErrorProductsModal,
    resetCart,
    removeProductsError
  }
)(ErrorProductsModal)
