export default (val) =>
{ 
	let nCheck = 0, bEven = false;
	const formattedVal = val.replace(/\D/g, "");

	for (var n = formattedVal.length - 1; n >= 0; n--) {
		var cDigit = formattedVal.charAt(n),
			  nDigit = parseInt(cDigit, 10);

		if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

		nCheck += nDigit;
		bEven = !bEven;
	}

    if ((nCheck % 10) === 0) return true;
    else return 'número do cartão inválido'
} 