import React, {
    Fragment
} from 'react';
import styled from 'styled-components';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import Skeleton from 'react-loading-skeleton';
import { isMobile } from 'react-device-detect';

import { Divider } from '../common';
import { device } from '../../constants/responsive';
import { MenuProductCard } from '.';
import convertStringToUrl from '../../utils/convertStringToUrl';
import {CARDAPIO_CONSULTA } from "../../config";

const Wrapper = styled.div`
    background-color: white;
`

const NoProductsText = styled.div`
    font-size: 19px;
    color: ${props => props.theme.colors.textBold};
    padding-top: 40px;
    text-align: center;
`

const ListWrapper = styled.div`
    padding: 14px 0;
`

const ListName = styled.div`
    font-size: 24px;
    color: ${props => props.theme.colors.textBold};
    font-weight: 700;
    letter-spacing: -0.01em;

    @media ${device.desktop} {
        font-size: 20px;
    }
`

const AlternativeProductWrapper = styled.div`
    flex: 1;
    min-width: 50%;
`

const ProductWrapper = styled.div``

const AlternativeProductsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.desktop} {
        flex-direction: row;
        flex-wrap: wrap;
    }
`

const ProductsWrapper = styled.div``

const FIXED_MENU_HEIGHT = isMobile ? 120 : 255;

configureAnchors({offset: -FIXED_MENU_HEIGHT})

class MenuList extends React.Component {

    constructor(props) {
        super(props);
        let refs = [];
        if (props.categories) {
            props.categories.forEach( (list, i) => {
                refs[i] = React.createRef();
            })
        }
        this.lists = refs;
    }

    componentDidMount() {
        // window.addEventListener('scroll', this.handleScroll, true);
    }

    componentDidUpdate(){
       
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    addEventGTM = (args) =>{
        this.props.handleGTM(args)
    }

    handleScroll = () => {
        if (!this.props.categories) return;
        let currentScrolledElement = 0;
        this.props.categories.forEach( (c, i) => {
            const el = this.lists[i];
            if (el && el.getBoundingClientRect().top < FIXED_MENU_HEIGHT) {
                currentScrolledElement = i;
            }
        })
        if (this.props.currentList !== currentScrolledElement) {
            this.props.setNavCurrentList(currentScrolledElement);
        }
    }

    setRef = (ref, value) => {
        const lists = [...this.lists]
        lists[value] = ref;
        this.lists = lists;
    }

    renderLoading = () => {
        return (
            <Wrapper>
                <ListWrapper>
                    <ListName>
                        <span>
                            <Skeleton width={120} />
                        </span>
                    </ListName>
                    <Divider marginV={"15px"} />
                    <div>
                        <MenuProductCard />
                        <Divider marginV={"15px"} />
                        <MenuProductCard />
                        <Divider marginV={"15px"} />
                        <MenuProductCard />
                        <Divider marginV={"15px"} />
                    </div>
                </ListWrapper>
            </Wrapper>
        )
    }

    renderProduct = (product, j, products) => {
        const key = `product-${j}${(new Date()).getTime()}`
        const urlName = convertStringToUrl(product.name)
        return (
        <Fragment key={key}>
            {
                CARDAPIO_CONSULTA === "false" && (
                    <ProductWrapper>

                        <MenuProductCard product={product} isActive={this.props.queryProduct === urlName} />
                        { j < products.length - 1 && (
                            <Divider marginV={"15px"} />
                        )}
                    </ProductWrapper>
                )
            }

            {
                CARDAPIO_CONSULTA === "true" && (
                    <AlternativeProductWrapper key={key}>
                        <MenuProductCard product={product} isActive={this.props.queryProduct === urlName} />
                        { j < products.length && (
                            <Divider marginV={"15px"} />
                        )}
                    </AlternativeProductWrapper>
                )
            }
        </Fragment>
    )}

    renderCategory = (category, i) => {
        const key = `category-${i}${(new Date()).getTime()}`

        return (
            <ListWrapper key={key}>
                <ListName ref={(ref) => this.setRef(ref, i)}>
                    <ScrollableAnchor id={category.name}>
                        <span>
                            {category.name}
                        </span>
                    </ScrollableAnchor>
                </ListName>
                <Divider marginV={"15px"} />
                { CARDAPIO_CONSULTA === "false" && <ProductsWrapper>
                    {
                        category.products.map(this.renderProduct)
                    }
                </ProductsWrapper> }
                { CARDAPIO_CONSULTA === "true" && <AlternativeProductsWrapper>
                    {
                        category.products.map(this.renderProduct)
                    }
                </AlternativeProductsWrapper> }
            </ListWrapper>
        )
    }

    renderEmpty = () => {
        return (
            <Wrapper>
                <NoProductsText>
                    Produto não encontrado =(
                </NoProductsText>
            </Wrapper>
        )
    }

    render() {
        if (this.props.loading) return this.renderLoading();
        if (!this.props.categories || this.props.categories.length === 0) return this.renderEmpty();

        return (
            <Wrapper>
                {
                    this.props.categories && this.props.categories.map(this.renderCategory)
                }
            </Wrapper>
        )
    }
}

export default (MenuList);
