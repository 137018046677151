import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
    font-size: 18px;
    color: ${props => props.theme.colors.textPrimary};
    border-radius: 5px;
    border: ${props => props.theme.colors.textPrimary} 2px solid;
    height: 40px;
    flex: 1;
    padding: 0px;
    max-width: 50px;
    text-align: center;
    margin-right: 5px;

    &:last-child {
        margin-right: 0px;
    }
`

const Paragraph = styled.p`
    font-size: 18px;
    color: #F33;
    flex: 1;
`

const Wrapper = styled.div`
    min-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    padding: 0px 15px;
`

class SMSCodeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            code: '',
            char0: '',
            char1: '',
            char2: '',
            char3: '',
            char4: '',
            char5: '',
            charDirection: true,
            error: null
        }

        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.char0.focus()
    }

    handleChange = async (event) => {
        event.persist()

        const {
            value,
            name
        } = event.target

        const positions = name.split('char')
        const position = Number(positions[1])

        const newState = {
            [`char${position}`]: value
        }

        await this.setState({
            ...newState
        })

        if (position > 0 && !this.state.charDirection) {
            this[`char${position - 1}`].focus()
        }

        if (position < 5 && !!this.state.charDirection) {
            this[`char${position + 1}`].focus()
        }

        this.setState({
            charDirection: true
        })

        if (position === 5 && this.state.char0 && this.state.char1 && this.state.char2 && this.state.char3 && this.state.char4 && this.state.char5) {
            const code = `${this.state.char0}${this.state.char1}${this.state.char2}${this.state.char3}${this.state.char4}${this.state.char5}`

            this.props.setCode(code)
            this.props.form.form.current.dispatchEvent(new Event('submit', { cancelable: true }))

            this.char5.blur()
            
            await this.setState({
                char0: '',
                char1: '',
                char2: '',
                char3: '',
                char4: '',
                char5: '',
                code
            })
        }
    }

    handleKey = (event) => {
        const {
            keyCode,
            target
        } = event

        const position = target.name.split('char')

        if (keyCode === 8 || keyCode === 46) {
            this.setState({
                charDirection: false
            })
        }

        if ((keyCode === 8 || keyCode === 46) && position[1] > 0 && !this.state.charDirection) {
            this[`char${Number(position[1]) - 1}`].focus()
        }

        const allowedKeys = [8, 37, 38, 39, 40, 46, 18, 35, 36]

        if (allowedKeys.indexOf(keyCode) >= 0) {
            return false
        }

        const number = Number(event.key)

        if (typeof number !== 'number' || isNaN(number)) {
            event.preventDefault()
        }
    }

    render() {
        const disabled = this.state.loading

        return (
            <>
                <Wrapper>
                    <Input disabled={disabled} ref={(input) => { this.char0 = input }} maxLength={1} name="char0" id="char0" value={this.state.char0} onChange={this.handleChange} onKeyDown={this.handleKey} />

                    <Input disabled={disabled} ref={(input) => { this.char1 = input }} maxLength={1} name="char1" id="char1" value={this.state.char1} onChange={this.handleChange} onKeyDown={this.handleKey} />

                    <Input disabled={disabled} ref={(input) => { this.char2 = input }} maxLength={1} name="char2" id="char2" value={this.state.char2} onChange={this.handleChange} onKeyDown={this.handleKey} />

                    <Input disabled={disabled} ref={(input) => { this.char3 = input }} maxLength={1} name="char3" id="char3" value={this.state.char3} onChange={this.handleChange} onKeyDown={this.handleKey} />

                    <Input disabled={disabled} ref={(input) => { this.char4 = input }} maxLength={1} name="char4" id="char4" value={this.state.char4} onChange={this.handleChange} onKeyDown={this.handleKey} />

                    <Input disabled={disabled} ref={(input) => { this.char5 = input }} maxLength={1} name="char5" id="char5" value={this.state.char5} onChange={this.handleChange} onKeyDown={this.handleKey} />

                    <Input disabled={disabled} ref={(input) => { this.code = input }} name="code" id="code" value={this.state.code} type="hidden" />
                </Wrapper>

                { this.state.error && <Paragraph>
                    { this.state.error.message }
                </Paragraph> }
            </>
        )
    }
}

export default SMSCodeForm
