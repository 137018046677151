import React, {
  useState
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import ReadyFridgeIcon from '../../../assets/fridge-ready-icon.png'
import EditIcon from '../../../assets/edit-icon.png'
import OpeningFridgeGIF from '../../../assets/opening-fridge.gif'

import {
  postOpenFridge
} from '../../../redux/actions/fridge'

import { getCardBrandImageById } from '../../../utils'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 960px;
  margin-top: 0px;
  min-width: 320px;
  padding-top: 40px;
`;

const ImageContainer = styled.img`
  margin-bottom: 20px;
  width: 125px;
  height: auto;
`

const LoadingContainer = styled.img`
  width: 320px;
  height: auto;
  margin-top: -110px;
  position: relative;
`

const H2 = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #41BAC8;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
`

const H1 = styled.h1`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`

const H3 = styled.h3`
  font-size: 20px;
  color: #41BAC8;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
`

const Paragraph = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #686868;
  text-align: center;
  max-width: 280px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
`

const ButtonContainer = styled.button`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #41BAC8;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  flex: none;
  margin: 0px;
  margin-bottom: 30px;
  padding: 10px 0px;
  width: 100%;
  max-width: 280px;
`

const SelectedCardContainer = styled.p`
  border: #DDD 1px solid;
  background: #F7F7F7;
  border-radius: 5px;
  margin-bottom: 15px;
  margin: 0px;
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  > span {
    font-size: 12px;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: 14px;
      font-weight: normal;
    }

    > img {
      margin-right: 5px;
    }

    > a {
      margin-left: 5px;

      > img {
        width: 15px;
        height: auto;
      }
    }
  }
`

const SelectedCard = ({ brand, number, setContent }) => {
  return <SelectedCardContainer>
    <span>Cartão selecionado:</span>

    <span>
      { brand && number ? <>
        <img src={getCardBrandImageById(brand)} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

        { number }
      </>: '' }

      <a href='#empty' onClick={(event) => {
        event.preventDefault()

        setContent('cards')
      }}><img src={EditIcon} alt='Editar' /></a>
    </span>
  </SelectedCardContainer>
}

function WithCard(props) {
  const {
    userName,
    setContent,
    cardBrand,
    cardNumber,
    storeName
  } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  return <Wrapper>
    { error === true && <>
      <H1>Erro ao abrir geladeira</H1>
    </> }

    { loading === true && <LoadingContainer src={OpeningFridgeGIF} /> }

    { loading === false && <H2>
      Seja bem vindx, <br />
      { userName }!
    </H2> }

    { loading === false && <SelectedCard brand={cardBrand || 1} number={cardNumber} setContent={setContent} /> }

    { loading === false && <ImageContainer src={ReadyFridgeIcon} alt='Geladeira' /> }

    { loading === false && <Paragraph>Você abrirá a geladeira:</Paragraph> }

    { loading === false && <H3>{ storeName }</H3> }

    { loading === false && <ButtonContainer onClick={async () => {
      setLoading(true)

      const result = await props.postOpenFridge(props.storeId)

      if (result.success === true) {
        setTimeout(() => setContent({
          content: 'shopping',
          fridgeId: result.fridgeId
        }), 3000)

        return
      }

      if (result.success === false) {
        setError(true)
        setTimeout(() => setContent('check-in'), 3000)

        return
      }
    }}>Abrir Geladeira</ButtonContainer> }
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeName: state.store && state.store.name,
    storeId: state.store && state.store.id,
    userName: state.user && state.user.name,
    cardBrand: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.brandId,
    cardNumber: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.data
  }
}

export default connect(
  mapStateToProps, {
    postOpenFridge
  }
)(withRouter(WithCard))
