import React/* , {
  useEffect
} */ from 'react'
import styled from 'styled-components'

import LoadingGIF from '../../../assets/loading.gif'

import { device } from '../../../constants/responsive'

import {
  getCardBrandImageById,
  numberToCurrency,
  formatCpf
} from '../../../utils'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0px;

  @media ${ device.desktop } {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  > div {
    background: #fff;
    box-sizing: border-box;

    @media ${ device.desktop } {
      padding: 20px;
      border-radius: 10px;
      box-shadow: #ddd 3px 3px 6px;
      min-width: 620px;
      max-width: 620px;
      text-align: left;
    }

    &.sidebar {
      min-width: 280px;
      max-width: 280px;

      @media ${ device.desktop } {
        margin-left: 20px;
        min-width: 320px;
        max-width: 320px;
      }
    }
  }
`;

const H2 = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
  min-width: 280px;
  flex: 1;
  
  @media ${ device.desktop } {
    font-size: 24px;
    text-align: left;
    line-height: 28px;
    max-width: unset;
    min-width: unset;
    margin-bottom: 50px;
  }

  > span {
    display: block;
    font-weight: normal;
    font-size: 18px;
  }
`

const H3 = styled.h3`
  font-size: 18px;
  color: #000;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
  min-width: 280px;
  max-width: 280px;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    font-size: 24px;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }
`

const List = styled.ul`
  list-style: inside none;
  min-width: 280px;
  max-width: 280px;
  padding: 0px;
  margin: 0px;
  flex: 1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: #B8B8B8 1px solid;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0px -20px;
    padding: 0px 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`

const ListItem = styled.li`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #000;
  font-size: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  > span {
    flex: 1;

    &:nth-child(1) {
      max-width: 30px;
    }

    &:nth-child(2) {
      > span {
        display: block;
        font-size: 12px;
        color: #939598;
      }
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
`

const ImageContainer = styled.img`
  width: 100px;
  height: auto;
`

const Paragraph = styled.p`  
  min-width: 280px;
  padding: 0px;
  margin: 0px;
  color: #7D7D7D;
`

const PreviewDeliveryBox = styled.div`
  background: #F7F7F7;
  min-width: 280px;
  max-width: 280px;
  flex: 1;
  margin-bottom: 30px;
  border: #DDD 1px solid;
  padding: 15px 0px;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  max-height: 50px;

  @media ${ device.desktop } {
    margin-bottom: 0px;
    min-width: unset;
    max-width: unset;
    display: none;
  }
 
  > p {
    font-size: 14px;
    margin: 0px;
    padding: 0px;

    > span {
      color: #3ABE71;
    }
  }
`

const Summary = styled.div`
  min-width: 280px;
  text-align: right;
  flex: 1;
  margin-bottom: 15px;
  min-width: 100%;
  padding-bottom: 20px;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0px -20px;
    padding: 0px 20px;
    padding-bottom: 20px;
  }
 
  > p {
    max-width: 280px;
    margin: 0px auto;

    &.date {
      color: #757575;
    }

    &:nth-child(2) {
      margin-bottom: 15px;
    }

    > strong {
      color: #000;
    }
  }
`

const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: #B8B8B8 1px solid;

  @media ${ device.desktop } {
    margin: 0px -20px;
  }

  > p {
    margin-bottom: 15px;
  }

  > button {
    font-size: 20px;
    padding: 8px 15px;
    box-sizing: border-box;
    max-width: 280px;
    min-width: 280px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 7px;
    font-weight: bold;
  }
`

const Button = styled.button`
  background: ${ props => props.disabled ? '#999' : '#000'};
`

const Block = styled.div`
  flex: 1;

  @media ${ device.desktop } {
    max-width: 200px;
    min-width: 200px;
  }
`

const SelectedCardContainer = styled.p`
  margin: 0px;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: 12px;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: 14px;
      font-weight: normal;
    }

    > img {
      margin-right: 5px;
    }

    > a {
      margin-left: 5px;

      > img {
        width: 15px;
        height: auto;
      }
    }
  }
`

const SelectedCard = ({ brand, number }) => {
  return <SelectedCardContainer>
    <span>
      { brand && number ? <>
        <img src={getCardBrandImageById(brand)} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

        { number }
      </>: '' }
    </span>
  </SelectedCardContainer>
}

function TableReceipt(props) {
  const {
    userName,
    cpf,
    cardBrand,
    cardNumber,
    useCpf,
    status,
    orderDate,
    products,
    handleFinishOrder,
    cashChange,
    tableNumber,
    deliveryTime
  } = props

  const DiscountText = () => (
    <Paragraph>
      {
        products.summary.discount ?
        <>Desconto: <span>{`${numberToCurrency(products.summary.discount)}`}</span></> :
        <></>
      }
    </Paragraph>
  )

  return <Wrapper>
    { status === null && <ImageContainer src={LoadingGIF} alt={`Carregando…`} /> }

    { status && <>
      <Block>
        <H2>
          { userName }, <br />
          seu pedido foi confirmado!
          <span>{ orderDate }</span>
        </H2>

        { status &&
          <PreviewDeliveryBox>
            <Paragraph><i></i> { deliveryTime }</Paragraph>

            <Paragraph><strong>Local de Entrega:</strong> <span>Mesa: { tableNumber }</span></Paragraph>
          </PreviewDeliveryBox>
        }
      </Block>

      <Block className='sidebar'>
        <H3>Resumo do Pedido</H3>

        <List>
          { products && products.list && products.list.map((item, index) => {
            const {
              Quantidade: qty,
              Produto: name,
              Valor: value,
              Descricao: description
            } = item

            const key = `checkout-listitem-${index}-${(new Date()).getTime()}`

            return <ListItem key={key}>
              <span>{ `${qty}x` }</span>

              <span name="info">
                { name }

                <span>  
                  { description }
                </span>
              </span>

              <span name="info">{`${numberToCurrency(value)}`}</span>
            </ListItem>
          }) }
        </List>

        { products.summary && <Summary>
          <Paragraph>Subtotal <span>{`${numberToCurrency(products.summary.subtotal)}`}</span></Paragraph>
          <DiscountText />
          <Paragraph><strong>TOTAL: {`${numberToCurrency(products.summary.total)}`}</strong></Paragraph>
        </Summary> }

        <Footer>
          { useCpf && <Paragraph>CPF na nota: { formatCpf(cpf) }</Paragraph> }

          { cashChange && <Paragraph>Troco para: { `${numberToCurrency(cashChange)}` }</Paragraph> }

          <SelectedCard brand={cardBrand || 1} number={cardNumber} />

          <Button disabled={status !== 2 ? true : false} onClick={() => {
            handleFinishOrder()
          }}>Recebi meu pedido</Button>
        </Footer>
      </Block>
    </> }
  </Wrapper>
}

export default TableReceipt
