interface EcommerceCheckoutData {
	list: []
  requestNumber: number
  status: number
  paymentStatus: number
  orderDate: string
  summary: EcommerceCheckoutDataSummary
  notification: EcommerceCheckoutDataNotification
  deliveryAddress: string
}

interface EcommerceCheckoutDataNotification {
  status: number
  statuses: EcommerceCheckoutDataNotificationStatuses[]
}

interface EcommerceCheckoutDataNotificationStatuses {
  status: string
  time: string
  active: boolean
}

interface EcommerceCheckoutDataSummary {
  total: number
  deliveryFee: number
  discount: number
  subtotal: number
}

interface EcommerceCheckoutRequestNumber {
	requestNumber: number
}

interface EcommerceCheckoutFinishOrder {
	success: boolean
}

interface EcommerceCheckoutOrderStatus {
  notification: EcommerceCheckoutDataNotification
	completed: boolean
}

export const mapEcommerceCheckoutData = (response: any) : EcommerceCheckoutData => {
  const notification = {
    status: response['NotificacaoPedido']['StatusDelivery'],
    statuses: response['NotificacaoPedido']['ListaStatusDelivery']
  }

	return {
    list: response['PedidoItems'],
    requestNumber: response['NumeroChamada'],
    status: response['NumeroChamada'] > -1 ? 2 : 1,
    paymentStatus: response['StatusPagamento'],
    orderDate: response['DataComprovante'],
    deliveryAddress: response['LocalEntrega'],
    notification,
    summary: {
      total: response['ValorPago'],
      deliveryFee: response['ModalidadeRetiradaValor'],
      discount: response['ValorDesconto'],
      subtotal: response['ValorPago'] - response['ModalidadeRetiradaValor']
    }
	}
}

export const mapEcommerceCheckoutRequestNumber = (response: any) : EcommerceCheckoutRequestNumber => {
	return {
    requestNumber: response['NumeroChamada']
	}
}

export const mapEcommerceCheckoutFinishOrder = (response: any) : EcommerceCheckoutFinishOrder => {
	return {
    success: response['Sucesso']
	}
}

export const mapEcommerceCheckoutOrderStatus = (response: any) : EcommerceCheckoutOrderStatus => {
  const statuses = (statuses: any[]) => (statuses.map((mappedItem: any) => ({
    status: mappedItem['Status'],
    active: mappedItem['Ativo'],
    time: mappedItem['Horario']
  })))

  const notification = {
    status: response['NotificacaoPedido'] && response['NotificacaoPedido']['StatusDelivery'],
    statuses: response['NotificacaoPedido'] && statuses(response['NotificacaoPedido']['ListaStatusDelivery'])
  }

	return {
    completed: response['PedidoPronto'],
    notification
	}
}
