import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Box,
  StoreLogo,
  Icon
} from '../common/'
import {
  HeaderStoreName,
  HeaderInfoSmall,
  HeaderLoginButton,
  SmallHeaderBar,
  StoreInfoModal,
} from './'
import { numberToCurrency } from '../../utils'
import { device } from '../../constants/responsive'
import {
  Desktop,
  Mobile
} from '../common/ResponsiveComponent'
import { HEADER_ANIM_TIME } from '../../constants/header'
import { DELIVERY_MODALITIES } from '../../constants/delivery'
import { isMobile } from 'react-device-detect'
import { CARDAPIO_CONSULTA } from '../../config'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Blocker = styled.div`
  width: 100%;
  height: ${(props) => props.theme.dimensions.headerHeightMobile}px;
  display: block;

  @media ${device.desktop} {
    height: ${(props) => props.theme.dimensions.headerHeightDesktop}px;
  }
`

const BlankSpace = styled.div`
  @media ${device.desktop} {
    transition: all ${HEADER_ANIM_TIME}s;
    width: 10%;
  }
`

const OutterBox = styled(Box)`
  width: auto;
  height: ${(props) => props.theme.dimensions.headerHeightMobile}px;
  transition: all ${HEADER_ANIM_TIME}s;
  display: flex;
  flex-grow: 1;
  justify-content: center;

  margin: 0 10px;
  @media ${device.desktop} {
    height: ${(props) => props.theme.dimensions.headerHeightDesktop}px;
    margin: auto;
  }
`

const BoxContent = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  transition: all ${HEADER_ANIM_TIME}s;
  padding: 0 10px;

  @media ${device.desktop} {
    width: 80vw;
    padding: 0 0 0 40px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: all ${HEADER_ANIM_TIME}s;

  @media ${device.desktop} {
    margin: 0 15px 0 0;
    padding: 0 40px 0 15px;
  }

  & img {
    max-width: 100px;
    transition: all ${HEADER_ANIM_TIME}s;
  }
`

const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${device.mobile} {
    flex-grow: 1;
    align-items: center;
  }
`

const AlternativeHeaderContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  align-items: center;

  @media ${device.mobile} {
    flex-grow: 1;
    align-items: center;
  }
`

const StoreNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobile} {
    max-width: 170px;
  }
`

const MoreInfoButton = styled.button`
  background: none;
  border: none;
`

const InfoWrapper = styled.div`
  display: flex;
  align-self: center;
`

const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  opacity: 1;
  min-width: 320px;

  ${(props) =>
    props.fixed &&
    `
        @media ${device.mobile} {
            display: none;
        }

        @media ${device.desktop} {
            position: fixed;
            top: 0;
            left: 0;
    
            ${OutterBox} {
                border: 0;
                -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.16);
                -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.16);
                box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.16);
            }
        }
    `}

  ${(props) =>
    props.stretch &&
    `
        @media ${device.desktop} {
            ${BlankSpace} {
                width: 0;
            }
        }
    `}
`

class HeaderBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showStoreInfoModal: false,
    }
  }

  handleStoreInfoModalClose = (e) => {
    this.setState({ showStoreInfoModal: false })
  }

  handleShowStoreInfoModal = () => {
    this.setState({ showStoreInfoModal: true })
  }

  handleHeaderClick = () => {
    if (isMobile) {
      this.handleShowStoreInfoModal()
    }
  }

  render() {
    const {
      fixed = false,
      stretch,
      isOpen,
      openingHours,
      deliveryModality,
      deliveryEstimate,
      contactInfo,
      availableCardBrands,
      name,
      storeLogo,
      modalities
    } = this.props

    const todayOpeningHours = openingHours && openingHours[new Date().getDay()]

    const selectedModality = (modalities && (modalities.filter(filteredItem => filteredItem.id === deliveryModality))[0]) || null

    const minimumValueText = ((deliveryModality && selectedModality) ?
      (selectedModality.minimumValue ?
        numberToCurrency(selectedModality.minimumValue) :
        'Nenhum') :
      'Escolha o local de entrega')

    const serviceFeeText = ((deliveryModality && selectedModality) ?
      ( selectedModality.serviceFee ?
        numberToCurrency(selectedModality.serviceFee) :
        'Grátis' ) :
      'Escolha o local de entrega')

    const serviceFeeTitle = ((deliveryModality === DELIVERY_MODALITIES.DELIVERY) ?
      'Taxa de entrega' :
      'Taxa')

    const deliveryEstimateText = ((deliveryModality === DELIVERY_MODALITIES.DELIVERY) ?
      'Estimativa de entrega' :
      'Tempo de preparo')

    return (
      <Wrapper>
        <title>{name}</title>
        <Blocker />
        <BoxContainer fixed={fixed} stretch={stretch}>
          <BlankSpace />
          <OutterBox onClick={this.handleHeaderClick}>
            <BoxContent>
              <LogoWrapper>
                <StoreLogo theme={{logoImage: storeLogo}} />
              </LogoWrapper>
              { CARDAPIO_CONSULTA === 'false' && 
                <>
                <HeaderContentWrapper>
                  <StoreNameWrapper>
                    <HeaderStoreName name={name} />
                  </StoreNameWrapper>
                  <InfoWrapper>
                    <Desktop>
                      <HeaderInfoSmall
                        primaryText={ serviceFeeTitle }
                        secondaryText={ serviceFeeText }
                        icon='delivery'
                      />
                      <HeaderInfoSmall
                        primaryText='Valor mínimo'
                        secondaryText={ minimumValueText }
                        icon='minValue'
                      />
                      <HeaderInfoSmall
                        primaryText={isOpen ? 'Aberto' : 'Fechado'}
                        secondaryText={
                          todayOpeningHours && `${todayOpeningHours.hours}`
                        }
                        icon='store'
                        bulletType={isOpen ? 'success' : 'error'}
                      />
                      {deliveryModality && (
                        <HeaderInfoSmall
                          primaryText={deliveryEstimateText}
                          secondaryText={deliveryEstimate}
                          icon='time'
                        />
                      )}
                    </Desktop>
                    <Mobile>
                      <HeaderInfoSmall
                        primaryText={isOpen ? 'Aberto' : 'Fechado'}
                        secondaryText=''
                        bulletType={isOpen ? 'success' : 'error'}
                      />
                    </Mobile>
                  </InfoWrapper>
                </HeaderContentWrapper>
                <MoreInfoButton onClick={this.handleShowStoreInfoModal}>
                  <Icon name='info' />
                </MoreInfoButton>
              </> }

              { CARDAPIO_CONSULTA === 'true' && <AlternativeHeaderContentWrapper>
                <StoreNameWrapper>
                  <HeaderStoreName name={name} />
                  <MoreInfoButton onClick={this.handleShowStoreInfoModal}>
                    <Icon name='info' />
                  </MoreInfoButton>
                </StoreNameWrapper>
              </AlternativeHeaderContentWrapper> }
              
              {
                CARDAPIO_CONSULTA === 'false' && <Desktop>{fixed && <HeaderLoginButton dark={true} />}</Desktop>
              }
            </BoxContent>
          </OutterBox>
          <BlankSpace />
        </BoxContainer>
        <Mobile>
          <SmallHeaderBar fixed={fixed} />
        </Mobile>
        <StoreInfoModal
          isOpen={this.state.showStoreInfoModal}
          onClose={this.handleStoreInfoModalClose}
          phone={contactInfo.phone}
          email={contactInfo.email}
          availableCardBrands={availableCardBrands}
          openingHours={openingHours}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modalities: state.store && state.store.modalities,
    isOpen: state.store && state.store.isOpen,
    openingHours: state.store && state.store.openingHours,
    deliveryModality: state.cart.delivery.modality,
    deliveryEstimate: state.store && state.store.deliveryEstimate,
    contactInfo: state.store && state.store.contactInfo,
    availableCardBrands: state.store && state.store.availableCardBrands,
    name: state.store && state.store.name,
    storeLogo: state.store && state.store.logo
  }
}

export default connect(mapStateToProps, null)(HeaderBox)
