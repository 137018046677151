import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Icon } from '../common';
import { toggleLoginModal } from '../../redux/actions/ui';
import { logout } from '../../redux/actions/auth';

import { CARDAPIO_CONSULTA } from "../../config";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
`

const Button = styled.button`
    background-color: transparent;
    border: 0;
    display: flex;
`

const HyperLink = styled(Button)`
    font-size: 14px;
    text-align: right;
    text-decoration: underline
`

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    color: ${props => props.dark ? props.theme.colors.mediumGrey : 'white'};
    font-size: 22px;
    font-weight: bold;

    ${HyperLink} {
        color: ${props => props.dark ? props.theme.colors.mediumGrey : 'white'};
    }
`

class HeaderLoginButton extends React.Component {

    handleLogin = () => {
        this.props.toggleLoginModal({show: true, state: 0})
    }

    handleLogout = () => {
        this.props.logout();
    }

    renderLoggedInButton = () => {
        return (
            <Wrapper>
                {this.props.dark 
                    ? (<Icon name="loginDark" />) 
                    : (<Icon name="login" />)
                }
                {` `}
                {!this.props.min && (
                    <TextBlock dark={this.props.dark}>
                        <span>{this.props.user.name}</span>
                        <HyperLink onClick={this.handleLogout}>Logout</HyperLink>
                    </TextBlock>
                )}
            </Wrapper>
        )
    }

    renderLoggedOutButton = () => {
        return (
            <Wrapper>
                <Button onClick={this.handleLogin}>
                    {this.props.dark 
                        ? (<Icon name="loginDark" />) 
                        : (<Icon name="login" />)
                    }
                    {` `}
                    <TextBlock dark={this.props.dark}>
                        {!this.props.min && 'Login'}
                    </TextBlock>
                </Button>
            </Wrapper>
        )
    }

    render() {
        return this.props.user ? CARDAPIO_CONSULTA === "false" && this.renderLoggedInButton() : CARDAPIO_CONSULTA === "false" && this.renderLoggedOutButton();
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    { toggleLoginModal, logout },
)(HeaderLoginButton);
