import React from 'react';
import styled from 'styled-components';

import { device } from '../../constants/responsive';
import addressInvalidImage from '../../assets/address_invalid.svg';
import { Button } from '../common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 25px;
  height: 351px;

  @media ${device.desktop} {
    width: 240px;
    margin: 60px;
    padding: 0;
  }
`

const ImageWrapper = styled.div`
  margin-bottom: 14px;
`

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.colors.textBold};
  margin-bottom: 16px;
`

const Description = styled.span`
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 25px;
`

const BackButton = styled(Button)`
  width: 100% !important;
  height: 33px;
`

const AddressInvalid = ({onBack}) => (
    <Wrapper>
        <ImageWrapper>
            <img src={addressInvalidImage} alt="Região fora da área de entrega" />
        </ImageWrapper>
        <Title>
            Região fora da área de entrega
        </Title>
        <Description>
        Infelizmente não chegamos ainda no endereço indicado
        <br />
        Desculpe-nos! :( 
        </Description>
        <BackButton className="primary" onClick={onBack}>
            BUSCAR OUTRO CEP
        </BackButton>
    </Wrapper>
)

export default AddressInvalid;