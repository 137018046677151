import React from 'react';
import styled from 'styled-components';

import { StoreLogo, Icon } from '../common';
import { device } from '../../constants/responsive';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div`
    height: ${props => props.theme.dimensions.headerHeightShrinkedMobile}px
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey}
    background: white;

    @media ${device.desktop} {
        padding: 0 15px;
        height: ${props => props.theme.dimensions.headerHeightDesktop}px
    }
`

const Content = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
`

const BackButton = styled.div`
    display: flex;
    align-items: center;
    width: 96px;
    font-size: 11px;
    font-weight: 700;
    color: ${props => props.theme.colors.primary}
    cursor: pointer;
`

const Placeholder = styled.div`
    width: 96px;
`

const Arrow = styled.div`
    padding-right: 15px;
`

const LogoWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${device.mobile} {
        img {
            max-width: 64px;
        }
    }
`

const HeaderSimplified = ({onBack}) => (
    <Wrapper>
        <Content>
            <BackButton onClick={onBack}>
                <Arrow>
                    <Icon name="arrowLeft" fill="primary" />
                </Arrow>
                {!isMobile && `VOLTAR PARA O CARDÁPIO`}
            </BackButton>
            <LogoWrapper>
                <StoreLogo />
            </LogoWrapper>
            <Placeholder />
        </Content>
    </Wrapper>
)

export default HeaderSimplified;