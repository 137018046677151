import React from 'react';
import styled from 'styled-components';
import PaymentMethodSelectionCell from './PaymentMethodSelectionCell';
import { device } from '../../constants/responsive';
import { getCardBrandImageByPaymentTypeAndId } from '../../utils/';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`

const Grid = styled.div`
    display: grid;
    width: 100%;
    margin: 0px 10px;

    @media ${device.mobile} {
        grid-template-columns: 1fr;
    }

    @media ${device.desktop} {
        grid-template-columns: 1fr 1fr;
    }
`

const Container = styled.div`
    padding: 10px;
`

const OfflinePaymentBlock = ({cards, onCheckCard, selected}) => (
    <Wrapper>
        <Grid>
            {cards && cards.map( (card, i) => (
                <Container key={`offlineCard#${i}`}>
                    <PaymentMethodSelectionCell 
                        card={card}
                        imageSrc={getCardBrandImageByPaymentTypeAndId(card.paymentType, card.brandId)}
                        checked={!!(selected && card.id === selected.id)}
                        name={card.data}
                        onCheck={onCheckCard}
                    />
                </Container>
            ))}
        </Grid>
    </Wrapper> 
)

export default OfflinePaymentBlock;