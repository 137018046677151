import React from 'react';
import styled from 'styled-components';

import { Modal } from '../common';
import { device } from '../../constants/responsive';

const Wrapper = styled.div`
    padding: 
    display: flex;
    flex-direction: column;
    padding: 30px;
    @media ${device.desktop} {
        width: 475px
    }
`

const SectionTitle = styled.span`
    display: inline-block;
    width: 100%;
    min-height: 40px;
    padding-bottom: 10px;
    font-size: 18px;
    color: ${props => props.theme.colors.textBold};
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey}
`

const SectionContent = styled.div`
    padding-top: 15px;
    padding-bottom: 40px;
`

const SectionLabel = styled.span`
    text-size: 14px;
    color: ${props => props.theme.colors.textPrimary}
`

const SectionDetail = styled.span`
    text-size: 14px;
    font-weight: 300;
    color: ${props => props.theme.colors.textPrimary}
`

const BrandsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @media ${device.desktop} {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
`

const BrandImage = styled.img`
    max-width: 32px;
`

const DaysCell = styled.span`
    text-size: 14px;
    color: ${props => props.theme.colors.textPrimary}
    display: inline-block;
    width: 70px;
`

const StoreInfoModal = ({isOpen, onClose, phone, email, availableCardBrands, openingHours}) => (
    <Modal name="info" isOpen={isOpen} onClose={onClose}>
        <Wrapper>
            <SectionTitle>
                Contato
            </SectionTitle>
            <SectionContent>
                <SectionLabel>Telefone: </SectionLabel>
                <SectionDetail>{phone}</SectionDetail>
                <br />
                <SectionLabel>Email: </SectionLabel>
                <SectionDetail>{email}</SectionDetail>
            </SectionContent>
            <SectionTitle>
                Pagamentos feitos pelo App
            </SectionTitle>
            <SectionContent>
                <BrandsGrid>
                {availableCardBrands && availableCardBrands.map( (brand, i) => (
                    <BrandImage key={`infoBrands#${i}`} src={brand} />
                ))}
                </BrandsGrid>
            </SectionContent>
            <SectionTitle>
                Horário de funcionamento
            </SectionTitle>
            <SectionContent>
                {openingHours && openingHours.map( (day, i) => (
                    <React.Fragment key={`infoHours#${i}`}>
                        <DaysCell>{day.day}</DaysCell>
                        <SectionDetail>{day.hours}</SectionDetail>
                        <br />
                    </React.Fragment>
                ))}
            </SectionContent>
        </Wrapper>
    </Modal>
)

export default StoreInfoModal;
