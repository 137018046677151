import React from 'react';
import styled from 'styled-components';
import { Modal, Button, Box } from '../common';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 40px;
    min-width: 320px;
`

const TitleText = styled.span`
    font-size: 18px;
    font-weight: 700;   
    color: ${props => props.theme.colors.primary};
    padding: 20px 0;
`

const DescriptionText = styled.span`
    font-size: 13px;
    color: ${props => props.theme.colors.textPrimary};
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    width: 100%;

    & button {
        margin: 0 10px;
    }
`

class ConfirmModal extends React.Component {

    render() {
        return (
            <Modal name="confirm" isOpen={this.props.isOpen} onClose={this.props.onClose}>
                <Box>
                    <Wrapper>
                        <TitleText>{this.props.title}</TitleText>
                        <DescriptionText>
                            {this.props.description}
                        </DescriptionText>
                        <ButtonWrapper>
                            <Button stretch onClick={this.props.negativeAction.onClick} className="primary">{this.props.negativeAction.text}</Button>
                            <Button stretch onClick={this.props.positiveAction.onClick} className="action">{this.props.positiveAction.text}</Button>
                        </ButtonWrapper>
                    </Wrapper>
                </Box>
            </Modal>
        )
    }
}

export default ConfirmModal;