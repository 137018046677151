import React from 'react';
import styled from 'styled-components';
import { Icon } from '../common';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    cursor: pointer;
`


const PlusButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid ${props => props.theme.colors.mediumGrey};
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

const Text = styled.span`
    margin-top: 8px;
    font-size: 11px;
    text-align: center;
    color: ${props => props.theme.colors.darkGrey}
`

const AddNewCardCell = ({onClick}) => (
    <Wrapper onClick={onClick}>
        <PlusButton>
            <Icon name="plus" />
        </PlusButton>
        <Text>Cadastrar novo cartão</Text>
    </Wrapper>
)

export default AddNewCardCell;