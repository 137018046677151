export const actionTypes = {
  REQUEST_STORE: 'REQUEST_STORE',
	REQUEST_SIGN_UP: 'REQUEST_SIGN_UP',
	REQUEST_SEND_CODE: 'REQUEST_SEND_CODE',
	REQUEST_SEND_CONFIRMATION_CODE: 'REQUEST_SEND_CONFIRMATION_CODE',
	REQUEST_SAVE_SIGN_UP: 'REQUEST_SAVE_SIGN_UP',
	REQUEST_SAVE_CVV: 'REQUEST_SAVE_CVV',
	REQUEST_UPDATE_PHONE: 'REQUEST_UPDATE_PHONE',
	REQUEST_LOGIN: 'REQUEST_LOGIN',
	REQUEST_FORGOT_PASSWORD: 'REQUEST_FORGOT_PASSWORD',
	REQUEST_CEP: 'REQUEST_CEP',
	REQUEST_SAVE_ADDRESS: 'REQUEST_SAVE_ADDRESS',
	REQUEST_CLEAR_ADDRESS: 'REQUEST_CLEAR_ADDRESS',
	REQUEST_FETCH_PAYMENT_METHODS: 'REQUEST_FETCH_PAYMENT_METHODS',
	REQUEST_FETCH_CARD_BRANDS: 'REQUEST_FETCH_CARD_BRANDS',
	REQUEST_ADD_COUPON: 'REQUEST_ADD_COUPON',
	REQUEST_DELETE_CARD: 'REQUEST_DELETE_CARD',
	REQUEST_ANALYSE_ORDER: 'REQUEST_ANALYSE_ORDER',
	REQUEST_PLACE_ORDER: 'REQUEST_PLACE_ORDER',
	REQUEST_ADD_CARD: 'REQUEST_ADD_CARD',
	REQUEST_CLOSEST_STORE: 'REQUEST_CLOSEST_STORE',
	REQUEST_AVAILABLE_CITIES: 'REQUEST_AVAILABLE_CITIES',
	REQUEST_AVAILABLE_STORES: 'REQUEST_AVAILABLE_STORES'
}
