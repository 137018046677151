import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { CARD_BRANDS_IDS } from '../../../constants/payment'

import {
  fetchAvailableCardBrands,
  deleteCard,
  addCard,
  addTicketCard
} from '../../../redux/actions/payment'

import {
  fetchPaymentMethods
} from '../../../redux/actions/fridge'

import {
  setPaymentType,
  setCpfOnReceit,
  setPaymentCashChange
} from '../../../redux/actions/cart'

import {
  navigateTo,
  goBack
} from '../../../redux/actions/pages'

import {
  NewCard as NewCardContainer
} from '../../../components/payment'

const PAYMENT_STATES = {
  SELECT_PAYMENT: 0,
  ONLINE_PAYMENT: 1,
  OFFLINE_PAYMENT: 2
}

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0px;
`;

class NewCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentState: PAYMENT_STATES.SELECT_PAYMENT,
      showDeleteCardModal: false,
      showAddNewCardModal: false,
      deleteCard: null,
      addNewCardError: null,
      selectedCard: {
          card: null,
          isOffline: false
      }
    }
  }

  handleAddNewCard = async (card) => {
    let result

    if (card.brandId === CARD_BRANDS_IDS.TICKET_VR || card.brandId === CARD_BRANDS_IDS.TICKET_VA) {
      result = await this.props.addTicketCard(card)
    } else {
      result = await this.props.addCard(card)
    }
    
    if (result.success) {
      await this.props.fetchPaymentMethods()
      this.props.setContent('cards')

      return
    }
    
    this.setState({addNewCardError: result.error})
  }

  render() {
    return (
      <Wrapper>
        <NewCardContainer
          addCard={this.handleAddNewCard}
          loading={this.props.addNewCardLoading}
          error={this.state.addNewCardError}
          onDismissError={this.handleDismissAddNewCardError}
          brandsImages={this.props.brandsImages}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    onlineCards: state.payment.onlineCards,
    offlineCards: state.payment.offlineCards,
    brandsImages: state.payment.brandsImages,
    fetchPaymentMethodsLoading: state.requests.fetchPaymentMethods.loading,
    addNewCardLoading: state.requests.addCard.loading
  }
}

export default connect(
  mapStateToProps, {
    fetchPaymentMethods,
    fetchAvailableCardBrands,
    setPaymentType,
    setCpfOnReceit,
    navigateTo,
    goBack,
    deleteCard,
    addCard,
    addTicketCard,
    setPaymentCashChange
  },
)(withRouter(NewCard))
