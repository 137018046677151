import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 10px 0;
    color: ${props => props.theme.colors.textPrimary}
    font-size: 13px;
`

const PrimaryText = styled.span`
    font-weight: 500;
`

const SecondaryText = styled.span`
`

const CartCpfCell = ({cpf}) => (
    <Wrapper>
        <PrimaryText>CPF na nota:&nbsp;</PrimaryText>
        <SecondaryText>{cpf}</SecondaryText>
    </Wrapper>
)

export default CartCpfCell;