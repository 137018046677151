import React from 'react';

class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined
        };
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    handleChange = (e) => {
        this.setState({error: undefined })
        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }

    validate = () => {
        if (!this.props.validations) return true;
        for (let i = 0; i < this.props.validations.length; i++) {
            const valid = this.props.validations[i](this.props.value);
            if (valid !== true) {
                this.setState({error: valid})
                return false;
            }
        }
        this.setState({error: false})
        return true;
    }

    render() {
        const InputComponent = this.props.InputComponent
        return (
            <InputComponent id={this.props.id} value={this.props.value} onChange={this.handleChange} error={this.state.error} />
        )
  }
}


export default FormInput;
