import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import { CARDAPIO_CONSULTA } from "../../config";
import {
    Modal,
    Icon,
    Button,
    LoadingOverlay
} from '../common'
import { numberToCurrency } from '../../utils'
import { device } from '../../constants/responsive'

import { AddonGroup } from './'
import { calculateAddonItems } from '../../utils/calculateAddonItems';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media ${device.desktop} {
    width: 475px;
    max-height: 90vh;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  height: 220px;
  width: 100%;
`

/* const ImageOverlay = styled.div`
  width: 100%;
  height: 50px;
  position: absolute;
  top: -1px;
  z-index: 10;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  @media ${device.desktop} {
    border-radius: 5px;
  }
`
 */

const ProductDetailWrapper = styled.div`
    width: 100%;
    overflow: auto;
    height: calc(100% - 55px);

    @media ${device.desktop} {
        border-radius: 10px;
        height: calc(100% - 75px);
    }
`

const ProductInfoWrapper = styled.div`
    padding: 28px;

    @media ${device.mobile} {
        padding: 28px 45px 28px 28px;
    }
`

const ProductNameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ProductName = styled.span`
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.colors.textBold};
    flex-grow: 1;
    max-width: 230px;
`

const ProductPrice = styled.span`
    font-size: 15px;
    font-weight: 500;
    color: ${props => props.theme.colors.textBold};
`

const PromotionalOldPrice = styled.span`
    font-weight: 400;
    font-size: 15px;
    color: ${props => props.theme.colors.mediumGrey};
    opacity: .8;
    text-decoration: line-through;
    margin: 0 10px 2px 0;

    @media ${device.desktop} {
        font-size: 16px;
    }
`;

const PromotionalCurrentPrice = styled.span`
    font-weight: 700;
    font-size: 20px;
    color: ${props => props.theme.colors.lightGreen};

    @media ${device.desktop} {
        font-size: 22px;
    }
`;

const PromotionalPriceWrapper = styled.div`
    align-items: flex-end;
    align-self: flex-start;
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;

    ${PromotionalOldPrice} {
        order: -1;
    }
`;

const ProductDescription = styled.div`
    padding-top: 20px;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
`

const AddonTitleBar = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${props => props.theme.colors.lightGrey};
    font-size: 12px;
    padding: 8px 28px 8px 28px;
    width: 100%;
    display: flex;
    flex-direction: row;

    ${props => props.error && `
        color: ${props.theme.colors.error};
    `}
`

const AddonName = styled.span`
  flex-grow: 1;
`

const AddonMandatory = styled.span``

const AddonContent = styled.div`
    padding: 16px 28px 16px 18px;
    width: 100%;
`

const ObservationBox = styled.textarea`
    width: 100%;
    height: 100px;
    font-size: 14px;
    font-weight: 300;
    color: ${props => props.theme.colors.textPrimary};
    border: 1px solid ${props => props.theme.colors.lightGrey};
`

const Footer = styled.div`
    display: flex;
    width: 100%;
    height: 65px;
    border-top: 1px solid ${props => props.theme.colors.lightGrey};
    align-items: center;
    padding: 0 10px;

    @media ${device.desktop} {
        padding: 20px 40px 20px 40px;
        height: 75px;
    }
`

const QuantityColumn = styled.div`
    text-align: left;
    flex: 1;
`

const AddColumn = styled.div`
    text-align: right;
    flex: 1;
`

const QuantityWrapper = styled.div`
    display: flex;
    width: 106px;
    align-items: center;
    text-align: center;
`

const QuantityButton = styled.button`
    border: 0;
    background: none;
`

const QuantityLabel = styled.span`
    font-size: 29px;
    color: ${props => props.theme.colors.darkGrey};
    flex-grow: 1;
`

const ErrorText = styled.span`
    font-size: 11px;
    color: ${props => props.theme.colors.error};
    position: absolute;
    left: 50%;
    transform: translateY(-125%);
`

const AddToCartButton = styled(Button).attrs({
    className: 'primary'
})`
    @media ${device.mobile} {
        font-size: 18px !important;
        height: 40px !important;
        width: 200px !important;
    }
`

class ProductDetailModal extends React.Component {
    constructor(props) {
        super(props);
        let checkedAddonItems = props.checkedAddonItems;
        if (props.product && (!checkedAddonItems || checkedAddonItems.length !== props.product.addons.length)) {
            checkedAddonItems = props.product.addons.map(addon => (
                {
                    name: addon.name,
                    items: [],
                    min: addon.min,
                    max: addon.max,
                    customCalculationType: addon.customCalculationType
                }
            ))
        }
        this.state = {
            quantity: props.quantity || 1,
            checkedAddonItems: checkedAddonItems,
            showError: false,
            observation: props.observation || null,
            loading: !props.isOpen,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            setTimeout(() => this.setState({loading: false}), 500);
        }
    }

    handleDecrementItem = () => {
        const minQuantity = this.props.quantity > 0 ? 0 : 1;
        this.setState({
            quantity: Math.max(this.state.quantity - 1, minQuantity)
        })
    }

    handleIncrementItem = () => {
        this.setState({
            quantity: this.state.quantity + 1
        })
    }

    validateMandatoryAddons = () => {
        let valid = true;
        const checkedAddonItems = [...this.state.checkedAddonItems]
        this.state.checkedAddonItems.forEach(addon => {
            const total = addon.items.reduce( (acc, item) => acc + item.quantity, 0);
            if (total < addon.min) {
                valid = false;
                addon.error = true
            }
        })
        this.setState({checkedAddonItems, showError: !valid})
        return valid;
    }

    handleSaveItem = () => {

        if (!this.validateMandatoryAddons()) {
            return false
        }

        this.props.onUpdateCartItem({
            quantity: this.state.quantity,
            checkedAddons: this.state.checkedAddonItems,
            observation: this.state.observation
        })
        this.props.onClose();
    }

    updateCheckedItems = (addonIndex, items) => {
        const checkedAddonItems = [...this.state.checkedAddonItems];
        checkedAddonItems[addonIndex].items = items;
        checkedAddonItems[addonIndex].error = null;
        this.setState({checkedAddonItems, showError: false});
    }

    getTotalPrice = () => {
        let price = 0;
        price += (this.props.product.promotionalPrice || this.props.product.price);
        this.state.checkedAddonItems.forEach(addon => {
            price += calculateAddonItems(addon.customCalculationType, addon.items);
        })
        price *= this.state.quantity;
        return price;
    }

    handleObservationChange = (e) => {
        const val = e.target.value;
        this.setState({observation: val})
    }

    renderModalContent = () => {
        const { product } = this.props;
        const totalPrice = this.getTotalPrice();

        return (
            <>
                <ProductDetailWrapper>
                    {product.image && (
                    <ImageWrapper image={product.image} />
                    )}
                    <ProductInfoWrapper>
                        <ProductNameWrapper>
                            <ProductName>
                                {product.name}
                            </ProductName>
                            {product && product.promotionalPrice ? <PromotionalPriceWrapper>
                                    <PromotionalOldPrice>
                                        {product.price !== 0 && numberToCurrency(product.price)}
                                    </PromotionalOldPrice>
                                    <PromotionalCurrentPrice>
                                        {numberToCurrency(product.promotionalPrice)}
                                    </PromotionalCurrentPrice>
                                </PromotionalPriceWrapper> :
                                <ProductPrice>
                                    {product.price !== 0 && numberToCurrency(product.price)}
                                </ProductPrice>
                            }
                        </ProductNameWrapper>
                        <ProductDescription>
                            {product.description}
                        </ProductDescription>
                    </ProductInfoWrapper>
                    { CARDAPIO_CONSULTA === "false" && product.addons && product.addons.map( (addon, i) => {
                        return (
                        <React.Fragment key={`${product.name}#${addon.name}`}>
                            <AddonTitleBar error={this.state.checkedAddonItems[i].error}>
                                <AddonName>
                                    {addon.name}
                                </AddonName>
                                {addon.min > 0 && (
                                    <AddonMandatory>
                                        Obrigatório
                                    </AddonMandatory>
                                )}
                            </AddonTitleBar>
                            <AddonContent>
                                {
                                <AddonGroup 
                                    items={addon.items} 
                                    addonIndex={i}
                                    max={addon.max}
                                    checkbox={addon.checkbox}
                                    checkedItems={this.state.checkedAddonItems[i].items}
                                    updateCheckedItems={this.updateCheckedItems}
                                />
                                }
                            </AddonContent>
                        </React.Fragment >
                    )})}
                    { CARDAPIO_CONSULTA === "false" && 
                        <>
                            <AddonTitleBar>
                                OBSERVAÇÃO ADICIONAL
                            </AddonTitleBar> <AddonContent>
                                <ObservationBox onChange={this.handleObservationChange} placeholder={"Deseja fazer alguma observação adicional? Digite aqui"}>
                                    {this.state.observation}
                                </ObservationBox>
                            </AddonContent>
                        </>
                    }
                </ProductDetailWrapper>
                { CARDAPIO_CONSULTA === "false" && <Footer>
                    <QuantityColumn>
                        <QuantityWrapper>
                            <QuantityButton onClick={this.handleDecrementItem}>
                                <Icon name="minus" width={"20px"} height={"20px"} />
                            </QuantityButton>
                            <QuantityLabel>{this.state.quantity}</QuantityLabel>
                            <QuantityButton onClick={this.handleIncrementItem}>
                                <Icon name="plus" width={"20px"} height={"20px"}/>
                            </QuantityButton>
                        </QuantityWrapper>
                    </QuantityColumn>
                    <AddColumn>
                        <ErrorText>{this.state.showError && ("Preencha todos os campos obrigatórios!")}</ErrorText>
                        <AddToCartButton onClick={this.handleSaveItem}>
                            {this.props.edit ?
                                this.state.quantity === 0 
                                    ? 'Remover'
                                    : 'Atualizar' 
                                : 'Adicionar'}
                            &nbsp;&nbsp;&nbsp;
                            {totalPrice !== 0 && numberToCurrency(totalPrice)}
                        </AddToCartButton>
                    </AddColumn>
                </Footer> }
            </>
        )
    }

    render() {
        const modalStyle = isMobile 
                                ? {
                                    content: {
                                        width: "100%",
                                        padding: 0,
                                        bottom: 0
                                }}
                                : {
                                    content: {
                                        bottom : 0,
                                        margin: -30
                                }}
        return (
            <Modal 
                isOpen={this.props.isOpen} 
                name="product"
                onClose={this.props.onClose} 
                style={modalStyle}
                fillHeight
                noBorder={isMobile}
                animated={isMobile}
            >
                <Wrapper>
                    {this.state.loading 
                        ? (
                            <LoadingOverlay visible={true} margin={"5px"} />
                        )
                        : this.renderModalContent()
                    }
                </Wrapper>
            </Modal>
        )
    }

}

export default ProductDetailModal;