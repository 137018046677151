import React from 'react';
import styled from 'styled-components'

import { Box } from './';

const Wrapper = styled.div`
    cursor: pointer;
    position:relative;
`

const SelectInputWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 35px;
    width: auto;
    background: white;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    border-radius: 5px;
    color: ${props => props.theme.colors.textPrimary};

    &.opened {
        display: none;
    }
`

const CaretWrapper = styled.div`
    width: 24px;
`

const Caret = styled.div`
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-top: 5px solid ${props => props.theme.colors.textPrimary};
`
const Label = styled.div`
    width: 100%;
    padding-left: 10px;
    flex-grow: 1;

    font-style: italic;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.05em;
`

const OptionsBox = styled(Box)`
    display: none;
    width: 100%;

    &.opened {
        position: absolute;
        display: block;
        max-height: 300px;
        overflow: auto;
    }
`

const Option = styled.div`
    font-size: 13px;
    padding: 5px 10px 5px 10px;

    &:hover,
    &.selected {
        background-color: ${props => props.theme.colors.lightGrey}
    }
`

class SelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
        }
        this.wrapperRef = React.createRef();
    }
    
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    UNSAFE_componentWillUpdate(prevProps, prevState) {
        const currentSelected = this.props.selected;
        if (this.props.options.length !== prevProps.options.length) {
            this.setState({
                selected: -1
            })
        } else if (prevProps.selected !== currentSelected) {
            this.setState({
                selected: currentSelected
            })
        }
    }

    handleClickOutside = (e) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            this.setState({
                opened: false
            })
          }
    }

    handleSelect = (i) => {
        this.setState({
            opened: false
        })
        if (this.props.onSelect) {
            this.props.onSelect(i)
        }
    }

    toggleOptions = () => {
        this.setState({
            opened: !this.state.opened
        })
    }

    render() {
      const selectedOption = this.props.options[this.props.selected];
      return (
        <Wrapper ref={this.wrapperRef} >
            <SelectInputWrapper onClick={this.toggleOptions} className={this.state.opened && "opened"}>
                <Label>
                    {this.props.selected !== -1 ? selectedOption && selectedOption.name : this.props.placeholder}
                </Label>
                <CaretWrapper>
                    <Caret />
                </CaretWrapper>
            </SelectInputWrapper>
            <OptionsBox className={this.state.opened && "opened"}>
                <Option key={-1} onClick={this.handleSelect.bind(null, -1)} className={this.props.selected === -1 && "selected"}>
                    {this.props.placeholder}
                </Option>
                {this.props.options && this.props.options.map( (option, i) => (
                    <Option key={i} onClick={this.handleSelect.bind(null, i)} className={this.props.selected === i && "selected"}>
                        {option.name}
                    </Option>
                ))}
            </OptionsBox>
        </Wrapper>
      )
  }
}


export default SelectInput;
