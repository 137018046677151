import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import qs from 'query-string'
import { isMobile } from 'react-device-detect'

import {
  CARDAPIO_CONSULTA,
  GELADEIRA_PEGUEEPAGUE,
  ESTABELECIMENTO_ID,
  MULTI_STORE,
  MESA,
  BALCAO
} from '../config'

import {
  GetQueryString
} from '../common/helpers'

import {
  Fridge
} from '../core/fridge'
import {
  Ecommerce
} from '../core/ecommerce'

import { HeaderBox, HeaderImage } from '../components/header'
import { Menu } from '../components/menu'
import { CartBox, CartBar, CartModal } from '../components/cart'
import { Mobile, Desktop } from '../components/common/ResponsiveComponent'
import { AddressModal } from '../components/address'
import { LoginModal } from '../components/login'
import { device } from '../constants/responsive'
import PageNavigator from '../components/utilities/PageNavigator'
import {
  setSelectedStore,
  getStoreInfoAndMenu,
  getDeliveryFee,
  setDeliveryCounter
} from '../redux/actions/store'
import {
  setNoDelivery
} from '../redux/actions/cart'
import {
  saveTable
} from '../redux/actions/address'
import { navigateTo } from '../redux/actions/pages' 
import { toggleAddressModal } from '../redux/actions/ui'
import {handleGTM} from '../redux/actions/gtm'

import {
  Header as FridgeHeader
} from '../core/fridge/header'

import { CVVModal } from '../components/payment'

const AppWrapper = styled.div`
  display : flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  background: #fff;
`

const HeaderImageWrapper = styled.div`
  width: 100%;
`

const HeaderContainer = styled.div`
  margin-top: -100px;
  z-index: 20;
`

const MainContainer = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  width: 100%;
  min-width: 100%;
  margin-top: 10px;
  background: #fff;

  @media ${device.desktop} {
    width: 80%;
    min-width: 80%;
    margin-bottom: 20px;
    margin-top: 0px;
  }
`

const CartBoxWrapper = styled.div`
  @media ${device.desktop} {
    height: 100%;
    margin-left: 15px;
    margin-top: 20px;
    width: 326px;
  }
`

const CartBarWrapper = styled.div`
  @media ${device.mobile} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 26;
    transition: all 0.3s;
  }
`

const scrollBreakpoint = {
  mobile: 168,
  desktop: 220
}

class HomePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      headerScrolled: false,
      cartModalVisible: props.cartModalVisible
    }

    const { match } = props

    if (match.params && match.params.id) {
      props.setSelectedStore(match.params.id)
    }
  }

  async componentDidMount() {
    const localStorageStore = JSON.parse(localStorage.getItem('store'))

    const storeId = {
      value: MULTI_STORE === 'false' && ESTABELECIMENTO_ID ? ESTABELECIMENTO_ID : localStorageStore && localStorageStore.id// parseInt(this.props.match.params.id) || (localStorageStore && localStorageStore.id) !== 'index.html' ? localStorageStore.id : ''
    }

    const queryString = GetQueryString()

    const {
      deliveryTable,
      deliveryModality
    } = this.props

    if (MESA === 'false' && deliveryModality === 1 && deliveryTable) {
      await this.props.setNoDelivery()
    }

    if (BALCAO === 'false' && MESA === 'false' && deliveryModality === 1) {
      await this.props.setNoDelivery()
    }

    const table = !Object.keys(queryString).length ? null : {
      number: queryString.mesa || false
    }

    if (table === null && deliveryModality === 1 && deliveryTable) {
      await this.props.setNoDelivery()
    }

    if (!storeId.value && GELADEIRA_PEGUEEPAGUE === 'false' && MULTI_STORE === 'false') {
      Object.assign(storeId, {
        value: ESTABELECIMENTO_ID
      })

      await this.props.setSelectedStore(ESTABELECIMENTO_ID)
    }

    if (MESA === 'true' && table !== null && table.number && table.number !== '0' && GELADEIRA_PEGUEEPAGUE === 'false') {
      await this.props.setDeliveryCounter()
      await this.props.saveTable(table.number)
    }

    if (MESA === 'true' && table !== null && (!table.number || table.number === '0') && GELADEIRA_PEGUEEPAGUE === 'false') {
      await this.props.setDeliveryCounter()
      this.props.toggleAddressModal({ show: true, state: 4 })
    }

    if (storeId.value && GELADEIRA_PEGUEEPAGUE === 'false') {
      await this.props.getStoreInfoAndMenu(storeId.value)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const scrollY = window.scrollY
    const breakpoint = isMobile ? scrollBreakpoint.mobile : scrollBreakpoint.desktop
    const shouldScroll = (document.body.offsetHeight - window.innerHeight) > breakpoint

    if (shouldScroll && scrollY > breakpoint && !this.state.headerScrolled) {
      this.setState({headerScrolled: true})

      return
    }
    
    if (scrollY <= breakpoint && this.state.headerScrolled) {
      this.setState({headerScrolled: false})
    }
  }

  handleSelectStore = () => {
    localStorage.removeItem('store')
    localStorage.removeItem('cart')
    localStorage.removeItem('user')
    window.location.href = '/'
  }

  openCartModal = () => {
    this.setState({cartModalVisible: true})
  }

  closeCartModal = () => {
    this.setState({cartModalVisible: false})
  }

  renderFridge() {
    const {
      location,
      storeName
    } = this.props
    const {
      headerScrolled
    } = this.state
    const parsedQuery = qs.parse(location.search)

    return (
      <>
        <FridgeHeader name={storeName} />

        <MainContainer>
          <Menu fixedNav={headerScrolled} queryProduct={parsedQuery.p} />

          <CartBoxWrapper>
            <CartBox fixedPosition={headerScrolled} fixedHeight={true} />
          </CartBoxWrapper>
        </MainContainer>
      </>
    )
  }

  renderConsultationMode() {
    const { location } = this.props
    const {
      headerScrolled,
      cartModalVisible
    } = this.state
    const parsedQuery = qs.parse(location.search)

    return (
      <>
        <PageNavigator />
        
        <HeaderImageWrapper>
          <HeaderImage handleSelectStore={this.handleSelectStore} />
        </HeaderImageWrapper>
        
        <HeaderContainer>
          <HeaderBox stretch={headerScrolled} fixed={headerScrolled} />
        </HeaderContainer>
        
        <MainContainer cartModalVisible={cartModalVisible}>
          <Menu fixedNav={headerScrolled} queryProduct={parsedQuery.p} />
        </MainContainer>
      </>
    )
  }

  renderEcommerce() {
    const { location } = this.props
    const {
      headerScrolled,
      cartModalVisible
    } = this.state
    const parsedQuery = qs.parse(location.search)

    return (
      <>
        <AddressModal />
        <CVVModal />
        
        <LoginModal />
        
        <PageNavigator />
        
        <HeaderImageWrapper>
            <HeaderImage handleSelectStore={this.handleSelectStore} />
        </HeaderImageWrapper>
        
        <HeaderContainer>
          <HeaderBox stretch={headerScrolled} fixed={headerScrolled} />
        </HeaderContainer>
        
        <MainContainer cartModalVisible={cartModalVisible}>
          <Menu fixedNav={headerScrolled} queryProduct={parsedQuery.p} />

          <Desktop>
            <CartBoxWrapper>
              <CartBox fixedPosition={headerScrolled} fixedHeight={true} />
            </CartBoxWrapper>
          </Desktop>
        </MainContainer>
        
        <Mobile>
          <CartBarWrapper>
              <CartBar onClick={this.openCartModal} />
          </CartBarWrapper>
          <CartModal modal isOpen={cartModalVisible} onClose={this.closeCartModal} />
        </Mobile>
      </>
    )
  }

  render() {
    const {
      url
    } = this.props.match

    if (GELADEIRA_PEGUEEPAGUE === 'true') {
      return <Fridge />
    }

    if (GELADEIRA_PEGUEEPAGUE === 'false' && CARDAPIO_CONSULTA === 'false' && url === '/finalizar-compra') {
      return <Ecommerce />
    }

    return (
      <AppWrapper>
        { CARDAPIO_CONSULTA === 'true' && GELADEIRA_PEGUEEPAGUE === 'false' && this.renderConsultationMode() }

        { CARDAPIO_CONSULTA === 'false' && GELADEIRA_PEGUEEPAGUE === 'false' && this.renderEcommerce() }
      </AppWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    storeId: state.store.id,
    storeName: state.store.name,
    deliveryModality: state.cart && state.cart.delivery && state.cart.delivery.modality,
    deliveryTable: state.cart && state.cart.delivery && state.cart.delivery.table
  }
}

export default connect(mapStateToProps, {
  setSelectedStore,
  getStoreInfoAndMenu,
  getDeliveryFee,
  setNoDelivery,
  setDeliveryCounter,
  navigateTo,
  toggleAddressModal,
  saveTable,
  handleGTM
})(withRouter(HomePage))
