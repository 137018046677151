import React, {
  useEffect
} from 'react'
import styled from 'styled-components'

import WelcomeIcon from '../../../assets/welcome-icon.png'

import {
  Login
} from '../../../components/login'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 960px;
  margin-top: 0px;
  min-width: 320px;
  padding-top: 40px;
`;

const ImageContainer = styled.img`
  margin-bottom: 20px;
  width: 70px;
  height: auto;
`

const H2 = styled.h2`
  font-size: 20px;
  color: #41BAC8;
  max-width: 200px;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
`

const Paragraph = styled.p`
  font-size: 14px;
  color: #B1B3B6;
  text-align: center;
  max-width: 280px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
`

function Home(props) {
  const {
    setContent
  } = props

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.id && user.token.accessToken && user.signUpStatus === 1) {
      setContent('check-in')

      return
    }
  }, [props, setContent])

  return <Wrapper>
    <ImageContainer src={WelcomeIcon} />

    <H2>Seja bem vindo ao Pegue e Pague!</H2>

    <Paragraph>Faça o login com seu e-mail ou conecte-se com o Facebook para prosseguir!</Paragraph>

    <Login mode="fridge" onSuccess={() => {
      setContent('check-in')
    }} />
  </Wrapper>
}

export default Home
