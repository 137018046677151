import React from 'react';
import styled from 'styled-components';
import { Icon, Checkbox } from '../common';
import { getCardBrandImageByPaymentTypeAndId } from '../../utils/';

const Wrapper = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 72px;
    cursor: pointer;
`

const CheckboxWrapper = styled.div`
    align-self: flex-end;
`

const CardContentWrapper = styled.div``

const CloseButtonWrapper = styled.div`
    width: 100%;
    text-align: right;
`

const CloseButton = styled.button`
    background: none;
    border: none;
`

const BrandImage = styled.img``

const DigitsWrapper = styled.div`
    padding-top: 5px;
    font-size: 13px;
    color: ${props => props.theme.colors.mediumGrey}
`

class CreditCardCell extends React.Component {
    constructor(props) {
        super(props);
        this.checkbox = React.createRef();
    }

    handleDelete = (e) => {
        e.stopPropagation();
        if (this.props.onDelete) {
            this.props.onDelete(this.props.card);
        }
    }

    handleCheck = (check) => {
        if (this.props.onCheck) {
            this.props.onCheck(this.props.card, check)
        }
    }

    render() {
        const { card } = this.props;
        const { brandId, data, paymentType } = card;
        return (
            <Wrapper>
                <CheckboxWrapper>
                    <Checkbox 
                        checked={this.props.checked} 
                        ref={this.checkbox} 
                        type="radio" 
                        name={"paymentMethod"} 
                        size="sm" 
                        primary 
                        onCheck={this.handleCheck} 
                    />  
                </CheckboxWrapper>
                <CardContentWrapper>
                    <CloseButtonWrapper>
                        <CloseButton onClick={this.handleDelete}>
                            <Icon name="close" width="8px" height="8px" fill={"textBold"} />
                        </CloseButton>
                    </CloseButtonWrapper>
                    <BrandImage src={getCardBrandImageByPaymentTypeAndId(paymentType, brandId)} />
                    <DigitsWrapper>
                        {data}
                    </DigitsWrapper>
                </CardContentWrapper>
            </Wrapper>
        )
    }
}

export default CreditCardCell;