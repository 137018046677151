import { useMediaQuery } from 'react-responsive'
import { sizes } from  '../../constants/responsive'

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: sizes.desktop })
  return isDesktop ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: sizes.mobile })
  return isMobile ? children : null
}