import React from 'react'
import styled from 'styled-components'
import { StoreLogo, Button, TextInput, LoadingOverlay } from '../common'

import { notEmpty, isEmail } from '../common/form/validationFunctions'
import { Form } from '../common/form'
import { device } from '../../constants/responsive'

import {
    LoginPhoneForm,
    SMSCodeForm
} from './'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0px auto;

  @media ${ device.mobile } {
    max-width: 310px;
  }

  @media ${ device.tablet } {
    max-width: 310px;
  }

  @media ${ device.desktop } {
    max-width: 360px;
  }
`

const Paragraph = styled.p`
    font-size: 16px;
    padding: 0px 45px;
`

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const InputWrapper = styled.div`
    width: 100%;
    padding-bottom: 15px;
`

const ActionsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 30px;
`

const ForgotPasswordText = styled.button`
    font-size: 11px;
    border: 0;
    color: ${props => props.theme.colors.textPrimary};
`

const SignUpText = styled.button`
    font-size: 11px;
    border: 0;
    color: ${props => props.theme.colors.success};
`

const Title = styled.span`
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.textPrimary};
`

const Description = styled.span`
    font-size: 14px;
    color: ${props => props.theme.colors.textPrimary};
    padding-bottom: 10px;
`

const Action = styled.a`
    font-weight: bold;
    font-size: 20px;
    color: ${props => props.disabled ? '#DDD': '#CA9128'};
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: ${props => props.disabled ? '#DDD': '#CA9128'};
        cursor: ${props => props.disabled ? 'unset': 'pointer'};
    }
`

const FORM_STATES = {
    LOGIN: 1,
    FORGOT_PASSWORD: 2,
}

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentState: FORM_STATES.LOGIN
        }

        this.formInputs = this.setupForms()
        this.phoneFormInputs = this.setupPhoneForm()
        this.codeFormInputs = this.setupCodeForm()

        this.form = React.createRef()
    }

    showForgotPasswordForm = () => {
        this.setState({currentState: FORM_STATES.FORGOT_PASSWORD})
    }

    showLoginForm = () => {
        this.setState({currentState: FORM_STATES.LOGIN})
    }

    handleSubmit = async (values) => {
        /* First step: Login information  */
        if (this.state.currentState === FORM_STATES.LOGIN && this.props.step === 0) {
            const {
                email,
                pass
            } = values

            await this.props.save({ step: 1 })

            this.props.onLogin(email, pass)

            this.props.handleGTM({
                event: `LOGIN`
            })

            return
        }

        /* Second step: Phone information  */
        if (this.state.currentState === FORM_STATES.LOGIN && this.props.step === 1) {
            const {
                phone
            } = values

            const {
                token
            } = this.props.user

            const {
                accessToken
            } = token

            await this.props.save({ phone })
            const result = await this.props.updatePhone({ 
                phone: this.props.rawPhone, confirmationType: 'sms', authToken: accessToken, userId: this.props.user.id 
            })

            if (result && result.success) {
                this.props.setTime(30)
                this.props.setStep(2)
            }

            return
        }

        /* Third step: Code information  */
        if (this.state.currentState === FORM_STATES.LOGIN && this.props.step === 2) {
            const {
                code
            } = values

            const {
                token
            } = this.props.user

            const {
                accessToken
            } = token

            await this.props.save({ code })
            const confirmation = await this.props.sendConfirmationCode({ code, authToken: accessToken })

            this.props.setTime(30)

            if (confirmation.success) {
                this.props.setConfirmationErrors(0)
                this.props.setConfirmationType('sms')
                this.props.setStep(0)
                await this.props.save({
                    step: 0
                })

                this.props.updatePhone()

                return
            }

            if (this.props.confirmationErrors >= 3) {
                this.props.setConfirmationErrors(0)
                this.props.setConfirmationType('sms')
                this.props.setStep(0)
                await this.props.save({
                    step: 0
                })
            }
        }
        
        /* Forgot Password */
        if (this.state.currentState === FORM_STATES.FORGOT_PASSWORD && this.props.step === 0) {
            const {
                email
            } = values

            this.props.onForgotPassword(email)

            return
        }
    }

    setupForms = () => [
        {
            id: 'email',
            validations: [notEmpty, isEmail],
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="email" placeholder="Email *" keepPlaceholderAbove />
            )
        },
        {
            id: 'pass',
            validations: [notEmpty],
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="pass" placeholder="Senha *" keepPlaceholderAbove
                    inputProps={{
                        type: 'password'
                    }}
                />
            )
        },
    ]

    setupPhoneForm = () => [
        {
            id: 'phone',
            validations: [notEmpty],
            Component: (formInputProps) => (<LoginPhoneForm inputProps={{...formInputProps}} setDDI={this.props.setDDI} setPhone={this.props.setPhone} setStep={this.props.setStep} />)
        }
    ]

    setupCodeForm = () => [
        {
            id: 'code',
            validations: [notEmpty],
            Component: (formInputProps) => (<SMSCodeForm inputProps={{...formInputProps}} confirmationType={this.props.confirmationType} setCode={this.props.setCode} setStep={this.props.setStep} form={this.formRef} />)
        }
    ]

    renderLoginForm = (inputs) => {
        return (
            <FormWrapper>
                <InputWrapper>
                    <inputs.email />
                </InputWrapper>
                <InputWrapper>
                    <inputs.pass />
                </InputWrapper>
                <ActionsWrapper>
                    <ForgotPasswordText type="button" onClick={this.showForgotPasswordForm}>
                        esqueci minha senha
                    </ForgotPasswordText>
                    <SignUpText type="button" onClick={this.props.onSignUp}>
                        CADASTRAR NOVA CONTA
                    </SignUpText>
                </ActionsWrapper>
                <Button type="submit" className="action">ENTRAR</Button>
            </FormWrapper>
        )
    }

    renderPhoneForm = (inputs) => {
        const {
            phone: Phone
        } = inputs

        return (
            <div>
                <Title>Telefone</Title>

                <Paragraph><em>Digite o seu telefone celular para finalizar o seu cadastro.</em></Paragraph>

                <Phone />

                <Button disabled={!this.props.phone} type="submit" className="action">Ok</Button>
            </div>
        )
    }

    renderCodeForm = (inputs) => {
        const {
            code: Code
        } = inputs

        const {
            fullPhone
        } = this.props

        const {
            time
        } = this.props

        return (
            <div>
                <Title>Código de SMS</Title>

                <Paragraph><em>Digite o código de confirmação recebido por SMS no celular abaixo.</em></Paragraph>

                <Paragraph><strong>{fullPhone}</strong></Paragraph>
                
                <Code />

                {
                    this.props.confirmationType === 'phone' && this.props.confirmationErrors >= 1 && this.props.confirmationErrors <= 1 ? <Action disabled={time} href='#' onClick={async (event) => {
                        event.preventDefault()

                        if (time) {
                            return
                        }

                        this.props.setTime(30)
                        await this.props.sendCode({ phone: this.props.rawPhone, confirmationType: 'phone' })

                        this.props.setConfirmationErrors(this.props.confirmationErrors + 1)
                    }} className="action">Receber ligação{ time ? `: ${time}` : '' }</Action> : <></>
                }

                {
                    this.props.confirmationType === 'sms' && this.props.confirmationErrors <= 1 ? <Action disabled={time} href='#' onClick={async (event) => {
                        event.preventDefault()

                        if (time) {
                            return
                        }

                        this.props.setTime(30)
                        await this.props.sendCode({ phone: this.props.rawPhone, confirmationType: 'sms', resend: true })
            
                        this.props.setConfirmationErrors(this.props.confirmationErrors + 1)
                        this.props.setConfirmationType('phone')
                    }} className="action">Reenviar código{ time ? `: ${time}` : '' }</Action> : <></>
                }
            </div>
        )
    }

    renderForgotPasswordForm = (inputs) => {
        return (
            <FormWrapper>
                <Title>
                    Esqueci minha senha
                </Title>
                <Description>
                    Uma nova senha será enviada para o email informado abaixo.
                </Description>
                <InputWrapper>
                    <inputs.email />
                </InputWrapper>
                <ActionsWrapper>
                    <Button className="back" onClick={this.showLoginForm}>
                        Voltar
                    </Button>
                    <Button className="action" type="submit">
                        Recuperar senha
                    </Button>
                </ActionsWrapper>
            </FormWrapper>
        )
    }


    render() {
        const {
            formInputs,
            phoneFormInputs,
            codeFormInputs
        } = this

        const {
            showLogo,
            logo
        } = this.props

        return (
            <Wrapper>
                <LoadingOverlay visible={this.props.loading} margin={"5px"} />
                { showLogo === true && <StoreLogo theme={{logoImage: logo}} style={{ marginBottom: '15px' }} /> }

                {
                    this.state.currentState === FORM_STATES.LOGIN && this.props.step === 0 ? <Form
                        ref={this.form}
                        formInputs={formInputs}
                        onSubmit={this.handleSubmit}
                        >
                            { (inputs) => this.renderLoginForm(inputs) }
                    </Form> : <></>
                }

                {
                    this.state.currentState === FORM_STATES.LOGIN && this.props.step === 1 ? <Form
                        ref={(form) => (this.formRef = form) }
                        phoneFormInputs={phoneFormInputs}
                        onSubmit={this.handleSubmit}
                        >
                            { (inputs) => this.renderPhoneForm(inputs) }
                    </Form> : <></>
                }

                {
                    this.state.currentState === FORM_STATES.LOGIN && this.props.step === 2 ? <Form
                        ref={(form) => (this.formRef = form) }
                        codeFormInputs={codeFormInputs}
                        onSubmit={this.handleSubmit}
                        >
                            { (inputs) => this.renderCodeForm(inputs) }
                    </Form> : <></>
                }

                {
                    this.state.currentState === FORM_STATES.FORGOT_PASSWORD ? <Form
                        ref={this.form}
                        formInputs={formInputs}
                        onSubmit={this.handleSubmit}
                        >
                            { (inputs) => this.renderForgotPasswordForm(inputs) }
                    </Form> : <></>
                }
            </Wrapper>
        )
    }
}

export default LoginForm;