import { actionTypes } from "../actions/auth";
import moment from 'moment';

let sessionAuth = JSON.parse(localStorage.getItem('auth'))

const now = moment();
if (sessionAuth && moment(sessionAuth.auth.expires).isBefore(now)) {
  localStorage.setItem('auth', null);
  sessionAuth = null;
}

const initialState = sessionAuth || null

const authReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case actionTypes.SET_AUTH: {
      newState = action.payload;
      break;
    }
    default: {
      newState = state;
      break;
    }
  }
  localStorage.setItem('auth', JSON.stringify(newState));
  return newState
};

export default authReducer;
