import React from 'react';
import styled from 'styled-components';
import { Modal, TextInput, Button, Box, LoadingOverlay, Notification } from '../common';
import { Form } from '../common/form';
import { notEmpty } from '../common/form/validationFunctions';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-width: 320px;
`

const TitleText = styled.span`
    font-size: 20px;
    font-weight: 700;
    color: ${props => props.theme.colors.textBold};
    padding: 20px 0;
`

const InputWrapper = styled.div`
    padding: 20px 0;
    width: 100%;
`

const ButtonWrapper = styled.div`
    padding: 20px 0;

    > button {
        margin-right: 20px;

        &:last-child {
            margin-right: 0px;
        }
    }
`

class CouponModal extends React.Component {

    constructor(props) {
        super(props);
        this.formInputs = this.setupForms();
    }

    setupForms = () => [
        {
            id: 'coupon',
            validations: [notEmpty],
            Component: (formInputProps) => {
                return <TextInput {...formInputProps} icon="tag" placeholder="Insira o código do cupom de desconto" />
            }
        }
    ]

    handleSubmit = ({coupon}) => {
        this.props.handleAddCoupon(coupon)
    }

    handleRemove = () => {
        this.props.handleRemoveCoupon()
    }

    render() {
        return (
            <Modal name="coupon" isOpen={this.props.isOpen} onClose={this.props.onClose}>
                <Notification type="error" text={this.props.error} onDismiss={this.props.onDismissCouponError} timeout={5} />
                <LoadingOverlay visible={this.props.loading} />
                <Box>
                    <Form formInputs={this.formInputs} onSubmit={this.handleSubmit}>
                        { (inputs) => {
                            return (
                                <Wrapper>
                                    <TitleText>Adicionar cupom</TitleText>
                                    <InputWrapper>
                                        <inputs.coupon value={this.props.coupon} />
                                    </InputWrapper>
                                    <ButtonWrapper>
                                        { !this.props.coupon && <Button type="submit" className="action">ADICIONAR</Button> }

                                        { this.props.coupon && <Button type="submit" className="action">ALTERAR</Button> }

                                        { this.props.coupon && <Button type="button" className="action" onClick={() => {
                                            this.handleRemove()
                                        }}>REMOVER</Button> }
                                    </ButtonWrapper>
                                </Wrapper>      
                            )
                        }}
                    </Form>
                </Box>
            </Modal>
        )
    }
}

export default CouponModal;