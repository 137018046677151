import { actionTypes } from "../actions/gtm";

const initialState = {
  events: null
}

const gtmReducer = (state = initialState, action) => {
  const sessionGTM = JSON.parse(localStorage.getItem('gtm')) || {}
  const loadedState = {
    ...state,
    ...sessionGTM
  }

  const newState = {
    value: loadedState
  }

  switch (action.type) {
    case actionTypes.SET_GTM:
      Object.assign(newState, {
        value: {
          ...newState.value,
          ...action.payload
        }
      })

      break

      default:
  }

  localStorage.setItem("gtm", JSON.stringify(newState.value));
  return newState.value;
};

export default gtmReducer;
