import { actionTypes } from "../actions/requests";

const requests = {
  [actionTypes.REQUEST_STORE]: 'store',
  [actionTypes.REQUEST_SIGN_UP]: 'signup',
  [actionTypes.REQUEST_LOGIN]: 'login',
  [actionTypes.REQUEST_CEP]: 'searchCep',
  [actionTypes.REQUEST_SAVE_SIGN_UP] : 'save',
  [actionTypes.REQUEST_UPDATE_PHONE] : 'updatePhone',
  [actionTypes.REQUEST_SAVE_ADDRESS] : 'saveAddress',
  [actionTypes.REQUEST_CLEAR_ADDRESS] : 'clearAddress',
  [actionTypes.REQUEST_SAVE_CVV] : 'saveCVV',
  [actionTypes.REQUEST_FETCH_PAYMENT_METHODS] : 'fetchPaymentMethods',
  [actionTypes.REQUEST_FETCH_CARD_BRANDS] : 'fetchCardBrands',
  [actionTypes.REQUEST_ADD_COUPON] : 'addCoupon',
  [actionTypes.REQUEST_DELETE_CARD] : 'deleteCard',
  [actionTypes.REQUEST_ANALYSE_ORDER] : 'analyseOrder',
  [actionTypes.REQUEST_PLACE_ORDER]: 'placeOrder',
  [actionTypes.REQUEST_ADD_CARD]: 'addCard',
  [actionTypes.REQUEST_STORE]: 'storeInfo',
  [actionTypes.REQUEST_FORGOT_PASSWORD]: 'forgotPassword',
  [actionTypes.REQUEST_AVAILABLE_CITIES]: 'availableCities',
  [actionTypes.REQUEST_AVAILABLE_STORES]: 'availableStores',
  [actionTypes.REQUEST_CLOSEST_STORE]: 'closestStore'
}

const initialState = Object.values(requests).reduce( (acc, id) => (
{
  ...acc,
  [id]: {
    loading: false,
    success: false,
    error: false
  }
}), {})

const requestsReducer = (state = initialState, action) => {
  const requestKey = action.type;
  const requestId = requests[requestKey];
  if (requestId) {
    return {
      ...state,
      [requestId]: action.payload
    }
  }
  return state;
};

export default requestsReducer;
