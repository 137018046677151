
import alelo from '../assets/card_brand_alelo.svg';
import ben from '../assets/card_brand_ben.svg';
import sodexo from '../assets/card_brand_sodexo.svg';
import ticket from '../assets/card_brand_ticket.svg';
import vr from '../assets/card_brand_vr.svg';
import cash from '../assets/cash_payment.svg';

import getCardBrandImageById from './getCardBrandImageById';
import { PAYMENT_TYPES } from '../constants/payment'

const getCardBrandImageByPaymentTypeAndId = (paymentType, brandId) => {
    switch(paymentType) {
        case PAYMENT_TYPES.ALELO:
            return alelo
        case PAYMENT_TYPES.BEN:
            return ben
        case PAYMENT_TYPES.TICKET_VR:
            return ticket
        case PAYMENT_TYPES.SODEXO:
            return sodexo
        case PAYMENT_TYPES.VR:
            return vr
        case PAYMENT_TYPES.CASH:
            return cash
        default:
            return getCardBrandImageById(brandId)
    }
}

export default getCardBrandImageByPaymentTypeAndId;