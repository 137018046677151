import React from 'react';
import styled from 'styled-components'
import defaultBrandImage from '../../../assets/default_brand.svg'

const Container = styled.div`
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CardBrand = styled.img`
    padding: 7px 13px;
    border: 1px solid ${props => props.theme.colors.mediumGrey};
    border-radius: 5px;
    border-style: thin;
`

const Text = styled.span`
    padding-left: 17px;
`

const BrandContainer = ({brandImage, text}) => (
    <Container>
        {brandImage
            ? (<CardBrand src={brandImage} alt={text} />)
            : (<img src={defaultBrandImage} alt={text} />)
        } 
        {text && (
            <Text>{text}</Text>
        )}
    </Container>
)

export default BrandContainer;