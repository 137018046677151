import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    border: 1px solid #fff;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
`

const Number = styled.span`
    font-size: 12px;
    color: white;
    font-weight: 700;
`

const CartBadge = ({number}) => (
    <Wrapper>
        <Number>{number}</Number>
    </Wrapper>
)

export default CartBadge;