import Card from '../models/card'
import { CARD_BRANDS_TAG } from '../constants/payment'

interface cardsResponse {
  availableOnlineCards: [Card]  
  availableOfflineCards: [Card]
  success: boolean
  message: string
  selectedCard?: any
}

interface brandsResponse {
  success: boolean,
  message: string,
  brandsImages: [string]
}

interface couponResponse {
  id: number,
  value: number,
  type: number,
  success: boolean,
  message: string
}

interface deleteCardResponse {
  success: boolean,
  message: string
}

interface orderProduct {
  id: number,
  addons: [{
    id: number;
    groupId: number;
  }],
  quantity: number,
  observation?: string
}

interface Konduto {
  id: string,
  statusCall: number,
  statusOrder: number,
  recomendation: number,
  score: number
}

// interface ProductError{
//   id: string,
//   descricao: string,
//   titulo: string
// }

interface analyseOrderResponse {
  error: boolean | any
  callback: string
  success: boolean
  message: string,
  error_type: number,
  products_error: []
  konduto: Konduto
}

interface analyseOrderRequest { 
  cardId: number
  paymentType: number
  useCpf: boolean
  cvv: number
  products: [orderProduct]
  deliveryAddressId: number
  deliveryModality: number
  deliveryPrice: number
  brandId: number
  cashChange: number
  couponId: number
  storeId: number
  isOfflinePayment: boolean
  table: number | string
}

interface placeOrderRequest extends analyseOrderRequest {
  konduto: Konduto
}

interface placeOrderResponse {
  success: boolean
  error: boolean | any
  callback: string
  message: string
  orderId?: number
  deliveryEstimate?: string
  deliveryStatus?: number
	paymentType: number
	paymentStatus: number
}

interface CardForm {
  cardNumber: string, 
  cardName: string, 
  cardDate: string, 
  cardCVV: string, 
  cardCpf: string, 
  brandId: number
}

interface MundipaggAccessTokenResponse {
  success: boolean,
  message: string,
  accessToken: string,
  userId: string,
}

interface AddCardData {
  mundipaggCardId: string,
  mundipaggUserId: string,
  digits: number,
  firstDigits: number,
  brandId: number,
  type: number,
  cpf: number,
  expMonth: number,
  expYear: number
}

interface AddCardResponse {
  success: boolean,
  message: string,
}

const ONLINE_PAYMENT_TYPE = "Cartão de Crédito e Vale Refeição"
const OFFLINE_PAYMENT_TYPE = "Pagamento Presencial"

const mapApiCardToModel = (card: any) : Card => {
  return {
    id: card["CartaoId"],
    data: card["Dados"],
    brand: card["Bandeira"],
    brandId: card["BandeiraId"],
    paymentType: card["TipoPagamento"],
    cvvRequired: card["PedirCvv"],
    cardType: card["TipoCartao"],
    active: card["Ativo"],
    discount: card["Desconto"],
    discountPercentage: card["ValorDesconto"],
    discountMaxValue: card["ValorMaximo"]
  }
}

export const mapCardsApiResponse = (response: any) : cardsResponse => {
  const userCards = 
    response["CartoesUsuario"] && 
    response["CartoesUsuario"].find( (card: any) => card["Tipo"] === ONLINE_PAYMENT_TYPE)
  const availableOnlineCards = userCards && 
                  userCards["Cartoes"]
                      .filter( (card: any) => card["Ativo"] === true)
                      .map( (card: any) : Card => mapApiCardToModel(card))
  const offlinePayments = 
    response["PagamentosNaEntrega"] && 
    response["PagamentosNaEntrega"].find( (card: any) => card["Tipo"] === OFFLINE_PAYMENT_TYPE)

  const availableOfflineCards = offlinePayments && 
                  offlinePayments["Cartoes"]
                    .filter( (card: any) => card["Ativo"] === true)
                    .map( (card: any) : Card => mapApiCardToModel(card))
  
  const selectedCard = {
    id: response['CartaoSelecionado'] && response['CartaoSelecionado'].CartaoId,
    data: response['CartaoSelecionado'] && response['CartaoSelecionado'].Dados,
    brand: response['CartaoSelecionado'] && response['CartaoSelecionado'].Bandeira,
    brandId: response['CartaoSelecionado'] && response['CartaoSelecionado'].BandeiraId,
    paymentType: response['CartaoSelecionado'] && response['CartaoSelecionado'].TipoPagamento,
    cvvRequired: response['CartaoSelecionado'] && response['CartaoSelecionado'].TipoPagamento
  }
  
  return {
    success: response["Sucesso"],
    message: response["Mensagem"],
    availableOnlineCards,
    availableOfflineCards,
    selectedCard
  };
}

export const mapBrandsApiResponse = (response: any) : brandsResponse => {
  return {
    success: response["Sucesso"],
    message: response["Mensagem"],
    brandsImages: response["Bandeiras"]
  }
}

export const mapCouponCodeToAPI = (coupon: string) : any => {
  return {
    "Nome": coupon
  }
}

export const mapCouponApiResponse = (response: any) : couponResponse => {
  return {
    id: response["CupomId"],
    value: response["Valor"],
    type: response["TipoValorCupomId"],
    success: response["Sucesso"],
    message: response["Mensagem"]
  }
}

export const mapDeleteCardRequestToApi = (card: Card) : any => {
  return {
    "CartaoId": card.id,
    "BandeiraId": card.brandId
  }
}

export const mapDeleteCardResponse = (response: any) : deleteCardResponse => {
  return {
    success: response["Sucesso"],
    message: response["Mensagem"]
  }
}

export const mapOrderProductToAPi = (product : orderProduct) : any => {
  return {
    "Codigo": product.id,
    "Acompanhamentos": product.addons.map(addon => ({"Codigo": addon.id, "GrupoId": addon.groupId})),
    "Quantidade": product.quantity,
    "Observacao": product.observation
  }
}

interface ticketOrderResponse {
	success: boolean
	error: boolean | any
	callback: string
	message: string
}

export const mapTicketOrderResponse = (response: any) : ticketOrderResponse => {
  return {
    success: response["Sucesso"],
    error: !response["Sucesso"] ,
    callback: !!(response["Sucesso"] && response['StatusPagamento'] === 3) ? 'success' : 'failed',
    message: response["Mensagem"]
  }
}

export const mapAnalyseOrderRequest = (orderData : analyseOrderRequest) : any => {
  return {
    "CartaoId": orderData.cardId,
    "EstabelecimentoId": orderData.storeId,
    "TipoPagamento": orderData.paymentType,
    "Cpf": orderData.useCpf,
    "Cvv": orderData.cvv,
    "Produtos": orderData.products.map(product => mapOrderProductToAPi(product)),
    "ModalidadeRetirada" : orderData.deliveryModality,
    "ModalidadeRetiradaValor" : orderData.deliveryPrice,
    "EnderecoDeliveryId": orderData.deliveryAddressId,
    "BandeiraId": orderData.brandId,
    "PagarNaEntrega": orderData.isOfflinePayment,
    "Troco": orderData.cashChange,
    "CupomId": orderData.couponId,
    "LocalEntregaBalcao": orderData.table
  }
}

export const mapAnalyseOrderResponse = (response: any) : analyseOrderResponse => {
  const mapProductsError = response["ProdutosIndisponiveis"]
  
  return {
    success: response["Sucesso"],
    error: !response["Sucesso"],
    callback: response["Sucesso"] ? 'success' : 'failed',
    message: response["Mensagem"],
    error_type: response["TipoFalhaPedido"],
    products_error: mapProductsError,
    konduto: {
      id: response["IdPedidoKonduto"],
      statusCall: response["StatusChamada"],
      statusOrder: response["StatusPedido"],
      recomendation: response["Recomendacao"],
      score: response["Score"]
    }
  }
}

const mapKondutoToApi = (konduto: Konduto) : any => {
  return {
    "IdPedidoKonduto": konduto.id,
    "StatusChamada": konduto.statusCall,
    "StatusPedido": konduto.statusOrder,
    "Recomendacao": konduto.recomendation,
    "Score": konduto.score
  }
}

export const mapPlaceOrderRequest = (orderData: placeOrderRequest) : any => {
  return {
    ...mapAnalyseOrderRequest(orderData),
    konduto: mapKondutoToApi(orderData.konduto)
  }
}

const getCallback = (response: any) => {
  if (response['TipoPagamento'] === 3) {
		return 'ticket'
	}

	if (!!response['Sucesso']) {
		return 'success'
	}

	return 'failed'
}

export const mapPlaceOrderResponse = (response : any) : placeOrderResponse => {
  const success = response["Sucesso"];
  const orderDetail = success 
            ? {
              orderId: response["ComandaBalcaoId"],
              deliveryEstimate: response["NotificacaoPedido"]["PrevisaoEntregaTexto"],
              deliveryStatus: response["NotificacaoPedido"]["StatusDelivery"]
            }
            : {}
	
	const callback = getCallback(response)

  return {
    success: response["Sucesso"],
		paymentType: response["TipoPagamento"],
		paymentStatus: response["StatusPagamento"],
    error: !response["Sucesso"],
    callback,
    message: response["Mensagem"],
    ...orderDetail
  }
}

export const mapMundipaggAccessTokenResponse = (response: any) : MundipaggAccessTokenResponse => {
  return {
    success: response["Sucesso"],
    message: response["Mensagem"],
    accessToken: response["AccessToken"],
    userId: response["UsuarioIdMundiPagg"],
  }
}

export const mapCardToMundipaggApiRequest = (cardForm: CardForm) : any => {
  return {
    number: cardForm.cardNumber,
    holder_name: cardForm.cardName,
    holder_document: cardForm.cardCpf,
    exp_month: cardForm.cardDate.slice(0, 2),
    exp_year: cardForm.cardDate.slice(-2),
    brand: CARD_BRANDS_TAG[cardForm.brandId],
    cvv: cardForm.cardCVV,
    private_label: false,
    options: {
      verify_card: false
    }
  }
}

export const mapAddCardRequestToApi = (data: AddCardData) : any => {
  return {
    "CartaoIdMundiPagg": data.mundipaggCardId,
    "UsuarioIdMundiPagg": data.mundipaggUserId,
    "Digitos": data.digits,
    "Bandeira": data.brandId,
    "TipoCartao": data.type,
    "Documento": data.cpf,
    "PrimeirosDigitos": data.firstDigits,
    "UltimosDigitos": data.digits,
    "MesValidade": data.expMonth,
    "AnoValidade": data.expYear
  }
}

export const mapAddCardResponse = (response: any) : AddCardResponse => {
  return {
    success: response["Sucesso"],
    message: response["Mensagem"]
  }
}

export const mapAddTicketCardRequestToApi = (card: CardForm) : any => {
  return {
    "DataNascimento": card.cardDate,
    "Numero": card.cardNumber,
    "Portador": card.cardName
  }
}

export const mapAddTicketCardResponse = (response: any) : AddCardResponse => {
  return {
    success: response["Sucesso"],
    message: response["Mensagem"]
  }
}
