import React from 'react';
import styled, { css } from 'styled-components'
import {default as ReactModal} from 'react-modal';
import { withRouter } from 'react-router';

import { Icon, Box } from './'
import { device } from '../../constants/responsive';
import { isMobile } from 'react-device-detect';

const MODAL_ANIM_TIME = 300;

const defaultStyle = {
    overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
        display: 'flex',
        justifyContent: 'center',
    },
    content : {
      top: 0,
      bottom: 'initial',
      left: isMobile ? 'initial' : '50%',
      right: 'initial',
      transform: isMobile ? null : 'translateX(-50%)',
      border : '0',
      background : 'transparent',
      overflow: 'initial',
      width: isMobile ? '85%' : 'auto',
      padding: isMobile ? '20px 0' : '20px'
    }
};

const Wrapper = styled.div`
    width: 100%;
    min-width: 260px;
    margin: 0px auto;
    display: flex;
    justify-content: center;

    @media ${device.desktop} {
        max-width: 480px;
    }

    ${props => props.fillHeight && css`
        height: 100%
    `}
`

const ModalContent = styled(Box).attrs({
    width: '100%'
})`
    position: relative;

    @media ${device.desktop} {
        margin: 40px;
    }

    ${props => props.noBorder && `
        border-radius: 0 !important; 
    `}
`

const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 101;

    @media ${device.desktop} {
        top: 0;
        right: 0;
        transform: translate(100%, -100%);
    }
`

const stopPropagation = (e) => e.stopPropagation();

const mergeStyles = (style = {}) => ({
    overlay: {
        ...defaultStyle.overlay,
        ...style.overlay
    },
    content: {
        ...defaultStyle.content,
        ...style.content
    }
})

ReactModal.setAppElement('#root')

class Modal extends React.Component {

    handleClose = () => {
        if (this.props.disableClose){
            return
        }
        /* if (this.props.history.location.hash === `#${this.props.name}`) {
            this.props.history.goBack();
        } */
        this.props.onClose();
    }

    pushHistory = () => {
        // this.props.history.push(`#${this.props.name}`)
        window.onpopstate = this.handleClose;
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.pushHistory();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen !== prevProps.isOpen) {
             if (this.props.isOpen) {
                this.pushHistory();
             } else {
                 window.onpopstate = null;
             }
        }
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    render() {
        const {isOpen, children, style, fillHeight, disableClose, noBorder, animated} = this.props;

        return (
            <ReactModal
                isOpen={isOpen}
                style={mergeStyles(style)}
                shouldCloseOnOverlayClick={!disableClose}
                shouldCloseOnEsc={!disableClose}
                onRequestClose={this.handleClose}
                className={animated ? 'animated' : undefined}
                closeTimeoutMS={animated ? MODAL_ANIM_TIME : undefined}
            >
                <Wrapper onClick={this.handleClose} fillHeight={fillHeight}>
                    <ModalContent onClick={stopPropagation} noBorder={noBorder}>
                        {!disableClose && (
                            <CloseButtonWrapper onClick={this.handleClose}>
                                <Icon name="close" stroke={(isMobile ? 'darkGrey' : 'white')} fill={(isMobile ? 'darkGrey' : 'white')} />
                            </CloseButtonWrapper>
                        )}
                        {children}
                    </ModalContent>
                </Wrapper>
            </ReactModal>
        )
    }
};

export default withRouter(Modal);
