import React from 'react';
import { Desktop, Mobile } from '../../common/ResponsiveComponent';
import MenuNavDesktop from './MenuNavDesktop';
import MenuNavMobile from './MenuNavMobile';

const MenuNav = (props) => (
    <>
        <Desktop>
            <MenuNavDesktop {...props} />
        </Desktop>
        <Mobile>
            <MenuNavMobile {...props} />
        </Mobile>
    </>
)


export default MenuNav;