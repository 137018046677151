import React from 'react';
import styled from 'styled-components';
import { Form } from '../../common/form';
import { TextInput } from '../../common';
import { notEmpty, isCreditCard } from '../../common/form/validationFunctions';
import { BrandContainer } from './';
import { getCardBrandImageById, getCardBrand } from '../../../utils';
import onlyNumbers from '../../../utils/onlyNumber';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
` 

class CardNumberForm extends React.Component {

    constructor(props) {
        super(props);
        this.formInputs = this.setupInputs();
        this.state = {
            brandId: undefined
        }
    }

    setupInputs = () => [
        {
            id: 'cardNumber',
            validations: [notEmpty, isCreditCard],
            beforeSubmit: onlyNumbers,
            Component: (formInputProps) => (
                <TextInput {...formInputProps} mask={"9999 9999 9999 9999"} name="cardNumber" placeholder="0000 0000 0000 0000" fontSize={19} center />
            )
        }
    ]

    handleSubmit = ({cardNumber}) => {
        this.props.onSubmit({brandId: this.state.brandId, cardNumber})
    }

    handleInputChange = (inputId, value) => {
        const brand = getCardBrand(onlyNumbers(value));
        this.setState({brandId: brand ? brand.id : null})
    }

    render() {
        const brandImage = this.state.brandId && getCardBrandImageById(this.state.brandId);
        return (
            <Form formInputs={this.formInputs} onSubmit={this.handleSubmit} onChange={this.handleInputChange} ref={this.props.formRef}>
                {(inputs) => (
                    <Wrapper>
                        <BrandContainer brandImage={brandImage} />
                        <inputs.cardNumber />
                    </Wrapper>
                )}
            </Form>    
        )
    }
}

export default CardNumberForm;