import { actionTypes } from "../actions/auth";
import moment from 'moment';

let sessionUser = JSON.parse(localStorage.getItem('user'))

const now = moment();
if (sessionUser && moment(sessionUser.token.expires).isBefore(now)) {
  localStorage.setItem('user', null);
  sessionUser = null;
}

const initialState = sessionUser || null

const userReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case actionTypes.SET_USER: {
      newState = action.payload;
      break;
    }
    default: {
      newState = state;
      break;
    }
  }
  localStorage.setItem('user', JSON.stringify(newState));
  return newState
};

export default userReducer;
