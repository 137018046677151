import React, {
  useState
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import {
  fetchAvailableCardBrands,
  deleteCard,
  addCard,
  addTicketCard
} from '../../../redux/actions/payment'
import {
  setPaymentType,
  fetchPaymentMethods
} from '../../../redux/actions/fridge'
import {
  setCpfOnReceit,
  setPaymentCashChange
} from '../../../redux/actions/cart'

import { OnlinePaymentBlock } from '../../../components/payment'
import { Button } from '../../../components/common'


const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 960px;
  margin-top: 0px;
  min-width: 320px;
  padding-top: 40px;
`;

const H2 = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
`

const ButtonContainer = styled.button`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #41BAC8;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  flex: none;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 10px 0px;
  width: 100%;
  max-width: 280px;
`

const DeletionConfirmationMessage = styled.p`
  margin-bottom: 1rem;
  font-size: 12px;
  text-align: center;
  color: ${props => props.theme.colors.mediumGrey};
`

const DeletionConfirmationContainer = styled.div`
  padding: .75rem;
  margin: 1rem 1rem 2rem 1rem;
  border: 1px solid rgba(184, 184, 184, .7);

  @media (min-width: 375px) {
    margin: 1rem 0 2rem 0;
  }

  ${DeletionConfirmationMessage}
`

const DeletionConfirmationButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    flex: 0 1 calc(50% - .5rem);

    &:first-of-type {
      margin-right: .5rem;
    }
  }
`

const ErrorWrapper = styled.span`
    background-color: ${props => props.theme.colors.error};
    color: ${props => props.theme.colors.lightGrey};
    font-size: 14px;
    border-radius: 5px;
    padding: .75rem;
    margin-bottom: 1rem;
    min-width: 12rem;
    text-align: center;
`

function Cards(props) {
  const {
    setContent
  } = props

  const [selectedCard, setSelectedCard] = useState({})
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false)
  const [deleteCard, setDeleteCard] = useState(null)
  const [currentError, setCurrentError] = useState(null)
  const [deletionCardIsPending, setDeletionCardIsPending] = useState(false)

  const handleDeleteCard = (card) => {
    setDeleteCard(card)
    setCurrentError(null)
    setShowDeleteCardModal(true)
  }
  
  const handleConfirmDeleteCard = async () => {
    setDeletionCardIsPending(true)
    setCurrentError(null)
    const result = await props.deleteCard(deleteCard);

    if (result.success) {
      props.fetchPaymentMethods();
    } else {
      setCurrentError(result.error || 'Erro ao deletar o cartão')
    }

    setDeleteCard(null)
    setShowDeleteCardModal(false)
    setDeletionCardIsPending(false)
  }
  const handleCloseDeleteCardModal = () => setShowDeleteCardModal(false)

  const handleCardSelect = (card, check) => {
    if (check) {
      setSelectedCard(card)
      props.setPaymentType({type: card.paymentType, card})

      return
    }
    
    setSelectedCard(null)
  }

  const handleOnlineCardSelect = (card, check) => {
    handleCardSelect(card, check, false)
  }

  const handleShowAddNewCardModal = () => {
    setContent('new-card')
  }

  return <Wrapper>
    <H2>
      Cartões cadastrados
    </H2>

    {currentError && <ErrorWrapper>{currentError}</ErrorWrapper>}

    { showDeleteCardModal && 
      <DeletionConfirmationContainer>
        <DeletionConfirmationMessage>
          { deleteCard && deleteCard.data ? `Você tem certeza que deseja excluir o cartão ${deleteCard.data}?` : 
            'Você tem certeza que deseja excluir esse cartão?' }
        </DeletionConfirmationMessage>
        <DeletionConfirmationButtonsWrapper>
          <Button 
            className="primary"
            onClick={handleCloseDeleteCardModal}
          >Cancelar</Button>
          <Button 
            className="action"
            onClick={handleConfirmDeleteCard}
            disabled={deletionCardIsPending}
          >Excluir</Button>
        </DeletionConfirmationButtonsWrapper>
      </DeletionConfirmationContainer> }

    <OnlinePaymentBlock 
      cards={props.onlineCards}
      onCheckCard={handleOnlineCardSelect}
      onDeleteCard={handleDeleteCard}
      onAddCard={handleShowAddNewCardModal}
      selected={selectedCard}
    />

    <ButtonContainer onClick={() => {
      setContent('with-card')
    }}>Voltar</ButtonContainer>
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeName: state.store && state.store.name,
    userName: state.user && state.user.name,
    cardBrand: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.brandId,
    cardNumber: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.data,
    onlineCards: state.fridge && state.fridge.onlineCards
  }
}

export default connect(
  mapStateToProps, {
    fetchPaymentMethods,
    fetchAvailableCardBrands,
    setPaymentType,
    setCpfOnReceit,
    deleteCard,
    addCard,
    addTicketCard,
    setPaymentCashChange
  }
)(withRouter(Cards))
