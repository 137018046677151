import React from 'react'
import styled from 'styled-components'

import LoadingGIF from '../../../assets/loading.gif'

import { device } from '../../../constants/responsive'

import {
  getCardBrandImageById,
  numberToCurrency,
  formatCpf
} from '../../../utils'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0px;

  @media ${ device.desktop } {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  > div {
    background: #fff;
    box-sizing: border-box;

    @media ${ device.desktop } {
      padding: 20px;
      border-radius: 10px;
      box-shadow: #ddd 3px 3px 6px;
      min-width: 620px;
      max-width: 620px;
      text-align: left;

      &.status_0 {
        border: #FB9D19 2px solid;
      }

      &.status_1 {
        border: #80D1A1 2px solid;
      }
    }

    @media ${ device.mobile } {
      &.status_0 {
        border: 0px none;
      }

      &.status_1 {
        border: 0px none;
      }
    }

    &.sidebar {
      min-width: 280px;
      max-width: 280px;

      @media ${ device.desktop } {
        margin-left: 20px;
        min-width: 320px;
        max-width: 320px;
      }
    }
  }
`;

const H2 = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
  min-width: 280px;
  flex: 1;

  @media ${ device.desktop } {
    min-width: unset;
    max-width: unset;
    text-align: left;
    margin-bottom: 0px;
  }

  > span.status_0 {
    color: #FB9D19;
  }

  > span.status_1 {
    color: #3ABE71;
  }
`

const H3 = styled.h3`
  font-size: 18px;
  color: #000;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
  min-width: 280px;
  max-width: 280px;
`

const List = styled.ul`
  list-style: inside none;
  min-width: 280px;
  max-width: 280px;
  padding: 0px;
  margin: 0px;
  flex: 1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: #B8B8B8 1px solid;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0px -20px;
    padding: 0px 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`

const ListItem = styled.li`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #000;
  font-size: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  > span {
    flex: 1;

    &:nth-child(1) {
      max-width: 30px;
    }

    &:nth-child(2) {
      > span {
        display: block;
        font-size: 12px;
        color: #939598;
      }
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
`

const ImageContainer = styled.img`
  width: 100px;
  height: auto;
`

const Paragraph = styled.p`  
  min-width: 280px;
  padding: 0px;
  margin: 0px;
  color: #7D7D7D;
`

const StatusBox = styled.div`
  min-width: 280px;
  max-width: 280px;
  flex: 1;
  margin-bottom: 30px;
  border: #000 2px solid;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;

  @media ${ device.desktop } {
    min-width: unset;
    max-width: unset;
    text-align: left;
    padding: 0px;
    margin-bottom: 0px;

    > p:nth-child(3) {
      text-align: center;
      > strong {
        font-size: 24px;
      }
    }
  }

  &.status_0 {
    border: #FB9D19  2px solid;
    background: #fff;

    @media ${ device.desktop } {
      border: 0px none;
      background: none;
    }
  }

  &.status_1 {
    border: #3ABE71 2px solid;
    background: #80D1A1;

    @media ${ device.desktop } {
      border: 0px none;
      background: none;

      > p {
        color: #000;

        &.requestNumber {
          background: #80D1A1;

          > span {
            display: inline-block;
            color: #fff;
            font-size: 40px;
          }

          > strong {
            color: #fff;
            font-size: 40px;
          }
        }

        > strong {
          color: #000;
        }
      }
    }

    @media ${ device.mobile } {
      > p {
        color: #fff;

        &.requestNumber {
          > span {
            display: none;
          }

          > strong {
            color: #fff;
          }
        }

        > strong {
          color: #fff;
        }
      }
    }
  }

  > p {
    min-width: unset;
    margin: 0px;
    padding: 0px;
    margin-bottom: 30px;

    &.only-mobile {
      @media ${ device.mobile } {
        display: block;
      }

      @media ${ device.desktop } {
        display: none;
      }
    }

    &.only-desktop {
      @media ${ device.mobile } {
        display: none;
      }

      @media ${ device.desktop } {
        display: block;
      }
    }

    &.date {
      margin-bottom: 15px;

      @media ${ device.desktop } {
        margin-bottom: 30px;
      }
    }

    &.requestNumber {
      margin-bottom: 0px;

      @media ${ device.desktop } {
        background: #F7F7F7;
        flex: 1;
        border: #DDD 1px solid;
        padding: 15px 0px;
        box-sizing: border-box;
        border-radius: 5px;
        text-align: center;
        color: #FB9D19;

        > span {
          display: inline-block;
          color: #FB9D19;
          text-transform: uppercase;
          font-size: 40px;
          font-weight: bold;
        }

        > strong {
          color: #FB9D19;
          font-size: 40px;
        }
      }

      @media ${ device.mobile } {
        > strong {
          color: #000;
          font-size: 40px;
        }

        > span {
          display: none;
        }
      }
    }

    > strong {
      color: #000;
      font-size: 20px;
    }
  }
`

const PreviewDeliveryBox = styled.div`
  background: #F7F7F7;
  min-width: 280px;
  max-width: 280px;
  flex: 1;
  margin-bottom: 30px;
  border: #DDD 1px solid;
  padding: 15px 0px;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  max-height: 50px;

  @media ${ device.desktop } {
    margin-bottom: 0px;
    min-width: unset;
    max-width: unset;
    display: none;
  }
 
  > p {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
  }
`

const Summary = styled.div`
  min-width: 280px;
  text-align: right;
  flex: 1;
  margin-bottom: 15px;
  min-width: 100%;
  max-height: 110px;
 
  > p {
    max-width: 280px;
    margin: 0px auto;

    &.date {
      color: #757575;
    }

    &:nth-child(2) {
      margin-bottom: 15px;
    }

    > strong {
      color: #000;
    }
  }
`

const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  border-top: #B8B8B8 1px solid;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: #B8B8B8 1px solid;

  @media ${ device.desktop } {
    margin: 0px -20px;
  }

  > p {
    margin-bottom: 15px;
  }

  > button {
    font-size: 20px;
    padding: 8px 15px;
    box-sizing: border-box;
    max-width: 280px;
    min-width: 280px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 7px;
    font-weight: bold;
  }
`

const Button = styled.button`
  background: ${ props => props.disabled ? '#999' : '#000'};
`

const Block = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${ device.desktop } {
    max-width: 200px;
    min-width: 200px;
  }

  &.sidebar {
    display: unset;
    justify-content: unset;
  }

  > img {
    margin: 0px auto;
    flex: none;
    max-height: 100px;
  }
`

const PlaceBox = styled.div`
  flex: 1;
  margin-bottom: 30px;
  padding: 15px 0px;
  box-sizing: border-box;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0px -20px;
    padding: 0px 20px;
    margin-bottom: 30px;
  }

  > h3 {
    margin-bottom: 10px;
  }

  @media ${ device.mobile } {
    display: block;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }

  &.only-desktop {
    @media ${ device.mobile } {
      display: none;
    }
  }
`

const SelectedCardContainer = styled.p`
  margin: 0px;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: 12px;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: 14px;
      font-weight: normal;
    }

    > img {
      margin-right: 5px;
    }

    > a {
      margin-left: 5px;

      > img {
        width: 15px;
        height: auto;
      }
    }
  }
`

const SelectedCard = ({ brand, number }) => {
  return <SelectedCardContainer>
    <span>
      { brand && number ? <>
        <img src={getCardBrandImageById(brand)} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

        { number }
      </>: '' }
    </span>
  </SelectedCardContainer>
}

function CounterReceipt(props) {
  const {
    userName,
    cpf,
    cardBrand,
    cardNumber,
    useCpf,
    status,
    orderDate,
    requestNumber,
    deliveryTime,
    products,
    handleFinishOrder,
    cashChange,
    storeAddress
  } = props

  const DiscountText = () => (
    <Paragraph>
      {
        products.summary.discount ?
        <>Desconto: <span>{`${numberToCurrency(products.summary.discount)}`}</span></> :
        <></>
      }
    </Paragraph>
  )

  return <Wrapper>
    <Block className={ !status ? '' : (status === 2 ? 'status_1' : 'status_0')}>
      { status === null && <ImageContainer src={LoadingGIF} alt={`Carregando…`} /> }

      { status === 1 && <>
        <H2>
          { userName }, <br />
          seu pedido está <span className="status_0">EM PREPARO!</span>
        </H2>

        <StatusBox className="status_0">
          <Paragraph className='date'>{ orderDate }</Paragraph>

          <Paragraph className="only-desktop">Para retirar, fique atento à mudança de status nesta tela ou à chamada pela senha:</Paragraph>

          <Paragraph><strong>PEDIDO CONFIRMADO!</strong></Paragraph>

          <Paragraph className="only-mobile">Para retirar, fique atento à mudança de status nesta tela ou à chamada pela senha:</Paragraph>

          <Paragraph className='requestNumber'><span>Senha:</span> <strong>{ requestNumber || '…' }</strong></Paragraph>
        </StatusBox>
      </> }

      { status === 2 && requestNumber && <>
        <H2>
          { userName }, <br />
          seu pedido está <span class="status_1">PRONTO!</span>
        </H2>

        <StatusBox className="status_1">
          <Paragraph className='date'>{ orderDate }</Paragraph>

          <Paragraph className="only-desktop">Hmmm, chegou a hora. Já pode vir retirar o seu pedido aqui no balcão.</Paragraph>

          <Paragraph><strong>VENHA AQUI RETIRAR!</strong></Paragraph>

          <Paragraph className="only-mobile">Hmmm, chegou a hora. Já pode vir retirar o seu pedido aqui no balcão.</Paragraph>

          <Paragraph className='requestNumber'><span>Senha: </span><strong>{ requestNumber }</strong></Paragraph>
        </StatusBox>
      </> }

      { status &&
        <PreviewDeliveryBox>
          <Paragraph><i></i>{ deliveryTime }</Paragraph>
        </PreviewDeliveryBox>
      }
    </Block>

    { status && <Block className='sidebar'>
      <PlaceBox className='only-desktop'>
        <H3>Local de Retirada</H3>

        <Paragraph>{ storeAddress }</Paragraph>
      </PlaceBox>

      <H3>Resumo do Pedido</H3>

      <List>
        {
          products && products.list && products.list.map((item, index) => {
            const {
              Quantidade: qty,
              Produto: name,
              Valor: value,
              Descricao: description,
              TextoAcompanhamento: extra
            } = item

            const key = `checkout-listitem-${index}-${(new Date()).getTime()}`

            return <ListItem key={key}>
              <span>{ `${qty}x` }</span>

              <span name="info">
                { name }

                <span>  
                  { extra || description }
                </span>
              </span>

              <span name="info">{`${numberToCurrency(value)}`}</span>
            </ListItem>
          })
        }
      </List>

      { products.summary && <Summary>
        <Paragraph>Subtotal <span>{`${numberToCurrency(products.summary.subtotal)}`}</span></Paragraph>
        <DiscountText />
        <Paragraph><strong>TOTAL: {`${numberToCurrency(products.summary.total)}`}</strong></Paragraph>
      </Summary> }

      <PlaceBox className='only-mobile'>
        <H3>Local de Retirada</H3>

        <Paragraph>{ storeAddress }</Paragraph>
      </PlaceBox>

      <Footer>
        { useCpf && <Paragraph>CPF na nota: { formatCpf(cpf) }</Paragraph> }

        { cashChange && <Paragraph>Troco para: { `${numberToCurrency(cashChange)}` }</Paragraph> }

        <SelectedCard brand={cardBrand || 1} number={cardNumber} />

        <Button disabled={status !== 2 ? true : false} onClick={() => {
          handleFinishOrder()
        }}>Retirei meu pedido</Button>
      </Footer>
    </Block> }
  </Wrapper>
}

export default CounterReceipt
