import React from 'react';
import styled from 'styled-components'
import { Icon } from '../common';

const SearchBarWrapper = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    width: auto;
    height: 35px;
    background: white;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    border-radius: 5px;
`

const IconWrapper = styled.div`
    padding: 0 0px 0 10px;
`

const InputWrapper = styled.div`
    flex-grow: 1;
    padding: 0 5px 0 10px;
`

const Input = styled.input`
    border: 0;
    width: 100%;
    height: 33px;

    font-style: italic;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.05em;
    color: ${props => props.theme.colors.textPrimary};

    &::placeholder {
        color: ${props => props.theme.colors.textPrimary};
    }
`

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
        this.inputRef = React.createRef();
    }

    handleChange = (e) => {
        this.setState({value: e.target.value })
        if (this.props.onSearch) {
            this.props.onSearch(e.target.value)
        }
    }

    focusInput = () => {
        this.inputRef.current.focus();
    }

    render() {
      return (
        <SearchBarWrapper onClick={this.focusInput}>
            {this.props.icon && (
                <IconWrapper>
                    <Icon name={this.props.icon} />
                </IconWrapper>
            )}
            <InputWrapper>
                <Input ref={this.inputRef} type="text" placeholder={this.props.placeholder} value={this.state.value} onChange={this.handleChange} />
            </InputWrapper>
        </SearchBarWrapper>
      )
  }
}


export default SearchBar;
