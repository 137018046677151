import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '../common';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const MethodName = styled.span`
    font-size: 13px;
    color: ${props => props.theme.colors.darkGrey};
    padding-left: 5px;
`

class PaymentMethodSelectionCell extends React.Component {
    
    handleCheck = (check) => {
        if (this.props.onCheck) {
            this.props.onCheck(this.props.card, check)
        }
    }

    render() {
        const { imageSrc, name } = this.props;
        return (
            <Checkbox type="radio" name="paymentMethod" onCheck={this.handleCheck} defaultChecked={this.props.checked}>
                <Wrapper>
                    <img src={imageSrc} alt={name} />
                    <MethodName>{name}</MethodName>
                </Wrapper>
            </Checkbox>
        )
    }
}

export default PaymentMethodSelectionCell;