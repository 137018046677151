import {
  mapEcommerceCheckoutData,
  mapEcommerceCheckoutRequestNumber,
  mapEcommerceCheckoutFinishOrder,
  mapEcommerceCheckoutOrderStatus
} from '../../dto/ecommerce'

import handleRequestError from '../../utils/handleRequestError'

import withAuthHeader from '../../utils/withAuthHeader'

export const actionTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
  SET_ORDER_PRODUCTS: 'SET_ORDER_PRODUCTS'
}

export const setOrderNumber = ({ orderNumber }) => ({
  type: actionTypes.SET_ORDER_NUMBER,
  payload: {
    orderNumber
  }
})

export const setOrderProducts = ({ orderProducts }) => ({
  type: actionTypes.SET_ORDER_PRODUCTS,
  payload: {
    orderProducts
  }
})

export const fetchEcommerceCheckoutData = ({
  checkoutId,
  modality
}) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `Pedidos/ObterComprovante?comandaId=${checkoutId}`
    const apiResult = await api.get(url, withAuthHeader())
    const response = mapEcommerceCheckoutData(apiResult.data)

    if (modality === 1) {
      const {
        list,
        requestNumber,
        status,
        paymentStatus,
        orderDate,
        summary,
        notification,
        deliveryAddress
      } = response
  
      if (response.list) {
        Object.assign(result, {
          value: {
            list,
            requestNumber,
            status,
            paymentStatus,
            orderDate,
            summary,
            notification,
            deliveryAddress
          }
        })
  
        return
      }

      return
    }

    if (modality === 2) {
      const {
        list,
        requestNumber,
        status,
        paymentStatus,
        orderDate,
        summary,
        notification,
        deliveryAddress
      } = response
  
      if (response.list) {
        Object.assign(result, {
          value: {
            list,
            requestNumber,
            status,
            paymentStatus,
            orderDate,
            summary,
            notification,
            deliveryAddress
          }
        })
  
        return
      }

      return
    }

    if (modality === 4) {
      const {
        list,
        status,
        paymentStatus,
        orderDate,
        summary,
        notification,
        deliveryAddress
      } = response

      if (response.list) {
        Object.assign(result, {
          value: {
            list,
            status,
            paymentStatus,
            orderDate,
            summary,
            notification,
            deliveryAddress
          }
        })
  
        return
      }

      return
    }

    Object.assign(result, {
      value: null
    })
  } catch (e) {
    const error = handleRequestError(e)    

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}

export const fetchEcommerceCheckoutOrderStatus = ({
  checkoutId,
  modality
}) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = (modality === 1 || modality === 2) ? `Pedidos/ObterStatusPedidoBalcao?comandaId=${checkoutId}` : `Pedidos/ObterStatusDelivery?comandaId=${checkoutId}`
    const apiResult = await api.get(url, withAuthHeader())
    const response = mapEcommerceCheckoutOrderStatus(apiResult.data)

    const {
      completed,
      notification
    } = response

    Object.assign(result, {
      value: {
        completed,
        notification
      }
    })
  } catch (e) {
    const error = handleRequestError(e)    

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}

export const fetchEcommerceCheckoutRequestNumber = (checkoutId) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `Pedidos/ObterNumeroChamada?comandaId=${checkoutId}`
    const apiResult = await api.get(url, withAuthHeader())
    const response = mapEcommerceCheckoutRequestNumber(apiResult.data)

    const {
      requestNumber
    } = response

    if (requestNumber) {
      Object.assign(result, {
        value: {
          requestNumber
        }
      })

      return
    }

    Object.assign(result, {
      value: null
    })
  } catch (e) {
    const error = handleRequestError(e)    

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}

export const postEcommerceCheckoutFinishOrder = (checkoutId) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `Pedidos/RetirarPedido`;
    const data = {
      "ComandaId": checkoutId
    }
    const apiResult = await api.post(url, data, withAuthHeader())

    const response = mapEcommerceCheckoutFinishOrder(apiResult.data)
    
    if (response.success) {
      Object.assign(result, {
        value: {
          success: true
        }
      })

      return
    }

    Object.assign(result, {
      value: {
        error: response.message
      }
    })
  } catch (e) {
    const error = handleRequestError(e)

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}