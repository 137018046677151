import React from 'react';
import styled from 'styled-components';
import { Icon } from '../common';
import { isMobile } from 'react-device-detect';
import { getCardBrandImageByPaymentTypeAndId } from '../../utils/';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 15px 0;
    color: ${props => props.theme.colors.textBold}
`
const BrandImage = styled.img`
    padding-right: 5px;
`

const TextLabel = styled.div`
    font-size: 13px;
    font-weight: 700;
    color: ${props => props.theme.colors.mediumGrey}
`

const EditButton = styled.button`
    background: transparent;
    border: 0;
    padding: 0 0 0 10px;
`

const CartPaymentMethod = ({brandId, type, text, onEdit}) => (
    <Wrapper>
        <BrandImage src={getCardBrandImageByPaymentTypeAndId(type, brandId)} />
        <TextLabel>{text}</TextLabel>
        {isMobile && (
            <EditButton onClick={onEdit}>
                <Icon name="edit" />
            </EditButton>
        )}
    </Wrapper>
)

export default CartPaymentMethod;