import React from 'react';
import styled from 'styled-components';
import { device } from '../../constants/responsive';
import { Loading, Button } from '../common';

const Wrapper = styled.div`
    width: 100%;
    height: 55px;
`

const ButtonLink = styled(Button)`
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 55px !important;
    border-radius: 0px !important;
    @media ${device.desktop} {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
`

const CartPurchaseButton = ({onClick, loading, text, disabled}) => {
    return (
        <Wrapper>
            <ButtonLink className={"primary"} onClick={onClick} disabled={disabled}>
                {loading 
                    ? (<Loading width={128} height={32} />)
                    : (text)
                }
            </ButtonLink>
        </Wrapper>
    )
}

export default CartPurchaseButton