import React from 'react'
import styled from 'styled-components'

import Country from '../common/Country'

import {
    countries
} from '../../constants/countries'

const Input = styled.input`
    font-size: 18px;
    color: ${props => props.theme.colors.textPrimary};
    border: 0px none;
    height: 40px;
    flex: 1;
    padding: 0px;
    max-width: 150px;
`

const Paragraph = styled.p`
    font-size: 18px;
    color: #F33;
    flex: 1;
`

const Wrapper = styled.div`
    border-radius: 5px;
    border: ${props => props.theme.colors.textPrimary} 2px solid;
    min-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    padding: 0px 15px;
`

const Select = styled.select`
    border: 0px none;
    background: none;
    flex: 1;
    max-width: 85px;
    min-width: 85px;
    padding: 0px;
    margin-right: 12px;
`

const setMask = (value) => {
    const newValue = value.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '')

    const masked = []

    if (newValue.length >= 1 && newValue.length < 3) {
        const maskedWithBracket = masked.concat(['(', newValue.substr(0, 1)])
        const maskedWithBracketRemainingChars = maskedWithBracket.concat([newValue.substr(1, 20)])

        return maskedWithBracketRemainingChars.join('')
    }

    if (newValue.length >= 3 && newValue.length < 7) {
        const maskedWithBracket = masked.concat(['(', newValue.substr(0, 2), ')', ' '])
        const maskedWithBracketRemainingChars = maskedWithBracket.concat([newValue.substr(2, 20)])

        return maskedWithBracketRemainingChars.join('')
    }

    if (newValue.length >= 7) {
        const maskedWithAll = masked.concat(['(', newValue.substr(0, 2), ')', ' ', newValue.substr(2, 5), newValue.length >= 8 ? '-' : ''])
        const maskedWithAllRemainingChars = maskedWithAll.concat([newValue.substr(7, 4)])

        return maskedWithAllRemainingChars.join('')
    }

    return newValue
}

class LoginPhoneForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: '',
            ddi: 55,
            phonePlaceholder: '(99) 99999-9999',
            phoneMaxLength: 15,
            countryUrl: 'brasil',
            countryAbbr: 'BR',
            error: null
        }
        this.inputRef = React.createRef()
    }

    handleChange = (event) => {
        const {
            value
        } = event.target

        if (this.state.countryUrl !== 'brasil') {
            this.setState({
                phone: value,
                phoneMaxLength: 99,
                phonePlaceholder: ''
            })

            this.props.setPhone(value)

            this.props.inputProps.onChange(event)

            return false
        }

        this.setState({
            phone: setMask(value),
            phoneMaxLength: 15,
            phonePlaceholder: '(99) 99999-9999'
        })

        if (value.length === 15) {
            const number = value.split(' ')
            const firstPhoneDigit = Number(number[1].substr(0, 1))

            if (firstPhoneDigit !== 9) {
                this.setState({
                    error: {
                        message: 'Número inválido.'
                    }
                })

                this.props.setPhone(null)

                return
            }

            this.props.setPhone(setMask(value))
            this.props.inputProps.onChange(event)

            this.setState({
                error: null
            })

            return
        }

        this.props.setPhone(null)
        this.props.inputProps.onChange(event)
    }

    handleDDIChange = async (event) => {
        const value = event.target.value.split('|')

        const countryUrl = value[0]
        const countryDDI = value[1]

        const newState = {
            countryUrl,
            ddi: countryDDI,
            phoneMaxLength: 99,
            phonePlaceholder: '',
            phone: '',
            error: null
        }

        if (value[0] === 'brasil') {
            Object.assign(newState, {
                phoneMaxLength: 15,
                phonePlaceholder: '(99) 99999-9999'
            })
        }

        this.props.setPhone(null)
        
        await this.setState(newState)
        this.props.setDDI(this.state.ddi)
    }

    handleKey = (event) => {
        const {
            keyCode
        } = event

        const allowedKeys = [8, 37, 38, 39, 40, 46, 18, 35, 36]

        if (allowedKeys.indexOf(keyCode) >= 0) {
            return false
        }

        const number = Number(event.key)

        if (typeof number !== 'number' || isNaN(number)) {
            event.preventDefault()
        }
    }


    render() {
        return (
            <>
                <Wrapper>
                    <Country url={this.state.countryUrl} />
                    <Select value={`${this.state.countryUrl}|${this.state.ddi}`} name="url" onChange={this.handleDDIChange}>
                        {
                            countries.map((mappedItem, index) => {
                                const key = `phone-key-${index}-${(new Date()).getTime()}`
                                const {
                                    abbr
                                } = mappedItem
                                const codes = abbr.split('/')

                                return <option key={key} value={`${mappedItem.url}|${mappedItem.ddi}`}>
                                    { `${codes[0]} +${mappedItem.ddi}` }
                                </option>
                            })
                        }
                    </Select>
                    <Input ref={this.inputRef} maxLength={this.state.phoneMaxLength} name="phone" id="phone" placeholder={this.state.phonePlaceholder} value={this.state.phone} onChange={this.handleChange} onKeyDown={this.handleKey} />
                </Wrapper>

                { this.state.error && <Paragraph>
                    { this.state.error.message }
                </Paragraph> }
            </>
        )
    }
}

export default LoginPhoneForm
