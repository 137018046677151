import React from "react";
import { withRouter } from "react-router-dom";
import { CartBox } from "./";
import { AnimatedWrapper } from "../common/";
import { Helmet } from "react-helmet";

class CartModal extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.props.isOpen && this.props.location.pathname !== "/cart") {
        this.props.onClose();
      }
    }
  }

  didAppear = () => {
    this.props.history.push("/cart");
  };

  didDisappear = () => {
    this.props.history.push("/");
  };

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <>
        {this.props.isOpen && (
          <Helmet>
            <html className="no-scroll" />
            <body className="no-scroll" />
          </Helmet>
        )}
        <AnimatedWrapper
          visible={this.props.isOpen}
          onAppear={this.didAppear}
          onDisappear={this.didDisappear}
        >
          <CartBox
            isCheckout
            modal
            isOpen={this.props.isOpen}
            onClose={this.handleClose}
          />
        </AnimatedWrapper>
      </>
    );
  }
}

export default withRouter(CartModal);
