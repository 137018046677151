import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import { TextInput, Button, LoadingOverlay } from '../common'
import { Form } from '../common/form/'
import  { notEmpty, isCpf } from '../common/form/validationFunctions'
import avatarImage from '../../assets/avatar.svg'
import { device } from '../../constants/responsive'
import onlyNumbers from '../../utils/onlyNumber'

import {
    LoginPhoneForm,
    SMSCodeForm
} from './'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  @media ${device.desktop} {
    width: 440px;
  }
`

const Title = styled.h2`
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.textPrimary};
`

const Paragraph = styled.p`
    font-size: 16px;
    padding: 0px 45px;
`

const Action = styled.a`
    font-weight: bold;
    font-size: 20px;
    color: ${props => props.disabled ? '#DDD': '#CA9128'};
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: ${props => props.disabled ? '#DDD': '#CA9128'};
        cursor: ${props => props.disabled ? 'unset': 'pointer'};
    }
`

const SingleColumn = styled.div`
    width: 100%;
    padding: 10px 0 10px 0;
    text-align: center;
`

const DoubleColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 0 10px 0;
`

const BlankSpace = styled.div`
    width: 45px;
`

class SignUpForm extends React.Component {
    constructor(props) {
        super(props)
        this.formInputs = this.setupForms()
        this.phoneFormInputs = this.setupPhoneForm()
        this.codeFormInputs = this.setupCodeForm()
        this.formRef = null
    }

    handleSubmit = async (values) => {
        /* First step: Basic information  */    
        const signUp = {
            response: null
        }

        const {
            name,
            lastname,
            email,
            birthdate,
            cpf,
            pass,
            confirm
        } = values

        if (this.props.step === 0) {
            Object.assign(signUp, {
                response: await this.props.signUp({ name, lastname, email, birthdate, cpf, pass, confirm })
            })
        }

        const {
            response
        } = signUp

        if (this.props.step === 0 && !!response) {
            const {
                response
            } = signUp

            const {
                user
            } = response

            const {
                token
            } = user

            const {
                accessToken
            } = token

            this.props.handleGTM({
                event: `CADASTRO`
            })

            await this.props.save({ name, lastname, email, birthdate, cpf, pass, confirm, authToken: accessToken })

            await this.props.setStep(1)

            return
        }

        if (this.props.step === 0 && !response) {
            return
        }

        /* Second step: Phone information  */
        if (this.props.step === 1) {
            const {
                phone
            } = values

            const {
                authToken
            } = this.props.data

            await this.props.save({ phone })
            const result = await this.props.sendCode({ phone: this.props.rawPhone, confirmationType: 'sms', authToken })

            if (result && result.success) {
                this.props.setTime(30)
                this.props.setStep(2)
            }

            return
        }

        /* Third step: Code information  */
        if (this.props.step === 2) {
            const {
                code
            } = values

            const {
                authToken
            } = this.props.data

            await this.props.save({ code })
            const confirmation = await this.props.sendConfirmationCode({ code, authToken })

            const {
                success
            } = confirmation

            this.props.setTime(30)

            if (this.props.confirmationErrors >= 3) {
                this.props.setConfirmationErrors(0)
                this.props.setConfirmationType('sms')
                this.props.setStep(0)
            }

            if (success) {
                return {
                    success
                }
            }
        }
    }

    setupForms = () => [
        {
            id: 'name',
            validations: [notEmpty],
            defaultValue: this.props.name,
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="name" placeholder="Nome *" keepPlaceholderAbove />
            )
        },
        {
            id: 'lastname',
            validations: [notEmpty],
            defaultValue: this.props.lastName,
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="lastname" placeholder="Sobrenome *" keepPlaceholderAbove />
            )
        },
        {
            id: 'email',
            validations: [notEmpty],
            defaultValue: this.props.email,
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="email" placeholder="Email *" keepPlaceholderAbove />
            )
        },
        {
            id: 'birthdate',
            validations: [notEmpty],
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="birthdate" placeholder={isMobile ? "Data nasc. *" : "Data de nascimento *"} mask={"99/99/9999"} keepPlaceholderAbove />
            )
        },
        {
            id: 'cpf',
            validations: [notEmpty, isCpf],
            beforeSubmit: onlyNumbers,
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="cpf" placeholder="Cpf *" mask={"999.999.999-99"} keepPlaceholderAbove />
            )
        },
        {
            id: 'pass',
            validations: [notEmpty],
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="pass" placeholder="Senha *" keepPlaceholderAbove 
                    inputProps={{
                        type:"password",
                    }} 
                />
            )
        },
        {
            id: 'confirm',
            validations: [notEmpty],
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="confirm" type="password" placeholder="Confirme a senha *" keepPlaceholderAbove
                    inputProps={{
                        type:"password",
                    }} 
                />
            )
        }
    ]

    setupPhoneForm = () => [
        {
            id: 'phone',
            validations: [notEmpty],
            Component: (formInputProps) => (<LoginPhoneForm inputProps={{...formInputProps}} setDDI={this.props.setDDI} setPhone={this.props.setPhone} setStep={this.props.setStep} />)
        }
    ]

    setupCodeForm = () => [
        {
            id: 'code',
            validations: [notEmpty],
            Component: (formInputProps) => (<SMSCodeForm inputProps={{...formInputProps}} confirmationType={this.props.confirmationType} setCode={this.props.setCode} setStep={this.props.setStep} form={this.formRef} />)
        }
    ]
    
    renderMainForm = (inputs) => {
        const profileImage = this.props.image || avatarImage

        const {
            name: Name,
            lastname: Lastname,
            email: Email,
            birthdate: Birthdate,
            cpf: Cpf,
            pass: Pass,
            confirm: Confirm
        } = inputs

        return (
            <div>
                <SingleColumn>
                    <img src={profileImage} alt="Foto de perfil" />
                </SingleColumn>
                <DoubleColumn>
                    <Name />
                    <BlankSpace />
                    <Lastname />
                </DoubleColumn>
                <SingleColumn>
                    <Email />
                </SingleColumn>
                <DoubleColumn>
                    <Birthdate />
                    <BlankSpace />
                    <Cpf />
                </DoubleColumn>
                <SingleColumn>
                    <Pass />
                </SingleColumn>
                <SingleColumn>
                    <Confirm />
                </SingleColumn>
                <SingleColumn>
                    <Button type="submit" className="action">CADASTRAR</Button>
                </SingleColumn>
            </div>
        )
    }

    renderPhoneForm = (inputs) => {
        const {
            phone: Phone
        } = inputs

        return (
            <div>
                <Title>Telefone</Title>

                <Paragraph><em>Digite o seu telefone celular para finalizar o seu cadastro.</em></Paragraph>

                <Phone />

                <Button disabled={!this.props.phone} type="submit" className="action">Ok</Button>
            </div>
        )
    }

    renderCodeForm = (inputs) => {
        const {
            code: Code
        } = inputs

        const {
            fullPhone
        } = this.props

        const {
            time
        } = this.props

        return (
            <div>
                <Title>Código de SMS</Title>

                <Paragraph><em>Digite o código de confirmação recebido por SMS no celular abaixo.</em></Paragraph>

                <Paragraph><strong>{fullPhone}</strong></Paragraph>
                
                <Code />

                {
                    this.props.confirmationType === 'phone' && this.props.confirmationErrors >= 1 && this.props.confirmationErrors <= 1 ? <Action disabled={time} href='#' onClick={async (event) => {
                        event.preventDefault()

                        if (time) {
                            return
                        }

                        this.props.setTime(30)
                        await this.props.sendCode({ phone: this.props.rawPhone, confirmationType: 'phone' })

                        this.props.setConfirmationErrors(this.props.confirmationErrors + 1)
                    }} className="action">Receber ligação{ time ? `: ${time}` : '' }</Action> : <></>
                }

                {
                    this.props.confirmationType === 'sms' && this.props.confirmationErrors <= 1 ? <Action disabled={time} href='#' onClick={async (event) => {
                        event.preventDefault()

                        if (time) {
                            return
                        }

                        this.props.setTime(30)
                        await this.props.sendCode({ phone: this.props.rawPhone, confirmationType: 'sms', resend: true })
            
                        this.props.setConfirmationErrors(this.props.confirmationErrors + 1)
                        this.props.setConfirmationType('phone')
                    }} className="action">Reenviar código{ time ? `: ${time}` : '' }</Action> : <></>
                }
            </div>
        )
    }

    render() {
        const {
            formInputs,
            phoneFormInputs,
            codeFormInputs
        } = this

        return (
            <Wrapper>
                <LoadingOverlay visible={this.props.loading} margin={"5px"} /> 
                
                {
                    this.props.step === 0 ? <Form
                        ref={(form) => (this.formRef = form) }
                        formInputs={formInputs}
                        onSubmit={this.handleSubmit}
                        >
                            { (inputs) => this.renderMainForm(inputs) }
                    </Form> : <></>
                }

                {
                    this.props.step === 1 ? <Form
                        ref={(form) => (this.formRef = form) }
                        phoneFormInputs={phoneFormInputs}
                        onSubmit={this.handleSubmit}
                        >
                            { (inputs) => this.renderPhoneForm(inputs) }
                    </Form> : <></>
                }

                {
                    this.props.step === 2 ? <Form
                        ref={(form) => (this.formRef = form) }
                        codeFormInputs={codeFormInputs}
                        onSubmit={this.handleSubmit}
                        >
                            { (inputs) => this.renderCodeForm(inputs) }
                    </Form> : <></>
                }
            </Wrapper>
        )
    }
}

export default SignUpForm;