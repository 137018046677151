import React from 'react'
import { connect } from 'react-redux'

import {
  Modal,
  Notification
} from '../common'
import { saveCVV } from '../../redux/actions/payment'
import { toggleCVVModal } from '../../redux/actions/ui'

import { CVVForm } from './'

class CVVModal extends React.Component {
  constructor(props) {
		super(props)

    this.state = {
			filled: false,
			valid: false,
      error: null
    }
  }

  dismissError = () => {
    this.setState({ error: null })
  }

  handleClose = () => {
		this.props.toggleCVVModal({ show: false })
	}

  saveCVV = async (cvv) => {
    const result = await this.props.saveCVV(cvv)
		
		if (result.success) {
      this.props.callback()

			this.handleClose()

			return
		}
		
		this.setState({ error: result.error })
  }

  render() {
    return (
      <Modal name="CVVModal" isOpen={ this.props.isOpen } onClose={this.handleClose}>
        <Notification type="error" text={this.state.error} onDismiss={this.dismissError} timeout={5} />

        <CVVForm saveCVV={this.saveCVV} />
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return { 
    isOpen: state.ui && state.ui.modals && state.ui.modals.CVVModal && state.ui.modals.CVVModal.show,
    callback: state.ui.modals.CVVModal.callback
  }
}

export default connect(mapStateToProps, {
	toggleCVVModal,
	saveCVV
})(CVVModal)
