import { actionTypes } from '../actions/fridge'

const initialState = {
  loading: null,
  success: null,
  statusId: null,
  checkoutId: null
}

const fridgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAYMENT_TYPE: {
      return {
        ...state,
        selectedCard: {
          id: action.payload.card.id,
          brandId: action.payload.card.brandId,
          paymentType: action.payload.card.paymentType,
          data: action.payload.card.data
        }
      }
    }
    case actionTypes.SET_FRIDGE_OPEN: 
      return {
        ...state,
        opened: true
      }
    case actionTypes.SET_LOADING: 
      return {
        ...state,
        loading: action.payload
      }
    case actionTypes.SET_AVAILABLE_CARDS: 
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.SET_FRIDGE_CHECKOUT_STATUS: 
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default fridgeReducer
