const withAuthHeader = () => {
    const sessionUser = JSON.parse(localStorage.getItem('user'))
    if (sessionUser) {
        const auth = `${sessionUser.token.type} ${sessionUser.token.accessToken}`
        return {
            headers: {
                Authorization: auth
            }
        };
    }
    return null;
}

export default withAuthHeader;