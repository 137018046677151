import React from "react";
import styled from "styled-components";
import { numberToCurrency } from "../../utils";
import { DELIVERY_MODALITIES } from "../../constants/delivery";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px 10px 24px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: ${(props) => props.theme.colors.mediumGrey};
`;

const SubtotalLabel = styled.div`
  font-weight: 400;
  flex-grow: 1;
`;

const PriceLabel = styled.div`
  font-weight: 500;
`;

const TotalLabel = styled.div`
  font-weight: 700;
  flex-grow: 1;
`;

const TotalPriceLabel = styled.div`
  font-weight: 700;
`;

function getModalityCostText({ deliveryModality, deliveryPrice, pickupPrice }) {
  const modalityCost = { value: null }

  if (deliveryModality === 2) {
    Object.assign(modalityCost, { value: pickupPrice })
  }

  if (deliveryModality === 4) {
    Object.assign(modalityCost, { value: deliveryPrice })
  }

  if (!deliveryModality && modalityCost.value === null) {
    return "Escolha Local Entrega"
  }
  
  if ((deliveryModality === 2 || deliveryModality === 4) && modalityCost.value === 0) {
    return "Grátis"
  }

  if ((deliveryModality === 2 || deliveryModality === 4) && modalityCost.value) {
    

    return numberToCurrency(modalityCost.value)
  }

  return ''
}

const CalculateTotal = ({
  subtotal,
  discount,
  deliveryModality,
  deliveryPrice,
  pickupPrice,
}) => {
  let total = subtotal;
  if (discount > 0) {
    total = Math.max(0, total - discount);
  }
  if (deliveryModality === DELIVERY_MODALITIES.DELIVERY) {
    total += deliveryPrice;
  }
  if (deliveryModality === DELIVERY_MODALITIES.PICKUP) {
    total += pickupPrice;
  }
  return total;
};

const CartTotals = ({
  subtotal,
  discount,
  deliveryModality,
  deliveryPrice,
  pickupPrice
}) => {
  const modalityCostText = getModalityCostText({ deliveryModality, deliveryPrice, pickupPrice })
  const hasDiscount = discount !== 0 && discount !== null
  const isPickup = deliveryModality === DELIVERY_MODALITIES.PICKUP

  return (
    <Wrapper>
      <Line>
        <SubtotalLabel>Subtotal</SubtotalLabel>
        <PriceLabel>{numberToCurrency(subtotal)}</PriceLabel>
      </Line>
      {hasDiscount && (
        <Line>
          <SubtotalLabel>Desconto</SubtotalLabel>
          <PriceLabel>-{numberToCurrency(discount)}</PriceLabel>
        </Line>
      )}
      {
        modalityCostText && <Line>
          <SubtotalLabel>{isPickup ? "Taxa" : "Entrega"}</SubtotalLabel>
          <PriceLabel>{modalityCostText}</PriceLabel>
        </Line>
      }
      <Line>
        <TotalLabel>TOTAL</TotalLabel>
        <TotalPriceLabel>
          {numberToCurrency(
            CalculateTotal({
              subtotal,
              discount,
              deliveryModality,
              deliveryPrice,
              pickupPrice,
            })
          )}
        </TotalPriceLabel>
      </Line>
    </Wrapper>
  );
};

export default CartTotals;
