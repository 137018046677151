import React from 'react';
import styled from 'styled-components';

import { device } from '../../constants/responsive';
import { StoreName } from '../common/';

import {
    CARDAPIO_CONSULTA
} from '../../config'

const Wrapper = styled.h1`
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    text-align: center;
    color: ${props => props.theme.colors.primary}
    @media ${device.desktop} {
        font-size: 25px;
    }
`

const AlternativeWrapper = styled.h1`
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    color: ${props => props.theme.colors.primary}
    @media ${device.desktop} {
        font-size: 25px;
    }
`

const HeaderStoreName = (props) => {
    const {
        name
    } = props

    if (CARDAPIO_CONSULTA === "false") {
        return <Wrapper>
            <StoreName name={name} />
        </Wrapper>
    }

    return <AlternativeWrapper>
        <StoreName name={name} />
    </AlternativeWrapper>
}

export default HeaderStoreName;