import React, { forwardRef } from 'react';
import styled from 'styled-components'
import { css } from 'styled-components';
import InputMask from 'react-input-mask';

import { Icon } from './';

const InputWrapper = styled.div`
    text-align: center;
    width: auto;
    height: 44px;
    background: none;
    border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
    position: relative;
    display: flex;
    align-items: center;

    ${props => props.error && `
        border-bottom: 1px solid ${props.theme.colors.error};
    `}
`

const ErrorLabel = styled.div`
    position: absolute;
    bottom: -20px;
    right: 5px;
    color: ${props => props.theme.colors.textError};
    font-size: 10px;
`

const Placeholder = styled.div`
    color: ${props => props.theme.colors.lightGrey};
    font-size: ${props => props.fontSize || '14px'};
    font-style: italic;
    transition: all 0.25s;
    position: absolute;
    text-align: left;
    width: 100%;
    top: 25%;
    left: ${props => props.hasIcon ? '26px' : '10px'};

    ${props => props.center && css`
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    `}

    &.above {
        transform: translateY(-60%) scaleX(0.7) scaleY(0.7) translateX(-24%) ;
        transition: all 0.25s;
    }

    &.gone {
        display: none;
    }

    ${props => props.error && `
        color: ${props.theme.colors.error};
    `}
`

const Input = styled.input`
    border: 0;
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    background: none;

    ${props => props.center && css`
        text-align: center;
    `}    

    font-size: ${props => props.fontSize || '14px'};
    letter-spacing: -0.05em;
    color: ${props => props.theme.colors.textPrimary};

    ${props => props.error && `
        color: ${props.theme.colors.error};
    `}
`

const isEmpty = (val) => val === `` || val === undefined || val === null

class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            focused: false
        }
    }

    handleChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }

    focusInput = () => {
        this.inputRef.current.focus();
    }

    setInputFocused = () => {
        this.setState({
            focused: true
        })
    }

    setInputBlurred = () => {
        this.setState({
            focused: false
        })
    }

    DefaultInput = forwardRef( (props, ref) => (
        <Input 
            center={this.props.center} 
            id={this.props.id}
            name={this.props.name}
            ref={ref} 
            error={this.props.error}
            type={this.props.type || "text" }
            value={this.props.value}
            onChange={this.props.onChange} 
            onFocus={this.setInputFocused} 
            onBlur={this.setInputBlurred}
            fontSize={this.props.fontSize} 
            {...this.props.inputProps}
            {...props}
    />))

    render() {
        const value = this.props.value;
        const placeholderGoneClass = !isEmpty(value) && !this.props.keepPlaceholderAbove ? 'gone' : '';
        const placeholderAboveClass = this.props.keepPlaceholderAbove && (this.state.focused || !isEmpty(value)) ? 'above' : '';
        const error = this.props.error;
        return (
        <InputWrapper onClick={this.focusInput} error={error}>
            {this.props.icon && (
                <Icon name={this.props.icon} />
            )}
            {this.props.placeholder && (
                <Placeholder 
                    fontSize={this.props.fontSize} 
                    hasIcon={this.props.icon} 
                    center={this.props.center} 
                    error={error}
                    className={`${placeholderGoneClass} ${placeholderAboveClass}`}
                >
                    {this.props.placeholder}
                </Placeholder>
            )}
            {this.props.mask && (
                <InputMask 
                    mask={this.props.mask}
                    formatChars={this.props.formatChars}
                    value={value}
                    onChange={this.handleChange}
                    onFocus={this.setInputFocused} 
                    onBlur={this.setInputBlurred} 
                >
                    {(parentProps) => <this.DefaultInput ref={this.inputRef} {...parentProps} />}
                </InputMask>
            )}
            {!this.props.mask && (
                <this.DefaultInput ref={this.inputRef} />
            )}
            <ErrorLabel>
                {error}
            </ErrorLabel>
        </InputWrapper>
      )
  }
}


export default TextInput;
