import React, {
  useEffect
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import LoadingGIF from '../../../assets/loading.gif'

import {
  fetchPaymentMethods
} from '../../../redux/actions/fridge'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 960px;
  margin-top: 0px;
  min-width: 320px;
`

const ImageContainer = styled.img`
  width: 100px;
  height: auto;
`

function CheckIn(props) {
  const {
    setContent
  } = props

  useEffect(() => {
    async function getCards() {
      if (props.loading !== true && typeof props.storeId === 'number') {
        await props.fetchPaymentMethods()
      }

      if (props.selectedCard && props.loading === false) {
        setContent('with-card')
  
        return
      }

      if (!props.selectedCard && props.onlineCards && props.loading === false) {
        setContent('cards')

        return
      }

      if (!props.selectedCard && !props.onlineCards && props.loading === false) {
        setContent('no-card')

        return
      }
    }

    getCards()
  }, [props, setContent])

  return <Wrapper>
    <ImageContainer src={LoadingGIF} />
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeId: state.store && state.store.id,
    loading: state.fridge && state.fridge.loading,
    selectedCard: state.fridge && state.fridge.selectedCard,
    onlineCards: state.fridge && state.fridge.onlineCards,
    offlineCards: state.fridge && state.fridge.offlineCards
  }
}

export default connect(
  mapStateToProps, {
    fetchPaymentMethods
  }
)(withRouter(CheckIn))
