import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import NoCardIcon from '../../../assets/no-card-icon.png'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 960px;
  margin-top: 0px;
  min-width: 320px;
  padding-top: 40px;
`;

const ImageContainer = styled.img`
  margin-bottom: 20px;
  width: 200px;
  height: auto;
`

const H2 = styled.h2`
  font-size: 20px;
  color: #41BAC8;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
`

const Paragraph = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #686868;
  text-align: center;
  max-width: 280px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
`

const SubParagraph = styled.p`
  font-size: 14px;
  font-weight: 200;
  color: #B1B3B6;
  text-align: center;
  max-width: 280px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
`

const ButtonContainer = styled.button`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #41BAC8;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  flex: none;
  margin: 0px;
  padding: 10px 0px;
  width: 100%;
  max-width: 280px;
`

function NoCard(props) {
  const {
    userName,
    setContent
  } = props

  return <Wrapper>
    <H2>
      { userName }, <br />
      Seja bem vindx!
    </H2>

    <SubParagraph>
      Você está a um passo de usufruir o futuro das compras rápidas. <br />
      Só falta adicionar uma forma de pagamento.
    </SubParagraph>

    <ImageContainer src={NoCardIcon} />

    <Paragraph>Você ainda não possui nenhum cartão cadastrado!</Paragraph>

    <ButtonContainer onClick={() => {
      setContent('new-card')
    }}>Cadastrar Cartão</ButtonContainer>
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    userName: state.user && state.user.name
  }
}

export default connect(
  mapStateToProps, {}
)(withRouter(NoCard))
