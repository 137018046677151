import React from 'react';
import styled from 'styled-components';

const Checkmark = styled.span`
    position: absolute;
    top: 25%;
    left: 0;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.mediumGrey}

    &:after { 
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${props => props.theme.colors.mediumGrey};
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Content = styled.div`
    margin-left: 24px;
`

const Container = styled.label`
    display: block;
    position: relative;
    margin-right: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input { 
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ ${Checkmark}:after {
            display: block;
        }
    }
`

class RadioButton extends React.Component {

    handleChange = (e) => {
        const value = e.target.value;
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
        <Container>
            <Wrapper>
                <input type="radio" name={this.props.name} value={this.props.value} defaultChecked={this.props.defaultChecked} onChange={this.handleChange} />
                <Checkmark />
                <Content>
                    {this.props.children}
                </Content>
            </Wrapper>
        </Container>
        )
    }
}

export default RadioButton;