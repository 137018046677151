import {
  mapFridgeStatusResponse,
  mapFridgeCheckoutStatusResponse
} from '../../dto/fridge'

import { mapCardsApiResponse } from '../../dto/payment'

import handleRequestError from '../../utils/handleRequestError'

import withAuthHeader from '../../utils/withAuthHeader'

import { actionTypes as requestsActionTypes } from './requests'

export const actionTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
  SET_PAYMENT_CVV: 'SET_PAYMENT_CVV',
  SET_FRIDGE_OPEN: 'SET_FRIDGE_OPEN',
  SET_AVAILABLE_CARDS: 'SET_AVAILABLE_CARDS',
  SET_FRIDGE_CHECKOUT_STATUS: 'SET_FRIDGE_CHECKOUT_STATUS'
}

export const setPaymentType = ({type, card, isOffline}) => ({
  type: actionTypes.SET_PAYMENT_TYPE,
  payload: {
    type,
    card,
    isOffline
  }
})

export const fetchPaymentMethods = () => async (dispatch, getState, api) => {
  let result = {}
  try {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    })

    const url = `Cartoes/Listar/${getState().store.id}`
    const apiResult = await api.get(url, withAuthHeader())
    const response = mapCardsApiResponse(apiResult.data)

    if (response.success) {
      result = {
        success: true,
        selectedCard: {
          id: response.selectedCard.CartaoId,
          data: response.selectedCard.Dados,
          brand: response.selectedCard.Bandeira,
          brandId: response.selectedCard.BandeiraId,
          paymentType: response.selectedCard.TipoPagamento,
          cvvRequired: response.selectedCard.TipoPagamento
        },
        onlineCards: response.availableOnlineCards,
        offlineCards: response.availableOfflineCards
      }
      dispatch({
        type: actionTypes.SET_AVAILABLE_CARDS,
        payload: {
          selectedCard: response.selectedCard,
          onlineCards: response.availableOnlineCards,
          offlineCards: response.availableOfflineCards
        }
      })
    } else {
      result = {
        error: response.message
      }
    }
  } catch (e) {
    const error = handleRequestError(e)

    result = {
      error: error.message,
      success: false
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_FETCH_PAYMENT_METHODS,
    payload: {
      success: true
    }
  })

  dispatch({
    type: actionTypes.SET_LOADING,
    payload: false
  })

  return result
}

export const fetchFridgeCheckoutStatus = (fridgeId) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `geladeiras/obterstatus/${fridgeId}` //${getState().store.id}`
    const apiResult = await api.get(url, withAuthHeader())
    const response = mapFridgeCheckoutStatusResponse(apiResult.data)

    if (response.success) {
      Object.assign(result, {
        value: {
          success: true,
          statusId: response.statusId,
          checkoutId: response.checkoutId
        }
      })

      dispatch({
        type: actionTypes.SET_FRIDGE_CHECKOUT_STATUS,
        payload: {
          success: true,
          statusId: response.statusId,
          checkoutId: response.checkoutId
        }
      })

      return
    }
    
    Object.assign(result, {
      value: {
        error: response.message
      }
    })
  } catch (e) {
    const error = handleRequestError(e)    

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}

export const fetchFridgeCheckoutData = (checkoutId) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `Pedidos/ObterComprovante?comandaId=${checkoutId}` //${getState().store.id}`
    const apiResult = await api.get(url, withAuthHeader())

    if (apiResult.data.PedidoItems) {
      Object.assign(result, {
        value: {
          list: apiResult.data.PedidoItems,
          summary: {
            total: apiResult.data.ValorPago,
            deliveryFee: apiResult.data.ModalidadeRetiradaValor,
            subtotal: apiResult.data.ValorPago - apiResult.data.ModalidadeRetiradaValor
          }
        }
      })

      return
    }

    Object.assign(result, {
      value: null
    })
  } catch (e) {
    const error = handleRequestError(e)    

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}

export const postOpenFridge = (fridgeId) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `geladeiras/abrirporta`;
    const dto = {
      "CartaoId": getState().fridge.selectedCard.id,
      "EstabelecimentoId": fridgeId,
      "TipoPagamento": getState().fridge.selectedCard.paymentType,
      "Cpf": true
    }
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapFridgeStatusResponse(apiResult.data)
    
    if (response.success) {
      Object.assign(result, {
        value: {
          success: true,
          fridgeId: response.requestId
        }
      })

      dispatch({
        type: actionTypes.SET_FRIDGE_OPEN
      })

      return
    }

    Object.assign(result, {
      value: {
        error: response.message
      }
    })
  } catch (e) {
    const error = handleRequestError(e)

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}

export const postFridgePayment = (requestId) => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `geladeiras/pagar`;
    const dto = {
      "SolicitacaoId": requestId,
      "CVV": getState().requests.saveCVV.cvv
    }
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapFridgeCheckoutStatusResponse(apiResult.data)
    
    if (response.success) {
      Object.assign(result, {
        value: {
          success: true,
          statusId: response.statusId,
          checkoutId: response.checkoutId
        }
      })

      return
    }

    Object.assign(result, {
      value: {
        error: response.message
      }
    })
  } catch (e) {
    const error = handleRequestError(e)

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}
