import Address from '../models/address';

interface ViaCepAddressResponse extends Address {
    error?: boolean
}

interface SaveAddressResponse {
    id?: number,
    success: boolean,
    message :string
}

interface AddressValidationResponse {
    valid: boolean,
    success: boolean,
    message: string,
}

export const mapViaCepResponse = (viaCepAddress: any) : ViaCepAddressResponse => {
    return {
        street: viaCepAddress["logradouro"],
        number: undefined,
        complement: undefined,
        neighborhood: viaCepAddress["bairro"],
        cep: viaCepAddress["cep"],
        city: viaCepAddress["localidade"],
        state: viaCepAddress["uf"],
        error: viaCepAddress["erro"]
    }
}

export const mapSaveAddressResponse = (response: any) : SaveAddressResponse => {
    return {
        id: response["EnderecoDeliveryId"],
        success: response["Sucesso"],
        message: response["Mensagem"]
    }
}

export const mapAddressModelToSaveAddressRequest = (address: Address) : any => {
    return {
        "Enderecos": [
            {
                "Selecionado": true,
                "Endereco": address.street,
                "Numero": address.number,
                "Cep":address.cep,
                "Bairro":address.neighborhood,
                "Cidade":address.city,
                "Estado":address.state,
                "Pais": "Brasil",
                "Complemento":address.complement,
                "Referencia":address.reference,
                "TipoEndereco":"0",
                "Latitude":address.latitude,
                "Longitude":address.longitude
            }
        ]
    }
}

export const mapAddressCoordinatesToValidationRequest = ({latitude, longitude}: {latitude: number, longitude: number}) => {
    return {
        "Latitude": latitude,
        "Longitude": longitude
    }
}

export const mapAddressValidationResponse = (response: any) : AddressValidationResponse => {
    return {
        success: response["Sucesso"],
        message: response["Mensage"],
        valid: response["EnderecoValido"]
    }
}