import React from 'react';
import styled from 'styled-components';
import { StoreLogo, Button, Icon, Loading } from '../common';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'

import fbTextImage from '../../assets/facebook.svg'
import googleTextImage from '../../assets/google.svg'
import { device } from '../../constants/responsive';

import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, TERMS_OF_USE } from '../../config';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;
  padding: 20px 0px;
  box-sizing: border-box;
  margin: 0px auto;

  @media ${ device.mobile } {
    max-width: 310px;
    padding: 20px;
  }

  @media ${ device.tablet } {
    max-width: 310px;
  }

  @media ${ device.desktop } {
    max-width: 360px;
    padding: 20px;
  }
`

const StoreLogoWrapper = styled.div`
  padding-bottom: 30px;
`

const SocialButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 15px;
`

const StyledSocialButton = styled.button`
  flex: 1;
  padding: 5px 0 5px 0;
  border: 0;
  border-radius: 5px;
  font-size: 11px;
  background: ${props => props.theme.colors.facebook};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(1) {
    margin-right: 10px;
  }

  &:nth-child(2) {
    margin-left: 10px;
  }

  svg {
    transform: scale(.75);
  }

	> div > img {
		margin-top: -3px;
	}
`

const StyledFacebookButton = styled(StyledSocialButton)`
  background: ${props => props.theme.colors.facebook};
`

const StyledGoogleButton = styled(StyledSocialButton)`
  background: ${props => props.theme.colors.google};
`

const TextImageBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`

const LoginButton = styled(Button)`
  color: ${props => {
    if (props.mode === 'fridge') {
      return '#41BAC8'
    }

    return props.theme.colors.textBold
  }};
  border-color: ${props => {
    if (props.mode === 'fridge') {
      return '#41BAC8'
    }

    return props.theme.colors.textBold
  }};

	> div > svg {
		transform: scale(.75);
		

		path {
			fill: #41BAC8;
		}
	}
`

const LoginText = styled.span`
  font-size: 13px;
  padding-left: 14px;
`

const TermsText = styled.span`
  padding-top: 15px;
  font-size: 13px;
  text-align: center;
  color: ${props => {
    if (props.mode === 'fridge') {
      return '#41BAC8'
    }

    return props.theme.colors.textBold
  }};

  > a {
    background: none;
    border: 0;
    color: ${props => {
      if (props.mode === 'fridge') {
        return '#41BAC8'
      }

      return props.theme.colors.textBold
    }};
    font-weight: 300;
    text-decoration: underline;
  }
`

const LoginPanel = ({onFacebookLogin, onGoogleLogin, showLoginForm, showLogo, mode, logo}) => {
  return (
    <Wrapper>
      { showLogo === true && <StoreLogoWrapper>
          <StoreLogo theme={{logoImage: logo}} />
        </StoreLogoWrapper>
      }

      <SocialButtonsWrapper>
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          disableMobileRedirect={true}
          isMobile={isMobile}
          callback={onFacebookLogin}
          render={({onClick, isDisabled, isProcessing}) => (
            <StyledFacebookButton onClick={onClick} disabled={isDisabled}>
              <Icon name="facebook" />
              <TextImageBlock>
                {isProcessing ? (
                  <Loading height={20} />
                ) : (
                  <>
                    <span>
                      entrar com 
                    </span>
                    <img src={fbTextImage} alt="Facebook" />
                  </>
                )}
              </TextImageBlock>
            </StyledFacebookButton>
          )} />
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Login"
          onSuccess={onGoogleLogin}
          onFailure={onGoogleLogin}
          cookiePolicy={'single_host_origin'}
          render={renderProps => (
            <StyledGoogleButton onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <Icon name="google" />
              <TextImageBlock>
                <span>
                  entrar com 
                </span>
                <img src={googleTextImage} alt="Google" />
              </TextImageBlock>
            </StyledGoogleButton>
          )} 
        />
      </SocialButtonsWrapper>
      <LoginButton mode={mode} onClick={showLoginForm} stretch>
        <Icon name="mail" />
        <LoginText>
          entrar / cadastrar com <b>email</b>
        </LoginText>
      </LoginButton>
      <TermsText mode={mode}>
        Ao se cadastrar você concorda com os <br/><a rel="noopener noreferrer" target="_blank" href={TERMS_OF_USE}>Termos de uso</a>
      </TermsText>
    </Wrapper>
  )
}

export default LoginPanel
