import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '../common';
import { numberToCurrency } from '../../utils';
import CounterInput from '../common/CounterInput';

const AddonCell = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 12px;

    ${props => props.disabled && `
        opacity: 0.5
    `}
`

const AddonActionBlock = styled.div`
    display: flex;
    align-items: center;
`

const AddonNameBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    flex-grow: 1
`

const AddonName = styled.span`
    font-size: 14px;
`

const AddonDescription = styled.span`
    font-size: 11px;
`

const AddonPrice = styled.span`
    font-size: 14px;
    min-width: 65px;
    white-space: nowrap;
`

class AddonGroup extends React.PureComponent {

    constructor(props) {
        super(props);
        const isDisabled = props.checkedItems && props.checkedItems.length >= props.max
        const checkedItems = props.checkedItems && props.checkedItems.reduce(function(map, item) {
            map[item.id] = item;
            return map;
        }, {});
        this.state = {
            checkedItems: checkedItems || {},
            disabled: isDisabled || false
        }
    }

    isMaxReached = (checkedItems) => {
        const total = Object.values(checkedItems).reduce( (acc, item) => acc + item.quantity, 0);
        if (total >= this.props.max) return true;
        else return false;
    }

    handleCheck = (item, add, quantity = 1) => {
        const checkedItems = {...this.state.checkedItems};
        if (add) {
            if (checkedItems[item.id]) {
                checkedItems[item.id].quantity = quantity;
            } else {
                checkedItems[item.id] = {
                    id: item.id,
                    name: item.name,
                    quantity,
                    price: item.price,
                    groupId: item.groupId
                }
             }
        } else {
            delete checkedItems[item.id];
        }
        const isDisabled = this.isMaxReached(checkedItems);
        this.setState({ checkedItems, disabled: isDisabled })

        const checkedItemsList = Object.values(checkedItems);
        this.props.updateCheckedItems(this.props.addonIndex, checkedItemsList)
    }

    render() {
        return this.props.items && this.props.items.map(item => {
            const checked = !!this.state.checkedItems[item.id];
            const quantity = checked && this.state.checkedItems[item.id].quantity;
            return (
                <AddonCell disabled={this.state.disabled && !checked} key={`addonItem#${item.id}`}>
                    <AddonActionBlock>
                        {this.props.checkbox && (
                            <Checkbox 
                                checked={checked} 
                                disabled={this.state.disabled && !checked}
                                onCheck={(check) => this.handleCheck(item, check)}/>
                        )}
                        {!this.props.checkbox && (
                            <CounterInput
                                counter={quantity}
                                min={0}
                                max={this.props.max}
                                disabled={this.state.disabled && !checked}
                                onChange={(counter) => this.handleCheck(item, counter > 0, counter)} />
                        )}
                    </AddonActionBlock>
                    <AddonNameBlock>
                        <AddonName>
                            {item.name}
                        </AddonName>
                        <AddonDescription>
                            {item.description}
                        </AddonDescription>
                    </AddonNameBlock>
                    {!!item.price && item.price > 0 && (
                        <AddonPrice>
                            + {numberToCurrency(item.price)}
                        </AddonPrice>
                    )}
                </AddonCell>
            )
        })
    }
}

export default AddonGroup;