import { actionTypes } from "../actions/pages";

const initialState = {
    navigating: false,
}

const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NAVIGATING: {
        return {
            ...state,
            navigating: action.payload
        }
    }
    default: {
      return state;
    }
  }
};

export default pagesReducer;
