import { actionTypes } from "../actions/ui"

const initialState = {
  modals: {
    CVVModal: {
      show: false
    },
    addressModal: {
      show: false,
      state: 0
    },
    errorProductsModal: false,
    loginModal: {
      show: false,
      state: 0
    }
  },
  navCurrentList: 0,
  notification: {
    type: null,
    text: null
  }
}

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_CVV_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          CVVModal: {
            ...state.modals.CVVModal,
            ...action.payload
          }
        }
      }

    case actionTypes.TOGGLE_ADDRESS_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          addressModal: {
            ...state.modals.addressModal,
            ...action.payload
          }
        }
      }

    case actionTypes.TOGGLE_ERROR_PRODUCTS_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          errorProductsModal: action.payload
        }
      }

    case actionTypes.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          loginModal: action.payload
        }
      }

    case actionTypes.SET_NAV_CURRENT_LIST:
      return {
        ...state,
        navCurrentList: action.payload
      }

    case actionTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      }

    default:
      return state
  }
}

export default uiReducer
