import {useState, useEffect } from 'react'

const useLocation = () => {
    const [coords, setCoords] = useState(null);
    const [error, setError] = useState(null);
    const [isAllowed, setIsAllowed] = useState()

    const positionFetched = (position) => {
        if (position) {
            setCoords(position.coords)
            setIsAllowed(true)
        }
    }

    const positionError = (error) => {
        if (error.code === error.PERMISSION_DENIED) {
            setIsAllowed(false)
        }
        setError(error)
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(positionFetched, positionError);
        }
    }, []);
    
    return { coords, isGeolocationAllowed: isAllowed, isGeolocationAvailable: navigator.geolocation, error};
};

export default useLocation