import React from 'react';
import styled from 'styled-components';
import { Icon } from './';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

const MinusButton = styled.button`
    background: transparent;
    border: 0;
    padding-left: 0;
`

const PlusButton = styled.button`
    background: transparent;
    border: 0;
    padding-right: 0;
`

const Counter = styled.span`
    flex-grow: 1;
`

class CounterInput extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            counter: props.counter || 0
        }
    }

    incrementCounter = (inc) => {
        const newCounter = this.state.counter + inc;
        if (newCounter <= this.props.max && newCounter >= this.props.min && !this.props.disabled) {
            this.setState({
                counter: newCounter
            })
            if (this.props.onChange) {
                this.props.onChange(newCounter)
            }
        }
    }

    handlePlus = () => {
        this.incrementCounter(+1);
    }

    handleMinus = () => {
        this.incrementCounter(-1);
    }

    render() {
        return (
            <Wrapper>
                <MinusButton onClick={this.handleMinus}><Icon name="minus" width={"16px"} height={"16px"}/></MinusButton>
                <Counter>{this.state.counter}</Counter>
                <PlusButton onClick={this.handlePlus}><Icon name="plus" width={"16px"} height={"16px"}/></PlusButton>
            </Wrapper>
        )
    }
}

export default CounterInput;