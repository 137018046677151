import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from '../../assets/public/loading.json';

const Wrapper = styled.div`
    pointer-events: none;
`

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const Loading = (props) => (
    <Wrapper>
        <Lottie options={defaultOptions}
            height={props.height || 100}
            width={props.width || 100}
        />
    </Wrapper>
)

export default Loading;