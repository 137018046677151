import { combineReducers } from 'redux'

import auth from './auth'
import cart from './cart'
import pages from './pages'
import payment from './payment'
import requests from './requests'
import store from './store'
import ui from './ui'
import user from './user'
import fridge from './fridge'
import gtm from './gtm'

export default combineReducers({
	auth,
	cart,
	pages,
	payment,
	requests,
	store,
	ui,
	user,
	fridge,
	gtm
})
