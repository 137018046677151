import { GTM_KEY } from './../../config'

export const actionTypes = {
  SET_GTM: 'SET_GTM',
}

export function handleGTM(data) {
  return (dispatch, getState, api) => {
    if (GTM_KEY && GTM_KEY !== 'false') {
      
    }

    if (GTM_KEY && GTM_KEY !== 'false' && !data) {
      dispatch(setGTM({
        events: null
      }))

      return
    }

    if (GTM_KEY && GTM_KEY !== 'false' && data) {
      const gtm = dispatch(getGTM())

      const {
        events
      } = gtm

      const {
        event
      } = data

      const hasCalled = (events && events.filter(filteredItem => filteredItem.event === event)[0]) || false
      const hasCalledWithEcommerce = (events && events.filter(filteredItem => filteredItem.ecommerce && filteredItem.ecommerce.event === event)[0]) || false

      if (!hasCalled && !hasCalledWithEcommerce) {
        const newEvents = [
          ...(events && events.length ? events : []),
          data
        ]

        const newGTM = {
          events: newEvents
        }

        dispatch(setGTM(newGTM))

        window.dataLayer && window.dataLayer.push(data)
      }
    }
  }
}

export const setGTM = (gtm) => ({
  type: actionTypes.SET_GTM,
  payload: gtm
})

export const getGTM = () => (dispatch, getState, api) => {
  const gtm = getState().gtm

  return gtm
}
