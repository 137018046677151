import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setNavigating } from '../../redux/actions/pages';

class PageNavigator extends React.Component {
    componentDidMount() {
        if (this.props.history.location.hash) {
            this.props.history.replace(this.props.history.location.path);
        }
        this.props.setNavigating(false)
    }

    render() {
        return null;
    }
}

export default connect(null, { setNavigating })(withRouter(PageNavigator));