interface FridgeCheckoutStatusResponse {
	id: string
	statusId: number
	checkoutId: number
	success: boolean
	message: string
}

interface FridgeStatusResponse {
	requestId: string
	success: boolean
	message: string
}

interface FridgeData {
	id: string
	name: boolean
	success: boolean
}

export const mapFridgeCheckoutStatusResponse = (response: any) : FridgeCheckoutStatusResponse => {
	return {
		id: response['Id'],
		statusId: response['StatusId'],
		checkoutId: response['ComandaId'],
		success: response['Sucesso'],
		message: response['Mensagem']
	}
}

export const mapFridgeStatusResponse = (response: any) : FridgeStatusResponse => {
	return {
		requestId: response['SolicitacaoId'],
		success: response['Sucesso'],
		message: response['Mensagem']
	}
}

export const mapFridgeData= (response: any): FridgeData => {
  return {
    id: response["Id"],
		name: response["Nome"],
		success: response['Sucesso']
  }
}
