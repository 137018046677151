import React from 'react'
import { Route, Switch } from "react-router-dom"

import { MULTI_STORE } from './config'

import HomePage from './pages/HomePage'
import PaymentPage from './pages/PaymentPage'
import StoreSelectPage from './pages/StoreSelectPage'

const Routes = (props) => {
  const {
    storeId
  } = props

  if (MULTI_STORE === "true" && !storeId) {
    return (
      <Switch>
        <Route path="/:id?" component={StoreSelectPage} />
      </Switch>
    )
  }

  return <Switch>
    <Route path="/finalizar-compra" component={HomePage} />
    <Route path="/checkout" component={PaymentPage} />
    <Route path="/cart" render={(props) => <HomePage {...props} cartModalVisible={true} />} />
    <Route exact path="/:id?" component={HomePage} />
  </Switch>
}

export default Routes
