import React from 'react';
import styled from 'styled-components';
import { StoreLogo, StoreName } from '../common';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 24px;
    justify-content: center;
    height: 74px;
`

const StoreNameWrapper = styled.div`
    flex-grow: 1;
    font-size: 15px;
    font-weight: 700;
    padding-left: 0px;
    padding-right: 40px;
    text-align: center;
    color: ${props => props.theme.colors.textPrimary};
`

const CartStoreName = (props) => (
    <Wrapper>
        <StoreLogo theme={{logoImage: props.logo}} width={48} height={48} />
        <StoreNameWrapper>
            <StoreName name={props.name} />
        </StoreNameWrapper>
    </Wrapper>
)

export default CartStoreName;