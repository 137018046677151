import React from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';

const Divider = styled.div`
  height: 1px;
  width: auto;
  background: ${props => props.theme.colors.lightGrey};
  margin-top: ${props => props.marginV};
  margin-bottom: ${props => props.marginV};
  margin-left: ${props => props.marginH};
  margin-right: ${props => props.marginH};
`

Divider.propTypes = {
    marginH: PropTypes.string,
    marginV: PropTypes.string
}

Divider.defaultProps = {
    marginH: '0',
    marginV: '0'
}

export default (props) => (<Divider {...props} >&nbsp;</Divider>);
