import React from 'react';
import styled from 'styled-components'

const Image = styled.img.attrs(props => ({
  src: props.theme.logoImage
}))`
  border: 0;
  width: ${props => props.width ? `${props.width}px` : "auto"};
  height: ${props => props.height ? `${props.height}px` : "auto"};
  max-width: 100%;
`

const StoreLogo = (props) => {
  return <Image {...props} />
}

export default StoreLogo;
