import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Box } from '../common/'
import { MenuList, MenuNav } from './'
import { device } from '../../constants/responsive';
import normalizeString from '../../utils/normalizeString';
import { setNavCurrentList } from '../../redux/actions/ui';
import { handleGTM } from '../../redux/actions/gtm'

const Wrapper = styled.div`
    position: relative;
    flex-grow: 1;
`

const ContentBox = styled(Box)`
    padding: 5px 10px 10px 10px;

    @media ${device.mobile} {
        border-radius: 0;
    }

    @media ${device.desktop} {
        width: auto;
        padding: 0 42px 42px 42px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`

class Menu extends React.Component {
    componentDidMount(){
        setTimeout(() => {
            this.addEventGTM(this.props.categories)
        }, 10000);
        
    }

    addEventGTM(categories){
        const selectedCategories = categories

        if(selectedCategories && selectedCategories.length > 0){
            const storeName = this.props.name
            const dataGTM = ({
                'ecommerce': {
                    'event': `CARDAPIO - ${storeName}`,
                    'currencyCode': 'BRL',
                    'impressions': selectedCategories.map(item => {
                        const categoryName = item.name
                        const products = item.products
                        return products.map(function (item) {
                           return {
                            'name': item.name,
                            'id': item.id,
                            'price': item.price,
                            'brand': null,
                            'category': categoryName,
                            'variant': null,
                            'list': `Cardapio - ${storeName}`,
                            'position': null
                           }
                        })
                    })
                }
            })

            this.props.handleGTM(dataGTM)
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.hash !== this.props.hash || 
            nextProps.loading !== this.props.loading ||
            nextProps.fixedNav !== this.props.fixedNav ||
            nextProps.currentList !== this.props.currentList ||
            nextProps.categories !== this.props.categories) {
            return true;
        }
        return false;
    }

    render() {       
        const {categories, fixedNav, loading, currentList, setNavCurrentList, handleGTM, queryProduct} = this.props;

        return (
            <Wrapper>
                <MenuNav categories={categories} fixed={fixedNav} />
                <ContentBox>
                    <MenuList 
                        categories={categories} 
                        loading={loading}
                        currentList={currentList}
                        queryProduct={queryProduct}
                        setNavCurrentList={setNavCurrentList}
                        handleGTM={handleGTM}
                    />
                </ContentBox>
            </Wrapper>
        )
    }
}


const filterCategories = (categories, term) => {
    if (!categories) return categories;
    if (!term || term === '') return categories;
    const normalizedTerm = normalizeString(term.toLowerCase());
    const newCategories = [];
    categories.forEach(category => {
      const newCategory = {...category};
      newCategory.products = newCategory.products.filter(product => {
        const normalizedName = normalizeString(product.name.toLowerCase());
        const normalizedDescription = product.description ? normalizeString(product.description.toLowerCase()) : '';
        if (normalizedName.includes(normalizedTerm) 
        || (normalizedDescription.includes(normalizedTerm))) {
          return true;
        }
        return false;
      })
      if (newCategory.products.length > 0) {
        newCategories.push(newCategory)
      }
    })
    return newCategories
  }
  
  const mapStateToProps = (state) => {
      const categories = [...state.store.menu.categories];
      const filter = state.store.menu.filter;
  
      return {
          categories: filterCategories(categories, filter),
          hash: state.store.menu.hash,
          loading: state.requests.storeInfo.loading,
          currentList: state.ui.navCurrentList,
          name: state.store.name
      }
  }


export default connect(mapStateToProps, {setNavCurrentList, handleGTM})(Menu);