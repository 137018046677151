import hash from 'object-hash';

const makeCartItemHash = (product, checkedAddons, observation) => {
    return hash({
        product,
        checkedAddons,
        observation
    })
}

export default makeCartItemHash;