import React, {
  useState,
  useEffect
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import {
  fetchFridgeCheckoutStatus,
  postFridgePayment
} from '../../../redux/actions/fridge'

import ApprovedPaymentGIF from '../../../assets/payment-approved-fridge.gif'
import NotApprovedPaymentGIF from '../../../assets/payment-not-approved-fridge.gif'
import ClosingFridgeGIF from '../../../assets/closing-fridge.gif'
import ShoppingGIF from '../../../assets/shopping-fridge.gif'
import OpeningFridgeGIF from '../../../assets/opening-fridge.gif'

import {
  CVV as CVVContainer
} from '../../../components/payment'

const steps = [{
  name: 'OPENING',
  index: 0
}, {
  name: 'OPENING_ERROR',
  index: 1
}, {
  name: 'SHOPPING',
  index: 2
}, {
  name: 'EMPTY',
  index: 3
}, {
  name: 'CLOSING',
  index: 4
}, {
  name: 'APPROVED_PAYMENT',
  index: 5
}, {
  name: 'NOT_APPROVED_PAYMENT',
  index: 6
}, {
  name: 'PAYMENT_CVV',
  index: 7
}]

const currentStep = {
  step: steps[0]
}

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 960px;
  margin-top: 0px;
  min-width: 320px;
`;

const ImageContainer = styled.img`
  width: 320px;
  height: auto;
  margin-top: -20px;
  position: relative;
`

const H1 = styled.h1`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`

function Shopping(props) {
  const [step, setStep] = useState(currentStep.step)
  const [status, setStatus] = useState(true)

  const {
    setContent,
    fridgeId
  } = props

  useEffect(() => {
    async function getStatus() {
      const result = await props.fetchFridgeCheckoutStatus(fridgeId)

      if (result.statusId === 1 || result.statusId === 2) { 
        setStep(steps[0])
        setTimeout(function(){
          getStatus()
        }, 5000)

        return
      }

      if (result.statusId === 3 || result.statusId === 5 || result.statusId === 12) { 
        setStep(steps[1])
        setStatus(false)
        setTimeout(function(){
          setContent('check-in')
        }, 5000)

        return
      }

      if (result.statusId === 4) { 
        setStep(steps[2])
        setTimeout(function(){
          getStatus()
        }, 5000)

        return
      }

      if (result.statusId === 6) { 
        setStep(steps[3])
        setStatus(false)
        setTimeout(function(){
          setContent('check-in')
        }, 5000)

        return
      }

      if (result.statusId === 7) { 
        setStep(steps[4])
        setTimeout(function(){
          getStatus()
        }, 5000)

        return
      }

      if (result.statusId === 8) { // PAYMENT_CVV
        setStatus(false)
        setStep(steps[7])

        return
      }

      if (result.statusId === 9) { // APPROVED_PAYMENT
        setStatus(false)
        setStep(steps[5])
        setTimeout(() => setContent({
          content: 'checkout',
          fridgeId
        }), 3000)

        return
      }

      if (result.statusId === 10) { 
        setStep(steps[4])
        setTimeout(function(){
          getStatus()
        }, 5000)

        return
      }

      if (result.statusId === 11 || result.statusId === 12) { // NOT_APPROVED_PAYMENT
        setStep(steps[6])
        setStatus(false)
        setTimeout(function(){
          setContent('checkout')
        }, 5000)

        return
      }
    }

    if (status === true) getStatus()
  }, [fridgeId, props, setContent, status])

  const handleSaveCVV = async () => {
    const result = await props.postFridgePayment(props.fridgeId)

    if (result.success) {
      setStep(steps[5])
      setTimeout(() => setContent({
        content: 'checkout',
        fridgeId
      }), 3000)

      return
    }

    setStep(steps[6])
    setTimeout(() => setContent({
      content: 'check-in',
      fridgeId
    }), 7000)
  }

  return <Wrapper>
    { step.name === 'OPENING' && <ImageContainer src={OpeningFridgeGIF} /> }

    { step.name === 'OPENING_ERROR' && <>
      <H1>Erro ao abrir geladeira</H1>
    </> }

    { step.name === 'SHOPPING' && <ImageContainer src={ShoppingGIF} /> }

    { step.name === 'CLOSING' && <ImageContainer src={ClosingFridgeGIF} /> }

    { step.name === 'APPROVED_PAYMENT' && <ImageContainer src={ApprovedPaymentGIF} /> }

    { step.name === 'NOT_APPROVED_PAYMENT' && <ImageContainer src={NotApprovedPaymentGIF} /> }

    { step.name === 'EMPTY' && <>
      <H1>Nenhum produto retirado</H1>
    </> }

    { step.name === 'PAYMENT_CVV' && <>
      <CVVContainer onSuccess={handleSaveCVV} />
    </> }
  </Wrapper>
}

const mapStateToProps = () => {
  return {}
}

export default connect(
  mapStateToProps, {
    fetchFridgeCheckoutStatus,
    postFridgePayment
  }
)(withRouter(Shopping))
