import React, {
  useState,
  useEffect
} from 'react'
import styled from 'styled-components'

import { device } from '../../constants/responsive'

import { Form } from '../common/form/'
import { notEmpty } from '../common/form/validationFunctions'

import {
  TextInput
} from '../common'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;
  min-width: 320px;

  @media ${ device.desktop } {
    min-width: 360px;
  }

  form > div {
    border: 0px none;

    > div {
      &:first-child {
        font-size: 28px;
        top: 0px;
      }
    }

    input {
      margin: 0px;
      width: 100%;
      font-size: 28px;
      border: #ccc 1px solid;
      border-radius: 10px;
    }
  }
`

const Title = styled.h1`
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  border-bottom: #000 1px solid;
  min-width: 100%;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
`

const Paragraph = styled.p`
  flex: 1;
  font-size: 16px;
  color: #AAABAE;
  min-width: 100%;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 40px;
`

const Button = styled.button`
  background: ${ props => props.disabled ? '#9C9C9C' : '#000' };
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 35px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  flex: 1;
  min-width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`

function Table(props) {
  const handleFormSetup = () => [{
    id: 'table',
    validations: [notEmpty],
    Component: (formInputProps) => {
      if (formInputProps.value) {
        setDisabled(false)
      }

      return <TextInput {...formInputProps} type='text' name='table' placeholder='000' center 
        inputProps={{
          maxLength: 6
        }}
      />
    }
  }]

  const [form, setForm] = useState(null)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setForm(handleFormSetup())
  }, [])

  return <Wrapper>
    <Title>Qual a sua mesa?</Title>

    { form && <Form formInputs={form} onSubmit={props.handleSaveTable}>
      {
        (inputs) => {
          return (<>
            { <inputs.table /> }

            <Paragraph><em>Insira o número da sua mesa, por favor.</em></Paragraph>

            <Button type="submit" disabled={disabled} className="action" stretch>
              CONFIRMAR
            </Button>
          </>
          )
        }
      }
    </Form> }
  </Wrapper>
}

export default Table
