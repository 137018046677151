import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from "react-redux";

const Blocker = styled.div`
    width: 100%;
    height: 45px;
    display: block;
`

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 45px;
    min-width: 320px;
    display: flex;
    overflow-x: auto;
    background: white;
    &::-webkit-scrollbar {
        display: none;
    }
    ${props => props.fixed && css`
        position: fixed;
        top: 65px;
        left: 0;
        right: 0;
        border-bottom: 1px solid ${props => props.theme.colors.lightGrey}
    `}
`

const NavItem = styled.a`
    display: inline-block;
    margin: 0 14px;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    ${props => props.selected && css`
        font-weight: 700;
        border-bottom: 5px solid ${props => props.theme.colors.primary};
    `}
    &:hover {
        text-decoration: none !important;
    }
`

const NavText = styled.span`
    color: ${props => props.theme.colors.primary}
    text-transform: uppercase;
    font-size: 12px;
`

class MenuNavMobile extends React.Component {

    constructor(props) {
        super(props);
        this.container = React.createRef();
        let refs = [];
        if (props.categories) {
            props.categories.forEach( (option, i) => {
                refs[i] = React.createRef();
            })
        }
        this.items = refs;
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedCategory } = this.props;
        if (prevProps.selectedCategory !== selectedCategory) {
            const el = this.items[selectedCategory];
            if (el) {
                this.container.current.scroll({
                    left: el.offsetLeft,
                    behavior: 'smooth'
                });
            }
        }
    }

    setRef = (ref, value) => {
        const items = [...this.items]
        items[value] = ref;
        this.items = items;
    }

    render() {
        const { categories } = this.props;
        const options = categories && categories.map(category => ({
            name: category.name,
            value: category.name
        }))
        return (
            <>
                <Blocker />
                <Container fixed={this.props.fixed} ref={this.container}>
                    {options && options.map( (option, i) => (
                        <NavItem 
                            key={`nav-bar#${i}`} 
                            selected={this.props.selectedCategory === i} 
                            href={`#${option.value}`}
                            ref={(ref) => this.setRef(ref, i)}
                        >
                            <NavText>
                                {option.name}
                            </NavText>
                        </NavItem>
                    ))}
                </Container>
            </>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    const selectedCategory = state.ui.navCurrentList;
    return {
        selectedCategory
    }
}

export default connect(
    mapStateToProps,
    null,
)(MenuNavMobile);