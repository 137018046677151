import React from 'react';
import styled from 'styled-components';

import { MESA, BALCAO } from '../../config'

import { device } from '../../constants/responsive'

import {
	GetQueryString
} from '../../common/helpers'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;
  min-width: 320px;

  @media ${ device.desktop } {
    min-width: 360px;
  }
`

const Title = styled.h1`
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  border-bottom: #000 1px solid;
  min-width: 100%;
  text-align: center;
  margin-bottom: 55px;
  padding-bottom: 20px;
`

const Paragraph = styled.p`
  flex: 1;
  font-size: 20px;
  color: #000;
  min-width: 100%;
  text-align: center;
  margin-bottom: 55px;
`

const Button = styled.button`
  background: #00CC74;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 35px;
  color: #fff;
  border-radius: 10px;
  flex: 1;
  min-width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`

function Modality(props) {
  const queryString = GetQueryString()

  const table = !Object.keys(queryString).length ? null : {
    number: queryString.mesa || false
  }

  const {
    handleModality
  } = props

  return <Wrapper>
    <Title>Loja</Title>

    <Paragraph><strong>Como gostaria de pedir?</strong></Paragraph>

    <Button type="button" className="action" stretch onClick={() => {
      handleModality({ modality: 2, table: false })}
    }>
      RETIRAR NA LOJA
    </Button>

    { BALCAO === 'true' && <Button type="button" className="action" stretch onClick={() => {
      handleModality({ modality: 1, table: false })}
    }>
      COMER NA LOJA
    </Button> }
    
    { MESA === 'true' && table && table.number && <Button type="button" className="action" stretch  onClick={() => {
      handleModality({ modality: 1, table: true })}
    }>
      PEDIR NA MESA
    </Button> }
  </Wrapper>
}

export default Modality
