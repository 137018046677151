import { actionTypes } from '../actions/payment'

const initialState = {
  onlineCards: [],
  offlineCards: [],
  brandsImages: []
}

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AVAILABLE_CARDS: 
      return {
        ...state,
        onlineCards: action.payload.onlineCards,
        offlineCards: action.payload.offlineCards
      }

    case actionTypes.SET_AVAILABLE_BRANDS:
      return {
        ...state,
        brandsImages: action.payload.brandsImages
      }

    default:
      return state
  }
}

export default paymentReducer
