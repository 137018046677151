import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-height: 70px;
  min-height: 70px;
  border-bottom: #B8B8B8 1px solid;
  box-shadow: #888888 0px 0px 1px;
`;

const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  color: ${props => props.theme.colors.black};
`

function HeaderBox(props) {
  const {
    name
  } = props

  return (
    <Wrapper>
      <H1> { name } </H1>
    </Wrapper>
  )
}

export default HeaderBox
