import React, {
  useState,
  useEffect
} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  fetchEcommerceData
} from '../../redux/actions/store'

import {
  Header
} from './header'

import {
  Content
} from './content'

const AppWrapper = styled.div`
  display : flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  flex: 1;
  background: #fff;
`

function Ecommerce(props) {
  const [content, setContent] = useState('checkout-receipt')

  const currentContent = { value: null }

  const {
    storeName,
    storeId
  } = props

  useEffect(() => {
    async function getEcommerce() {
      await props.fetchEcommerceData(storeId)
    }

    getEcommerce()
  }, [props, storeId])

  if (typeof content === 'object') {
    Object.assign(currentContent, {
      value: {
        ...content
      }
    })
  }

  if (typeof content === 'string') {
    Object.assign(currentContent, {
      value: content
    })
  }

  return <AppWrapper>
    <Header name={storeName} />

    <Content name={typeof content === 'object' ? currentContent.value.content : currentContent.value} setContent={setContent} />
  </AppWrapper>
}

const mapStateToProps = (state) => {
  return {
    storeId: state.store.id,
    storeName: state.store.name
  }
}

export default connect(mapStateToProps, {
  fetchEcommerceData
})(withRouter(Ecommerce))
