import React from 'react';
import styled from 'styled-components';
import { Modal, Button, Icon, Notification, LoadingOverlay } from '../../common';
import { AvailableCardBrandsBox, CardDetailForm, CardNumberForm, CardSelectBrandForm } from './';
import { device } from '../../../constants/responsive';

const Wrapper = styled.div`

    @media ${device.desktop} {
        width: 363px;
    }
`

const Content = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
`

const TitleIconContainer = styled.div`
    padding-right: 10px;
`

const Title = styled.span`
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.theme.colors.textBold};
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
`

const CardNumberContainer = styled.div`
    margin-bottom: 50px;
`

const AddButton = styled(Button)`
    width: 100% !important;
    height: 60px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`

const MODAL_PAGES = {
    CARD_NUMBER: 0,
    CARD_DETAILS: 1,
    CARD_BRAND: 2,
}

class AddNewCardModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: MODAL_PAGES.CARD_NUMBER,
            brandId: null,
            cardNumber: undefined,
        }
        this.numberFormRef = React.createRef();
        this.detailsFormRef = React.createRef();
    }

    componentDidUpdate() {
        if (!this.props.isOpen && this.state.currentPage !== MODAL_PAGES.CARD_NUMBER) {
            this.setState({currentPage: MODAL_PAGES.CARD_NUMBER})
        }
    }

    getCardLastDigits = () => {
        return this.state.cardNumber && this.state.cardNumber.slice(-4);
    }

    handleCardNumberSubmit = ({brandId, cardNumber}) => {
        if (brandId) {
            this.setState({
                brandId,
                cardNumber,
                currentPage: MODAL_PAGES.CARD_DETAILS
            })
        } else {
            this.setState({
                cardNumber,
                currentPage: MODAL_PAGES.CARD_BRAND
            })
        }
    }

    handleCardDetailsSubmit = ({cardName, cardDate, cardCVV, cardCpf}) => {
        const { cardNumber, brandId } = this.state
        return this.props.addCard({cardNumber, cardName, cardDate, cardCVV, cardCpf, brandId});
    }

    handleSelectBrand = (brandId) => {
        this.setState({
            brandId,
        })
    }

    handleButtonClick = () => {
        if (this.props.loading) {
            return
        }

        if (this.state.currentPage === MODAL_PAGES.CARD_NUMBER) {
            this.numberFormRef.current.submit()

            return
        }
        
        if (this.state.currentPage === MODAL_PAGES.CARD_DETAILS) {
            this.detailsFormRef.current.submit()

            return
        }
        
        if (this.state.currentPage === MODAL_PAGES.CARD_BRAND && this.state.brandId) {
            this.setState({
                currentPage: MODAL_PAGES.CARD_DETAILS
            })

            return
        }
    }

    renderCardNumberPage = () => {
        if (this.state.currentPage !== MODAL_PAGES.CARD_NUMBER) return null
        return (
            <FormWrapper>
                <CardNumberContainer>
                    <CardNumberForm formRef={this.numberFormRef} onSubmit={this.handleCardNumberSubmit} />
                </CardNumberContainer>
                <AvailableCardBrandsBox images={this.props.brandsImages} />
            </FormWrapper>
        )
    }

    renderCardDetailsPage = () => {
        if (this.state.currentPage !== MODAL_PAGES.CARD_DETAILS) return null
        return (
            <FormWrapper>
                <CardDetailForm 
                    brandId={this.state.brandId} 
                    cardDigits={this.getCardLastDigits()}
                    onSubmit={this.handleCardDetailsSubmit} formRef={this.detailsFormRef} />
            </FormWrapper>
        )
    }

    renderCardSelectBrandPage = () => {
        if (this.state.currentPage !== MODAL_PAGES.CARD_BRAND) return null

        return (
            <FormWrapper>
                <CardSelectBrandForm selectedBrand={this.state.brandId} onSelect={this.handleSelectBrand} />
            </FormWrapper>
        )
    }

    render() {
        const {isOpen, onClose} = this.props
        const { currentPage } = this.state

        return (
            <Modal name="card" isOpen={isOpen} onClose={onClose}>
                <Notification type="error" text={this.props.error} onDismiss={this.props.onDismissError} timeout={5} />
                <LoadingOverlay visible={this.props.loading} margin={"5px 5px 60px 5px"} />
                <Wrapper>
                    <Content>
                        <TitleContainer>
                            <TitleIconContainer>
                                <Icon name={"creditCard"} />
                            </TitleIconContainer>
                            <Title>
                                Cadastrar cartão
                            </Title>
                        </TitleContainer>
                        {this.renderCardNumberPage()}
                        {this.renderCardDetailsPage()}
                        {this.renderCardSelectBrandPage()}
                    </Content>
                    <AddButton onClick={this.handleButtonClick} className="primary">
                        {currentPage === MODAL_PAGES.CARD_NUMBER && 'CONTINUAR'}
                        {currentPage === MODAL_PAGES.CARD_DETAILS && 'CADASTRAR'}
                        {currentPage === MODAL_PAGES.CARD_BRAND && 'CONTINUAR'}
                    </AddButton>
                </Wrapper>
            </Modal>
        )
    }
}

export default AddNewCardModal
