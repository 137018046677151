import React from "react";
import styled from "styled-components";

import { DELIVERY_MODALITIES } from "../../constants/delivery";
import { numberToCurrency } from "../../utils";
import { device } from '../../constants/responsive'

const Wrapper = styled.div`
  background-color: #e2e2e224;
  font-weight: 400;
  padding: 24px;
  font-size: 15px;
  color: ${(props) => props.theme.colors.mediumGrey};
  
  @media ${device.desktop} {
    word-break: break-all;
  }

`;

const ValueWrapper = styled.span`
  font-weight: 500;
`;

const CartMinimumValue = ({
  minimumValue,
  deliveryModality
}) => {
  const getFeeText = () => {
    const texts = {
      [DELIVERY_MODALITIES.DELIVERY]: 'A taxa de entrega não está inclusa.',
      [DELIVERY_MODALITIES.PICKUP]: 'Taxas não estão inclusas.'
    }
    return texts[deliveryModality] || ''
  }

  return (
    <Wrapper>
      O valor mínimo do pedido é de <ValueWrapper>{ numberToCurrency(minimumValue) }</ValueWrapper>. 
      { getFeeText() }
    </Wrapper>
  )
}

export default CartMinimumValue
