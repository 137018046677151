const handleRequestError = (e) => {
    const error = {};
    if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (e.response.data["Mensagem"]) {
            error.message = e.response.data["Mensagem"];
        } else {
            error.message = String(e.response.data);
        }
        error.status = e.response.status;
    } else if (e.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        error.message = String(e.responseText);
        error.status = e.status;
    } else {
        // Something happened in setting up the request that triggered an Error
        error.message = String(e.message);
        error.status = e.status;
    }
    return error;
}

export default handleRequestError