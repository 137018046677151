import React from 'react';
import styled from 'styled-components';
import { CreditCardCell, AddNewCardCell } from '.';
import { device } from '../../constants/responsive';

const Wrapper = styled.div`
    position: relative;
    display: grid;
    grid-gap: 10px;
    flex: 1;

    @media ${device.mobile} {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media ${device.desktop} {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr
    }
`

const Container = styled.div`
    padding: 0 12px;
`

const OnlinePaymentBlock = ({cards, onCheckCard, onDeleteCard, onAddCard, selected}) => {
    
    const isCardSelected = (card) => {
        if (selected && card.id === selected.id) {
            return true;
        }
        return false;
    }

    return (
        <Wrapper>
            {cards && cards.map( (card, i) => (
                <Container key={`onlineCard#${i}`}>
                    <CreditCardCell 
                        card={card}
                        checked={isCardSelected(card)}
                        onCheck={onCheckCard} 
                        onDelete={onDeleteCard}
                    />
                </Container>
            ))}
            <Container>
                <AddNewCardCell onClick={onAddCard} />
            </Container>
        </Wrapper>
    )
}

export default OnlinePaymentBlock;