import React from 'react';
import styled from 'styled-components';

import { Icon } from '../common'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 15px 0;
    color: ${props => props.theme.colors.primary}
    cursor: pointer;
`

const IconWrapper = styled.div`
`

const TextWrapper = styled.div`
    padding-left: 10px;
    font-weight: 700;
    text-align: center;
`

const CartCouponCell = ({coupon, onClick}) => (
    <Wrapper onClick={onClick}>
        <IconWrapper>
            <Icon name="tag" stroke="primary"/>
        </IconWrapper>
        <TextWrapper>
            {coupon 
                ? coupon
                : "adicionar cupom de desconto"
            }
        </TextWrapper>
    </Wrapper>
)

export default CartCouponCell;