import React from 'react';
import styled, { withTheme } from 'styled-components';

import { HeaderStoreName } from './';
import { HEADER_ANIM_TIME } from '../../constants/header';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${props => props.theme.dimensions.headerHeightShrinkedMobile}px
    opacity: 0;
    pointer-events: none;
    transition: all ${HEADER_ANIM_TIME}s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey}
    background: white;

    ${props => props.isCheckout && `
        position: static;
        width: 100%;
        opacity: 1;
        margin-bottom: 10px;
        pointer-events: all;
    `}
    
    ${props => props.fixed && `
        opacity: 1;
        pointer-events: all;
    `}
`
const SmallHeaderBar = ({fixed, isCheckout, theme}) => (
    <Wrapper fixed={fixed} isCheckout={isCheckout}>
        <HeaderStoreName>
            {theme.storeName}
        </HeaderStoreName>
    </Wrapper>
)

export default withTheme(SmallHeaderBar);