export const CUSTOM_CALCULATION_TYPE = {
    FIXED: 1,
    AVERAGE: 2,
    HIGHEST: 3
};

const calcFixedValue = (addonItems) => addonItems.reduce((price, addonItem) => 
    price + (addonItem.price * addonItem.quantity), 0);

const calcAverageValue = (addonItems) => {
    const tempResult = addonItems.reduce((temp, addonItem) => ({
        price: temp.price + addonItem.price,
        quantity: temp.quantity + addonItem.quantity
    }), { price: 0, quantity: 0 });
    return tempResult.price / tempResult.quantity;
}; 

const calcHighestValue = (addonItems) => addonItems.reduce((highestPrice, addonItem) => 
    addonItem.price > highestPrice ? addonItem.price : highestPrice, 0); 

const getCalcStrategy = (type) => {
    const types = {
       [CUSTOM_CALCULATION_TYPE.FIXED]: calcFixedValue,
       [CUSTOM_CALCULATION_TYPE.AVERAGE]: calcAverageValue,
       [CUSTOM_CALCULATION_TYPE.HIGHEST]: calcHighestValue,
    };
    return types[type] || types.FIXED;
}

export const calculateAddonItems = (type, addonItems) => {
    const calc = getCalcStrategy(type);
    return calc(addonItems) || 0;
}
