import React from 'react'
import styled from 'styled-components'

import {
  Home
} from '../'
import {
  CheckIn
} from '../'
import {
  NoCard
} from '../'
import {
  WithCard
} from '../'
import {
  Cards
} from '../'
import {
  NewCard
} from '../'
import {
  Shopping
} from '../'
import {
  Checkout
} from '../'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0px;
`;

class Content extends React.Component {
  render() {
    const {
      name,
      fridgeId,
      setContent
    } = this.props

    const setHighOrderContent = (content) => {
      setContent(content)
    }

    return (
      <Wrapper>
        { name === 'home' && <Home setContent={setHighOrderContent} /> }

        { name === 'check-in' && <CheckIn setContent={setHighOrderContent} /> }

        { name === 'no-card' && <NoCard setContent={setHighOrderContent} /> }

        { name === 'with-card' && <WithCard setContent={setHighOrderContent} /> }

        { name === 'cards' && <Cards setContent={setHighOrderContent} /> }

        { name === 'new-card' && <NewCard setContent={setHighOrderContent} /> }

        { name === 'shopping' && fridgeId && <Shopping setContent={setHighOrderContent} fridgeId={fridgeId} /> }

        { name === 'checkout' && fridgeId && <Checkout fridgeId={fridgeId} /> }
      </Wrapper>
    )
  }
}

export default Content
