import AddonItem from '../models/addonItem';

const mapAPIAddonItem = (apiAddonItem: any) : AddonItem => {
    return {
        id: apiAddonItem["Codigo"],
        name: apiAddonItem["Nome"],
        price: apiAddonItem["Valor"],
        description: apiAddonItem["Descricao"],
        quantity: apiAddonItem["Quantidade"] || 0,
        groupId: apiAddonItem["GrupoId"]
    }
}

export default mapAPIAddonItem;