import React from 'react';
import styled from 'styled-components';
import { Loading } from './';

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(255,255,255,0.6);
    display: none;
    margin: ${props => props.margin};
    ${props => props.visible && `
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`

const LoadingOverlay = ({visible, margin}) => (
    <Overlay visible={visible} margin={margin}>
        <Loading width={150} height={150} />
    </Overlay>
)

export default LoadingOverlay