import React, {
  useEffect
} from 'react'
import styled from 'styled-components'

import LoadingGIF from '../../../assets/loading.gif'
import CheckedIcon from '../../../assets/icon_checked.png'
import UncheckedIcon from '../../../assets/icon_unchecked.png'

import { device } from '../../../constants/responsive'

import {
  getCardBrandImageById,
  numberToCurrency,
  formatCpf
} from '../../../utils'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0px;

  @media ${ device.desktop } {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  > div {
    background: #fff;
    box-sizing: border-box;

    @media ${ device.desktop } {
      padding: 20px;
      border-radius: 10px;
      box-shadow: #ddd 3px 3px 6px;
      min-width: 620px;
      max-width: 620px;
      text-align: left;
    }

    &.sidebar {
      min-width: 280px;
      max-width: 280px;

      @media ${ device.desktop } {
        margin-left: 20px;
        min-width: 320px;
        max-width: 320px;
      }
    }
  }
`;

const H2 = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
  min-width: 280px;
  flex: 1;
  
  @media ${ device.desktop } {
    font-size: 24px;
    text-align: left;
    line-height: 28px;
    max-width: unset;
    min-width: unset;
    margin-bottom: 50px;
  }

  > span {
    display: block;
    font-weight: normal;
    font-size: 18px;
  }
`

const H3 = styled.h3`
  font-size: 18px;
  color: #000;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
  min-width: 280px;
  max-width: 280px;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    font-size: 24px;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }
`

const List = styled.ul`
  list-style: inside none;
  min-width: 280px;
  max-width: 280px;
  padding: 0px;
  margin: 0px;
  flex: 1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: #B8B8B8 1px solid;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0px -20px;
    padding: 0px 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`

const ListItem = styled.li`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #000;
  font-size: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  > span {
    flex: 1;

    &:nth-child(1) {
      max-width: 30px;
    }

    &:nth-child(2) {
      > span {
        display: block;
        font-size: 12px;
        color: #939598;
      }
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
`

const ImageContainer = styled.img`
  width: 100px;
  height: auto;
`

const Paragraph = styled.p`  
  min-width: 280px;
  padding: 0px;
  margin: 0px;
  color: #7D7D7D;
`

const Stepper = styled.div`
  min-width: 280px;
  max-width: 280px;
  margin-bottom: 25px;
  
  @media ${ device.desktop } {
    margin-bottom: 50px;
  }
`

const StepperItem = styled.div`
  background: url('${ UncheckedIcon }') no-repeat 0px 5px;
  min-width: 280px;
  max-width: 280px;
  padding-left: 40px;
  box-sizing: border-box;
  position: relative;
  padding-top: 4px;
  background-size: 20px;
  padding-bottom: 34px;
  font-size: 16px;
  font-weight: bold;
  
  @media ${ device.desktop } {
    font-size: 20px;
    padding-top: 0px;
    padding-bottom: 44px;
  }

  &.active {
    background-image: url('${ CheckedIcon }');
    background-size: 20px;
    padding-bottom: 9px;
    color: #3ABE71;
    font-weight: bold;

    > span {
      color: #949494;
      font-weight: normal;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 9px;
    top: 28px;
    border-left: #E2E2E2 1px solid;
    height: 34px;
    width: 1px;

    @media ${ device.desktop } {
      height: 46px;
    }
  }

  &:last-child {
    &:before {
      display: none;
    }
  }

  > span {
    display: block;
    font-size: 14px;
  }
`

const ProgressBar = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 280px;
  min-width: 280px;
  list-style: inside none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 20px;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin-bottom: 50px;
  }
`

const ProgressBarItem = styled.li`
  background: #B1B1B1;
  flex: 1;
  max-width: 24%;
  min-width: 24%;
  min-height: 5px;
  max-height: 5px;
  border-radius: 2px;
  padding: 0px;
  margin: 0px;

  &.active {
    background: #3ABE71;
  }

  @media ${ device.desktop } {
    max-height: 6px;
    min-height: 6px;
    border-radius: 4px;
  }
`

const DeliveryBox = styled.div`
  flex: 1;
  margin-bottom: 30px;
  padding: 15px 0px;
  box-sizing: border-box;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0px -20px;
    padding: 0px 20px;
    margin-bottom: 30px;
  }

  > h3 {
    margin-bottom: 10px;
  }

  @media ${ device.mobile } {
    display: block;
  }

  &.only-mobile {
    @media ${ device.desktop } {
      display: none;
    }
  }

  &.only-desktop {
    @media ${ device.mobile } {
      display: none;
    }
  }
`

const Summary = styled.div`
  min-width: 280px;
  text-align: right;
  flex: 1;
  margin-bottom: 15px;
  min-width: 100%;
  padding-bottom: 20px;

  @media ${ device.desktop } {
    max-width: unset;
    min-width: unset;
    margin: 0px -20px;
    padding: 0px 20px;
    padding-bottom: 20px;
  }
 
  > p {
    max-width: 280px;
    margin: 0px auto;

    &.date {
      color: #757575;
    }

    &:nth-child(2) {
      margin-bottom: 15px;
    }

    > strong {
      color: #000;
    }
  }
`

const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: #B8B8B8 1px solid;

  @media ${ device.desktop } {
    margin: 0px -20px;
  }

  > p {
    margin-bottom: 15px;
  }

  > button {
    font-size: 20px;
    padding: 8px 15px;
    box-sizing: border-box;
    max-width: 280px;
    min-width: 280px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 7px;
    font-weight: bold;
  }
`

const Button = styled.button`
  background: ${ props => props.disabled ? '#999' : '#000'};
`

const Block = styled.div`
  flex: 1;

  @media ${ device.desktop } {
    max-width: 200px;
    min-width: 200px;
  }
`

const SelectedCardContainer = styled.p`
  margin: 0px;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: 12px;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: 14px;
      font-weight: normal;
    }

    > img {
      margin-right: 5px;
    }

    > a {
      margin-left: 5px;

      > img {
        width: 15px;
        height: auto;
      }
    }
  }
`

const SelectedCard = ({ brand, number }) => {
  return <SelectedCardContainer>
    <span>
      { brand && number ? <>
        <img src={getCardBrandImageById(brand)} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

        { number }
      </>: '' }
    </span>
  </SelectedCardContainer>
}

function DeliveryReceipt(props) {
  const {
    userName,
    cpf,
    cardBrand,
    cardNumber,
    useCpf,
    status,
    orderDate,
    products,
    handleFinishOrder,
    notification,
    deliveryAddress,
    handleStatus,
    cashChange
  } = props

  const status0 = notification && notification.statuses && notification.statuses[0] && (notification.statuses[0].active || notification.statuses[0].time)
  const status1 = notification && notification.statuses && notification.statuses[1] && (notification.statuses[1].active || notification.statuses[1].time)
  const status2 = notification && notification.statuses && notification.statuses[2] && (notification.statuses[2].active || notification.statuses[2].time)
  const status3 = notification && notification.statuses && notification.statuses[3] && (notification.statuses[3].active || notification.statuses[3].time)

  const statusDate0 = notification && notification.statuses && notification.statuses[0] && notification.statuses[0].time
  const statusDate1 = notification && notification.statuses && notification.statuses[1] && notification.statuses[1].time
  const statusDate2 = notification && notification.statuses && notification.statuses[2] && notification.statuses[2].time
  const statusDate3 = notification && notification.statuses && notification.statuses[3] && notification.statuses[3].time

  useEffect(() => {
    handleStatus(1)

    if (status3) {
      handleStatus(2)
    }
  }, [handleStatus, status0, status1, status2, status3])

  const DeliveryFeeText = () => (
    <Paragraph>
      {
        products.summary.deliveryFee ?
        <>Taxa de entrega: <span>{`${numberToCurrency(products.summary.deliveryFee)}`}</span></> :
        <>Taxa de entrega: <span>Grátis</span></>
      }
    </Paragraph>
  )

  const DiscountText = () => (
    <Paragraph>
      {
        products.summary.discount ?
        <>Desconto: <span>{`${numberToCurrency(products.summary.discount)}`}</span></> :
        <></>
      }
    </Paragraph>
  )

  return <Wrapper>
    { status === null && <ImageContainer src={LoadingGIF} alt={`Carregando…`} /> }

    { status && deliveryAddress && deliveryAddress.address && <>
      <Block>
        <H2>
          { userName }, <br />
          seu pedido foi confirmado!
          <span>{ orderDate }</span>
        </H2>

        <H3 className='only-mobile'>Status do Pedido</H3>

        <ProgressBar>
          <ProgressBarItem className={status0 ? 'active' : ''} />
          <ProgressBarItem className={status1 ? 'active' : ''} />
          <ProgressBarItem className={status2 ? 'active' : ''} />
          <ProgressBarItem className={status3 ? 'active' : ''} />
        </ProgressBar>

        <Stepper>
          <StepperItem className={status0 ? 'active' : ''}>
            Recebido com sucesso
            { status0 && <span>{ statusDate0 }</span> }
          </StepperItem>
          <StepperItem className={status1 ? 'active' : ''}>
            Em preparo
            { status1 && <span>{ statusDate1 }</span> }
          </StepperItem>
          <StepperItem className={status2 ? 'active' : ''}>
            Saiu para entrega
            { status2 && <span>{ statusDate2 }</span> }
          </StepperItem>
          <StepperItem className={status3 ? 'active' : ''}>
            Chegou! Bom apetite!
            { status3 && <span>{ statusDate3 }</span> }
          </StepperItem> 
        </Stepper>
      </Block>

      <Block className='sidebar'>
        <DeliveryBox className='only-desktop'>
          <H3>Local de Entrega</H3>

          <Paragraph>{ deliveryAddress.address }</Paragraph>
        </DeliveryBox>

        <H3>Resumo do Pedido</H3>

        <List>
          {
            products && products.list && products.list.map((item, index) => {
              const {
                Quantidade: qty,
                Produto: name,
                Valor: value,
                Descricao: description
              } = item

              const key = `checkout-listitem-${index}-${(new Date()).getTime()}`

              return <ListItem key={key}>
                <span>{ `${qty}x` }</span>

                <span name="info">
                  { name }

                  <span>  
                    { description }
                  </span>
                </span>

                <span name="info">{`${numberToCurrency(value)}`}</span>
              </ListItem>
            })
          }
        </List>

        { products.summary && <Summary>
          <Paragraph>Subtotal <span>{`${numberToCurrency(products.summary.subtotal)}`}</span></Paragraph>
          <DeliveryFeeText />
          <DiscountText />
          <Paragraph><strong>TOTAL: {`${numberToCurrency(products.summary.total)}`}</strong></Paragraph>
        </Summary> }

        <DeliveryBox className='only-mobile'>
          <H3>Local de Entrega</H3>

          <Paragraph>{ deliveryAddress.address }</Paragraph>
        </DeliveryBox>

        <Footer>
          { useCpf && <Paragraph>CPF na nota: { formatCpf(cpf) }</Paragraph> }

          { cashChange && <Paragraph>Troco para: { `${numberToCurrency(cashChange)}` }</Paragraph> }

          <SelectedCard brand={cardBrand || 1} number={cardNumber} />

          <Button disabled={status !== 2 ? true : false} onClick={() => {
            handleFinishOrder()
          }}>Recebi meu pedido</Button>
        </Footer>
      </Block>
    </> }
  </Wrapper>
}

export default DeliveryReceipt
