import styled from 'styled-components'
import PropTypes from 'prop-types';
import { device } from '../../constants/responsive';

const Box = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: ${props => props.padding};
  width: ${props => props.width};
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.16);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.16);

  @media ${device.desktop} {
    border-radius: 10px;
  }
`

Box.propTypes = {
    padding: PropTypes.string,
    width: PropTypes.string
}

Box.defaultProps = {
    padding: '0px',
    width: '100%'
}

export default Box;
