import React from 'react';
import styled from 'styled-components';

const MODAL_ANIM_TIME = 300;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 26;
    transition: transform ${MODAL_ANIM_TIME}ms;

    &.appearing {
        opacity: 1;
        transform: translateY(0);
    }

    &.visible {
        opacity: 1;
        transform: translateY(0);
    }

    &.disappearing {
        opacity: 1;
        transform: translateY(100%);
    }

    &.hidden {
        opacity: 0;
        transform: translateY(100%);
    }
`

class AnimatedWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            animating: false,
            visible: props.visible || false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
            if (this.props.visible) {
                this.setState({animating: true});
                setTimeout(this.didAppear, MODAL_ANIM_TIME)
            } else {
                this.setState({animating: true})
                setTimeout(this.didDisappear, MODAL_ANIM_TIME)
            }
        }
    }

    didAppear = () => {        
        this.setState({animating: false, visible: true});
        if (this.props.onAppear) {
            this.props.onAppear();
        }
    }

    didDisappear = () => {
        this.setState({animating: false, visible: false});
        if (this.props.onDisappear) {
            this.props.onDisappear();
        }
    }

    render() {

        let currentClass = ''
        const { animating, visible } = this.state;
        if (!visible && animating) currentClass = 'appearing';
        if (visible && !animating) currentClass = 'visible';
        if (visible && animating) currentClass = 'disappearing';
        if (!visible && !animating) currentClass = 'hidden';

        return(
            <Wrapper className={currentClass}>
                {this.props.children}
            </Wrapper>
        )
    }
}

export default AnimatedWrapper;