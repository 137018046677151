import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    align-items: flex-start;
    &:hover { 
        background-color: ${props => props.theme.colors.lightGrey};
    }
    padding: 15px 0;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

const Title = styled.span`
    color: ${props => props.theme.colors.textBold};
    font-size: 14px;
    font-weight: bold;
`

const Address = styled.span`
    color: ${props => props.theme.colors.textPrimary};
    font-size: 10px;
`

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.lightGrey};
`

export const StoreCard = ({store, onClick}) => {

    return (
        <>
            <Wrapper onClick={onClick}>
                <TitleWrapper>
                    <Title>{store.name}</Title>
                    <Title>{store.distance && `${store.distance} km`}</Title>
                </TitleWrapper>
                <Address>
                    {store.address}
                </Address>
            </Wrapper>
            <Divider />
        </>
    )
}