import React from 'react';
import styled from 'styled-components';
import { Icon } from '.';
import { device } from '../../constants/responsive';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.3s;
    padding: 0 10px;
    z-index: 102;

    ${props => props.fixed && `
        position: fixed;
        z-index: 10000;
    `}

    ${props => props.show && `
        height: 65px;
        @media ${device.desktop} {
            height: 45px;
        }
    `}

    ${props => props.type === 'error' && `
        background-color: ${props.theme.colors.error};
    `}

    ${props => props.type === 'success' && `
        background-color: ${props.theme.colors.success};
    `}
`

const Text = styled.span`
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;

    @media ${device.desktop} {
        font-size: ${props => props.fixed ? `16px` : `10px`};
        padding-left: 5px;
    }
`

const iconNames = {
    error: "error"
}

class Notification extends React.Component {

    componentDidUpdate(prevProps) {
        if (prevProps.text !== this.props.text && !!this.props.text && this.props.timeout) {
            setTimeout(this.handleDismiss, this.props.timeout * 1000);
        }
    }

    handleDismiss = () => {
        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
    }

    render() {
        const {text, fixed, type} = this.props;
        const iconWidth = isMobile ? "32px" : "16px";
        const iconHeight = isMobile ? "32px" : "16px";
        return (
            <Wrapper fixed={fixed} show={!!text} onClick={this.handleDismiss} type={type}>
                {!!text && (
                    <>
                        <Icon name={iconNames[type]} width={iconWidth} height={iconHeight} />
                        <Text fixed={fixed}>
                            {text}
                        </Text>
                    </>
                )}
            </Wrapper>
        )
    }
}

Notification.defaultProps = {
    timeout: 5
}

export default Notification;