import styled from 'styled-components'
import PropTypes from 'prop-types';

import { device } from '../../constants/responsive';

const MenuProductImage = styled.div`
  border: 0;
  width: 100px;
  height: 100px;
  background-image:url(${props => props.image});
  background-size: cover;
  background-position: center;

  @media ${device.desktop} {
    width: 140px;
    height: 140px;
  }
`
MenuProductImage.propTypes = {
    image: PropTypes.string.isRequired
}

export default MenuProductImage;
