import React from 'react';
import styled from 'styled-components';
import { Form } from '../../common/form';
import { TextInput } from '../../common';
import { notEmpty, isCpf, isFullDate, isPartialDate } from '../../common/form/validationFunctions';
import { BrandContainer } from './'
import { getCardBrandImageById } from '../../../utils/';
import { CARD_BRANDS_IDS } from '../../../constants/payment';
import onlyNumbers from '../../../utils/onlyNumber';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
`

const SingleColumnContainer = styled.div`
    width: 100%;
    padding-bottom: 20px;
`

const DoubleColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
`

const FirstColumn = styled.div`
    padding-right: 10px;
`

const SecondColumn = styled.div`
    padding-left: 10px;
`   

class CardDetailForm extends React.Component {

    constructor(props) {
        super(props);
        this.formInputs = this.setupInputs();
    }

    isTicket = () => {
        return this.props.brandId === CARD_BRANDS_IDS.TICKET_VR || this.props.brandId === CARD_BRANDS_IDS.TICKET_VA;
    }

    renderDateInput = (formInputProps) => {
        if (this.isTicket()) {
            return (<TextInput {...formInputProps} mask={"99/99/9999"} name="cardDate" placeholder="Data nasc." center />)
        } else {
            return (<TextInput {...formInputProps} mask={"99/99"} name="cardDate" placeholder="Validade" center />)
        }
    }

    validateDate = (date) => {
        if (this.isTicket()) {
            return isFullDate(date)
        } else {
            return isPartialDate(date)
        }
    }

    setupInputs = () => [
        {
            id: 'cardName',
            validations: [notEmpty],
            Component: (formInputProps) => (
                <TextInput {...formInputProps} name="cardName" placeholder="Nome igual no cartão" center />
            )
        },
        {
            id: 'cardDate',
            beforeSubmit: onlyNumbers,
            validations: [notEmpty, this.validateDate],
            Component: this.renderDateInput
        },
        {
            id: 'cardCVV',
            validations: [notEmpty],
            Component: (formInputProps) => (
                <TextInput {...formInputProps} type="number" name="cardCVV" placeholder="CVV" center 
                inputProps={{
                    maxLength: 4
                }} />
            )
        },
        {
            id: 'cardCpf',
            validations: [notEmpty, isCpf],
            beforeSubmit: onlyNumbers,
            Component: (formInputProps) => (
                <TextInput {...formInputProps} mask={"999.999.999-99"} name="cardCpf" placeholder="CPF" center />
            )
        },
    ]

    render() {
        const brandImage = this.props.brandId && getCardBrandImageById(this.props.brandId);
        return (
            <Form formInputs={this.formInputs} onSubmit={this.props.onSubmit} ref={this.props.formRef}>
                {(inputs) => (
                    <Wrapper>
                        <BrandContainer brandImage={brandImage} text={this.props.cardDigits} />
                        <SingleColumnContainer>
                            <inputs.cardName />
                        </SingleColumnContainer>
                        <DoubleColumnContainer>
                            <FirstColumn>
                                <inputs.cardDate />
                            </FirstColumn>
                            <SecondColumn>
                                <inputs.cardCVV />
                            </SecondColumn>
                        </DoubleColumnContainer>
                        <SingleColumnContainer>
                            <inputs.cardCpf />
                        </SingleColumnContainer>
                    </Wrapper>
                )}
            </Form>    
        )
    }
}

export default CardDetailForm;