import Product from '../models/product';
import mapAPIAddon from './addon';

const mapAPIProduct = (apiProduct: any) : Product => {
    return {
        id: apiProduct["Id"],
        name: apiProduct["Nome"],
        image: apiProduct["Imagem"],
        description: apiProduct["Descricao"],
        price: apiProduct["Valor"],
        promotionalPrice: apiProduct['PromocaoInativada'] ? null : apiProduct["ValorPromocional"],
        addons: apiProduct["Acompanhamentos"].map( (addon: any) => mapAPIAddon(addon))
    }
}

export default mapAPIProduct;