import React from 'react';
import styled from 'styled-components';
import CheckedIcon from '../../assets/icons/icon_check.svg';

const Checkmark = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    height: 100%;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border: 1px solid ${props => props.theme.colors.mediumGrey}

    ${props => props.size && props.size === 'sm' && `
        width: 14px;
        height: 14px;
    `}
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Content = styled.div`
    margin-left: 24px;
`

const Container = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;

    & input { 
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ ${Checkmark} {
            background-color: ${props => props.theme.colors.lightGreen};
            border-color: ${props => props.theme.colors.lightGreen};
            background-image: url(${CheckedIcon});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 80%;

            &.primary {
                background-color: ${props => props.theme.colors.primary};
                border-color: ${props => props.theme.colors.primary};
            }
        }
    }
`

class Checkbox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }


    componentDidMount() {
        // Preload checked icon
        new Image().src = CheckedIcon
    }

    click = (e) => {
        if (this.ref) {
            this.ref.current.click();
        }
    }

    handleChange = (e) => {
        const value = e.target.checked;
        if (this.props.onCheck) {
            this.props.onCheck(value);
        }
    }

    render() {
        return (
        <Container>
            <Wrapper>
                <input ref={this.ref} name={this.props.name} disabled={this.props.disabled} type={this.props.type || "checkbox"} defaultChecked={this.props.checked} onChange={this.handleChange} />
                <Checkmark size={this.props.size} className={this.props.primary ? 'primary' : ''} />
                <Content>
                    {this.props.children}
                </Content>
            </Wrapper>
        </Container>
        )
    }
}

export default Checkbox;