import React from 'react'
import styled from 'styled-components'

import sources from './index'

const Img = styled.img`
    width: 35px
    height: auto
    max-height: 22px
    margin-right: 12px;
`

const Flags = (props) => {
    const {
        country
    } = props

    if (country) {
        return <Img src={sources[country]} />
    }
}

export default Flags
