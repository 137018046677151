import React from 'react'
import styled from 'styled-components'

import { SelectInput, Loading } from '../common/'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Title = styled.span`
    color: ${props => props.theme.colors.textBold};
    font-size: 18px;
    font-weight: bold;
`

const SelectWrapper = styled.div`
    height: 40px;
    width: 100%;
    text-align: left;
`

const LocationBlock = styled.div`
    font-size: 14px;
    text-align: left;
`

export const CitySelect = ({availableCities, loading, onSelect}) => {

    const options = availableCities.map(city => ({
        name: city.name,
        value: city.id
    }))

    const handleSelect = (index) => {
        if (onSelect && options[index]) {
            onSelect(options[index].value)
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <Wrapper>
            <Title>Selecione sua cidade</Title>
            <SelectWrapper>
                <SelectInput 
                    options={options}  
                    selected={-1}
                    onSelect={handleSelect} 
                    placeholder="*Campo obrigatório" 
                />
            </SelectWrapper>
            <LocationBlock>
                Ou habilite sua localização para encontrarmos o restaurante mais perto de você
            </LocationBlock>
        </Wrapper>
    )
}