import React from 'react';
import styled from 'styled-components'; 
import { connect } from 'react-redux';

import { Icon } from '../common'
import { numberToCurrency } from '../../utils';
import { CartBadge } from '.';

const CartBarContainer = styled.button`
    width: 100%;
    height: 65px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    background: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
`

const BadgeWrapper = styled.div`
    position: absolute;
    top: 5px;
    left: 35px;
    transition: all 0.5s;

    ${props => props.animating && `
        transform: scale(1.2);
        transition: none;
    `}
`

const IconWrapper = styled.div`
    flex: 1;
    text-align: left;
`

const TotalWrapper = styled.div`
    flex: 1;
    text-align: right;
`

const MyOrderWrapper = styled.div`
    position: absolute;
`

class CartBar extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            animating: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.animating) {
            setTimeout(() => this.setState({animating: false}), 1)
        }

        if (prevProps.totalItems !== this.props.totalItems) {
            this.setState({animating: true})
        }
    }

    render() {
        const {items, totalValue, totalItems, onClick} = this.props;
        if (items && items.length > 0) {
            return (
                <CartBarContainer onClick={onClick}>
                    <IconWrapper><Icon name="cart" width={"40px"} height={"40px"} /></IconWrapper>
                    <BadgeWrapper animating={this.state.animating}>
                        <CartBadge number={totalItems} />
                    </BadgeWrapper>
                    <MyOrderWrapper>MEU PEDIDO</MyOrderWrapper>
                    <TotalWrapper>{numberToCurrency(totalValue)}</TotalWrapper>
                </CartBarContainer>
            )  
        }
        return null;
    }
}

const mapStateToProps = state => {
    const totalItems = state.cart.items.reduce( (total, item) => total + item.quantity, 0);
    return { 
        items: state.cart.items,
        totalValue: state.cart.total,
        totalItems
    };
  };

export default connect(mapStateToProps, null)(CartBar);