// global-style.js
import { createGlobalStyle } from 'styled-components';
import reboot from 'styled-reboot';

import { THEME } from '../src/config';
 
const options = {
  fontFamilyBase: THEME.fontFamily,
  fontSizeBase: THEME.fontSize,
  bodyColor: THEME.colors.textPrimary,
  bodyBg: THEME.colors.background
};
 
const rebootCss = reboot(options);
 
const GlobalStyle = createGlobalStyle`
  ${rebootCss}

  html, body {
    height: 100%;
    background: #fff;
  }

  #root {
    min-width: 320px;
    min-height: 100%;
    display: flex;
  }
`
 
export default GlobalStyle;