import React from 'react'
import styled from 'styled-components'

import StoreLogo from '../../../components/common/StoreLogo'
import { device } from '../../../constants/responsive'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-bottom: #B8B8B8 1px solid;
  box-shadow: #888888 0px 0px 1px;

  @media ${ device.mobile } {
    max-height: 70px;
    min-height: 70px;
  }

  @media ${ device.tablet } {
    max-height: 120px;
    min-height: 120px;
  }

  @media ${ device.desktop } {
    max-height: 165px;
    min-height: 165px;
  }
`

const LogoContainer = styled.div`
  flex: 1;
  padding: 0px 40px;
  max-width: 180px;
  box-sizing: border-box;

  @media ${ device.mobile } {
    max-width: 160px;
  }

  > img {
    @media ${ device.mobile } {
      max-width: 80px;
    }
  }
`

function HeaderBox(props) {
  return (
    <Wrapper>
      <LogoContainer>
        <StoreLogo />
      </LogoContainer>
    </Wrapper>
  )
}

export default HeaderBox
