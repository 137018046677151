import history from '../../history';

export const actionTypes = {
    SET_NAVIGATING: 'SET_NAVIGATING',
}

export const goBack = (intendedRoute) => 
async (dispatch) => {
  await dispatch(setNavigating(true));
  setTimeout(() => {
    if(history.length > 1) {
      history.goBack();
    } else {
      history.push(intendedRoute);
    }
  }, 10);
}

export const navigateTo = (route) => async (dispatch) => {
  await dispatch(setNavigating(true))

  setTimeout(async () => {
      history.push(route)
      await dispatch(setNavigating(false))
  }, 10);
}

export const setNavigating = (navigating) => {
  if (navigating) {
    window.scrollTo(0,0)
    document.getElementById('pageLoader').style.display = 'flex'

    return {
      type: actionTypes.SET_NAVIGATING,
      payload: navigating
    }
  }
  
  document.getElementById('pageLoader').style.display = 'none'
  
  return {
    type: actionTypes.SET_NAVIGATING,
    payload: navigating
  }
}