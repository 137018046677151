import React from 'react';
import styled from 'styled-components';
import { HeaderLoginButton } from '.';
import { device } from '../../constants/responsive';
import { Desktop } from '../common/ResponsiveComponent';
import Button from '../common/Button'
import { MULTI_STORE } from '../../config';

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    background-image: url(${props => props.theme.headerImage});
    background-size: cover;

    @media ${device.mobile} {
        height: 195px;
    }

    @media ${device.desktop} {
        max-height: 320px;
        min-height: 195px;
        height: calc(100vw * 0.115);
    }
`

const SelectStoreButtonWrapper = styled.div`
    position: absolute;
    left: 10px;    
    top: 0;
    z-index: 10;
    @media ${device.desktop} {
        left: 50%;
        transform: translateX(-50%);
        top: 0;
    }
`

const SelectStoreButton = styled(Button)`
    border: none;
    border-radius: 0 0 5px 5px;
`

const LoginButtonWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 0;
    transform: translateY(25%);
    @media ${device.desktop} {
        right: 10%;
        top: 50%;
        transform: translateY(-100%);
    }
`

const Overlay = styled.div`
    background: rgba(0,0,0,0.3);
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

const HeaderImage = (props) => (
    <Wrapper {...props}>
        {MULTI_STORE === "true" && (
            <SelectStoreButtonWrapper>
                <SelectStoreButton className="default" onClick={props.handleSelectStore}>
                    Mudar de restaurante
                </SelectStoreButton>
            </SelectStoreButtonWrapper>
        )}
        <Desktop>
            <Overlay />
        </Desktop>
        <LoginButtonWrapper>
            <HeaderLoginButton />
        </LoginButtonWrapper>
    </Wrapper>
);

export default HeaderImage;
  
