import React from 'react';
import { connect } from "react-redux";
import Notification from "./Notification";
import { clearNotification } from '../../redux/actions/ui';

const DEFAULT_TIMEOUT = 5;

class NotificationCenter extends React.Component {
    handleDismiss = () => {
        this.props.clearNotification();
    }

    render() {
        return (
            <Notification 
                show={!!this.props.text} 
                text={this.props.text} fixed 
                timeout={this.props.timeout} 
                onDismiss={this.handleDismiss} 
                type={this.props.type}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        text: state.ui.notification.text,
        type: state.ui.notification.type,
        timeout: state.ui.notification.timeout || DEFAULT_TIMEOUT
    }
}

export default connect(
    mapStateToProps,
    { clearNotification }
)(NotificationCenter)