import React, {
  useState,
  useEffect
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import LoadingGIF from '../../../assets/loading.gif'

import { getCardBrandImageById, numberToCurrency } from '../../../utils'

import {
  fetchFridgeCheckoutStatus,
  fetchFridgeCheckoutData
} from '../../../redux/actions/fridge'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0px;
  padding-top: 40px;
`;

const H2 = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
  min-width: 280px;
  flex: 1;
  max-height: 50px;
`

const H3 = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: #41BAC8;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
  min-width: 280px;
  flex: none;
  padding: 10px 5px;
  background: #F7F7F7;
  border-radius: 5px;
  border: #DDD 1px solid;
  width: 100%;
  max-width: 300px;
`

const H4 = styled.h4`
  font-size: 18px;
  color: #000000;
  text-align: left;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  min-width: 280px;
  flex: 1;
  max-height: 20px;
`

const List = styled.ul`
  list-style: inside none;
  min-width: 280px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 20px;
  flex: 1;
`

const ListItem = styled.li`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #000;
  font-size: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  > span {
    flex: 1;

    &:nth-child(1) {
      max-width: 30px;
    }

    &:nth-child(2) {
      > span {
        display: block;
        font-size: 12px;
        color: #939598;
      }
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
`

const ImageContainer = styled.img`
  width: 100px;
  height: auto;
`

const Paragraph = styled.p`  
  min-width: 280px;
  padding: 0px;
  margin: 0px;
  color: #7D7D7D;
`

const Summary = styled.div`
  min-width: 280px;
  text-align: right;
  flex: 1;
  margin-bottom: 15px;
  border-top: #B8B8B8 1px solid;
  min-width: 100%;
  padding-top: 15px;
  max-height: 110px;
 
  > p {
    max-width: 280px;
    margin: 0px auto;

    &:nth-child(2) {
      margin-bottom: 15px;
    }

    > strong {
      color: #000;
    }
  }
`

const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  border-top: #B8B8B8 1px solid;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
`

const SelectedCardContainer = styled.p`
  margin: 0px;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: 12px;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: 14px;
      font-weight: normal;
    }

    > img {
      margin-right: 5px;
    }

    > a {
      margin-left: 5px;

      > img {
        width: 15px;
        height: auto;
      }
    }
  }
`

const SelectedCard = ({ brand, number }) => {
  return <SelectedCardContainer>
    <span>
      { brand && number ? <>
        <img src={getCardBrandImageById(brand)} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

        { number }
      </>: '' }
    </span>
  </SelectedCardContainer>
}

function Cards(props) {
  const {
    userName,
    storeName,
    cpf,
    cardBrand,
    cardNumber,
    useCpf,
    fridgeId
  } = props

  const [status, setStatus] = useState(null)
  const [products, setProducts] = useState([])

  useEffect(() => {
    async function getStatus() {
      const result = await props.fetchFridgeCheckoutStatus(fridgeId)
    
      if (result.checkoutId) {
        const products = await props.fetchFridgeCheckoutData(result.checkoutId)

        setProducts(products)
      }


      if (result.statusId === 9) { 
        setStatus(true)

        return
      }

      setStatus(false)
    }

    getStatus()
  }, [props, fridgeId])

  return <Wrapper>
    { status === null && <ImageContainer src={LoadingGIF} /> }

    { status === true && <H2>
      { userName }, <br />
      compra realizada com sucesso!
    </H2> }

    { status === false && <H2>
      { userName }, <br />
      o pagamento não foi realizado! :(
    </H2> }

    { status === true && <H3>{ storeName }</H3> }

    { status === true && <H4>Resumo do pedido</H4> }

    { status === true && <List>
      {
        products.list.map((item, index) => {
          const {
            Quantidade: qty,
            Produto: name,
            Valor: value,
            Descricao: description
          } = item

          const key = `checkout-listitem-${index}-${(new Date()).getTime()}`

          return <ListItem key={key}>
            <span>{ `${qty}x` }</span>

            <span name="info">
              { name }

              <span>
                { description }
              </span>
            </span>

            <span name="info">{`${numberToCurrency(value)}`}</span>
          </ListItem>
        })
      }
    </List> }

    { status === true && <Summary>
      <Paragraph>Subtotal <span>{`${numberToCurrency(products.summary.subtotal)}`}</span></Paragraph>
      <Paragraph>Taxa de serviço: <span>{`${numberToCurrency(products.summary.deliveryFee)}`}</span></Paragraph>
      <Paragraph><strong>TOTAL: {`${numberToCurrency(products.summary.total)}`}</strong></Paragraph>
    </Summary> }

    { status === true && <Footer>
      { useCpf && <Paragraph>CPF na nota: { cpf }</Paragraph> }

      <SelectedCard brand={cardBrand || 1} number={cardNumber || '**** 1234'} />
    </Footer> }
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeName: state.store && state.store.name,
    userName: state.user && state.user.name,
    cpf: state.user && state.user.cpf,
    useCpf: state.cart.useCpf,
    cardBrand: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.brandId,
    cardNumber: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.data,
    checkout: state.fridge && state.fridge.checkout
  }
}

export default connect(
  mapStateToProps, {
    fetchFridgeCheckoutStatus,
    fetchFridgeCheckoutData,
  }
)(withRouter(Cards))
