import React from 'react';
import styled from 'styled-components';

import {ReactComponent as IconDeliverySvg} from '../../assets/icons/icon_delivery.svg'
import {ReactComponent as IconMinValueSvg} from '../../assets/icons/icon_min_value.svg'
import {ReactComponent as IconStoreSvg} from '../../assets/icons/icon_store.svg'
import {ReactComponent as IconTimeSvg} from '../../assets/icons/icon_time.svg'
import {ReactComponent as IconInfoSvg} from '../../assets/icons/icon_info.svg'
import {ReactComponent as IconLoginSvg} from '../../assets/icons/icon_login.svg'
import {ReactComponent as IconLoginDarkSvg} from '../../assets/icons/icon_login_dark.svg'
import {ReactComponent as IconEditSvg} from '../../assets/icons/icon_edit.svg'
import {ReactComponent as IconDeleteSvg} from '../../assets/icons/icon_delete.svg'
import {ReactComponent as IconSearchSvg} from '../../assets/icons/icon_search.svg'
import {ReactComponent as IconMoreInfoSvg} from '../../assets/icons/icon_more_info.svg'
import {ReactComponent as IconLessInfoSvg} from '../../assets/icons/icon_less_info.svg'
import {ReactComponent as IconCartSvg} from '../../assets/icons/icon_cart.svg'
import {ReactComponent as IconCloseSvg} from '../../assets/icons/icon_close.svg'
import {ReactComponent as IconPlusSvg} from '../../assets/icons/icon_plus.svg'
import {ReactComponent as IconMinusSvg} from '../../assets/icons/icon_minus.svg'
import {ReactComponent as IconMailSvg} from '../../assets/icons/icon_mail.svg'
import {ReactComponent as IconFbSvg} from '../../assets/icons/icon_fb.svg'
import {ReactComponent as IconGoogleSvg} from '../../assets/icons/icon_google.svg'
import {ReactComponent as IconTagSvg} from '../../assets/icons/icon_tag.svg'
import {ReactComponent as IconCheckSvg} from '../../assets/icons/icon_check.svg'
import {ReactComponent as IconErrorSvg} from '../../assets/icons/icon_error.svg'
import {ReactComponent as IconArrowLeftSvg} from '../../assets/icons/icon_arrow_left.svg'
import {ReactComponent as IconArrowRightSvg} from '../../assets/icons/icon_arrow_right.svg'
import {ReactComponent as IconCheckoutTotalSvg} from '../../assets/icons/icon_checkout_total.svg'
import {ReactComponent as IconCheckoutPaymentSvg} from '../../assets/icons/icon_checkout_payment.svg'
import {ReactComponent as IconCheckoutAddressSvg} from '../../assets/icons/icon_checkout_address.svg'
import {ReactComponent as IconCheckoutTimeSvg} from '../../assets/icons/icon_checkout_time.svg'
import {ReactComponent as IconCreditCardSvg} from '../../assets/icons/icon_credit_card.svg'

const icons = {
    delivery: IconDeliverySvg,
    minValue: IconMinValueSvg,
    store: IconStoreSvg,
    time: IconTimeSvg,
    info: IconInfoSvg,
    login: IconLoginSvg,
    loginDark: IconLoginDarkSvg,
    edit: IconEditSvg,
    delete: IconDeleteSvg,
    search: IconSearchSvg,
    moreInfo: IconMoreInfoSvg,
    lessInfo: IconLessInfoSvg,
    cart: IconCartSvg,
    close: IconCloseSvg,
    plus: IconPlusSvg,
    minus: IconMinusSvg,
    mail: IconMailSvg,
    facebook: IconFbSvg,
    google: IconGoogleSvg,
    tag: IconTagSvg,
    check: IconCheckSvg,
    error: IconErrorSvg,
    arrowLeft: IconArrowLeftSvg,
    arrowRight: IconArrowRightSvg,
    checkoutTotal: IconCheckoutTotalSvg,
    checkoutPayment: IconCheckoutPaymentSvg,
    checkoutAddress: IconCheckoutAddressSvg,
    checkoutTime: IconCheckoutTimeSvg,
    creditCard: IconCreditCardSvg
}

// To-Do: Fix stroke
const IconWraper = styled.div`
    display: inline-block;
    vertical-align: baseline;
    & svg {
        width: ${props => props.width || "auto"};
        height: ${props => props.height || "auto"};
        & path {
            ${props => props.fill && `fill: ${props.theme.colors[props.fill] || props.fill}`};
            ${props => props.stroke && `stroke: ${props.theme.colors[props.stroke] || props.stroke}`};
        }
    }
`

const Icon = ({name, fill, stroke, width, height}) => {
    const Component = icons[name];
    if (!Component) return null;
    return (
        <IconWraper fill={fill} stroke={stroke} width={width} height={height}>
            <Component/>
        </IconWraper>
    )
}

export default Icon;