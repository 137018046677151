import Category from '../models/category';
import mapAPIProduct from './product';

const mapAPICategory = (apiCategory: any) : Category => {
    return {
        name: apiCategory["Nome"],
        products: apiCategory["Produtos"].map( (product: any) => mapAPIProduct(product))
    }
}

export default mapAPICategory;