import React from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";

import { Icon } from '../common';
import { numberToCurrency } from '../../utils';
import { ProductDetailModal } from '../product';
import { updateCartItem, removeCartItem } from '../../redux/actions/cart'

const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    font-size: 15px;
    letter-spacing: -0.01em;
`

const MainLine = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`

const AddonLine = styled.div`
    display: flex;
    font-size: 12px;
    color: ${props => props.theme.colors.textPrimary};
`

const QuantityColumn = styled.div`
    width: 40px;
    color: ${props => props.theme.colors.primary};
`

const NameColumn = styled.div`
    flex-grow: 1;
`

const PriceColumn = styled.div`
    
`

const ActionsColumn = styled.div`
    width: 16px;
`

const ProductNameLabel = styled.span`
    display: block;
    color: ${props => props.theme.colors.textBold};
    padding-bottom: 5px;
    max-width: 165px;
`

const PriceLabel = styled.div`
    color: ${props => props.theme.colors.textBold};
`

const IconWrapper = styled.button`
    background: transparent;
    border: 0;
    width: 16px;
    text-align: center;
    padding: 0;
`

const ActionsWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
`

const ActionIconWrapper = styled.button`
    border: 0;
    width: 16px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0;
`

const FillSpace = styled.div`
    flex-grow: 1;
`

class CartProductCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            actionsOpened: false,
            isModalOpen: false,
        }
    }

    toggleActions = () => {
        this.setState({
            actionsOpened: !this.state.actionsOpened
        })
    }

    handleDeleteItem = () => {
        this.props.removeCartItem(this.props.product)
        this.toggleActions();
    }

    handleOpenModal = () => {
        this.setState({isModalOpen: true});
    }

    handleCloseModal = () => {
        this.setState({isModalOpen: false});
    }

    handleUpdateCartItem = ({quantity, checkedAddons, observation}) => {
        this.props.updateCartItem({
            product: this.props.product, 
            quantity, 
            checkedAddons,
            observation,
            hash: this.props.hash,
        })
        this.toggleActions();
    }

    render() {
        const { product, quantity, total, checkedAddons, observation } = this.props;
        const { actionsOpened, isModalOpen } = this.state;
        return (
            <CardWrapper>
                <MainLine>
                    <QuantityColumn>
                        {quantity}x
                    </QuantityColumn>
                    <NameColumn>
                        <ProductNameLabel>
                            {product.name}
                        </ProductNameLabel>
                    </NameColumn>
                    <PriceColumn>
                        {!actionsOpened && (
                            <PriceLabel>
                                {numberToCurrency(total)}
                            </PriceLabel>
                        )}
                    </PriceColumn>
                    <ActionsColumn>
                    {!actionsOpened && (
                        <IconWrapper onClick={this.toggleActions}>
                            <Icon name="moreInfo" />
                        </IconWrapper>
                    )}
                    </ActionsColumn>
                    {actionsOpened && (
                        <ActionsWrapper>
                            <FillSpace />
                            <ActionIconWrapper onClick={this.handleDeleteItem}>
                                <Icon name="delete" />
                            </ActionIconWrapper>
                            <ActionIconWrapper onClick={this.handleOpenModal}>
                                <Icon fill="primary" stroke="primary" name="edit" />
                            </ActionIconWrapper>
                            <ActionIconWrapper onClick={this.toggleActions}>
                                <Icon name="lessInfo" />
                            </ActionIconWrapper>
                        </ActionsWrapper>
                    )}
                </MainLine>
                {checkedAddons && checkedAddons.map(addon => (
                    addon.items.map(item => {
                        const qty = item.quantity > 1 ? `${item.quantity}x ` : '';
                        return (
                            <AddonLine key={`${addon.name}#${item.name}`}>
                                <QuantityColumn />
                                <NameColumn>
                                    {qty}{item.name}
                                </NameColumn>
                                <PriceColumn>
                                    {/* {item.price ? `+${numberToCurrency(item.price)}` : ''} */}
                                </PriceColumn>
                                <ActionsColumn />
                            </AddonLine>
                        )
                    })
                ))}
                {isModalOpen && (
                    <ProductDetailModal 
                        isOpen={isModalOpen} 
                        onClose={this.handleCloseModal} 
                        onUpdateCartItem={this.handleUpdateCartItem}
                        product={product} 
                        quantity={quantity}
                        checkedAddonItems={checkedAddons}
                        observation={observation}
                        edit={true}
                    />
                )}
            </CardWrapper>
        )
    }
}


export default connect(null, { removeCartItem, updateCartItem })(CartProductCard);