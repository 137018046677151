import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { Icon } from '../common/'
import { isNull } from '../../utils';

import { device } from '../../constants/responsive';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    letter-spacing: -0.01em;    

    @media ${device.mobile} {
        padding-right: 0px;
    }

    @media ${device.desktop} {
        padding-right: 33px;
    }
`

const IconColumn = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;
`

const TextColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const PrimaryColumn = styled.div`
    display: flex;
    align-items: center;
`

const Bullet = styled.div`
    width: 10px;
    height: 10px;
    margin-left: 8px;
    border-radius: 5px;
    background-color: ${props => props.theme.colors[props.type]};
`

const PrimaryLabel = styled.span`
    display: block;
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.colors.textPrimary};

    @media ${device.desktop} {
        font-size: 14px;
    }
`

const SecondaryLabel = styled.span`
    font-size: 10px;
    font-weight: 300;
    color: ${props => props.theme.colors.textPrimary};
    font-style: italic;

    @media ${device.desktop} {
        font-size: 12px;
    }
`

const HeaderInfoSmall = ({primaryText, secondaryText, icon, bulletType}) => (
    <Wrapper>
        <IconColumn>
            {icon && (
                <Icon name={icon} />
            )}
        </IconColumn>
        <TextColumn>
            <PrimaryColumn>
                <PrimaryLabel>{!isNull(primaryText) ? primaryText : (<Skeleton width={60} />)}</PrimaryLabel>
                {bulletType && <Bullet type={bulletType} />}
            </PrimaryColumn>
            <SecondaryLabel>{!isNull(secondaryText) ? secondaryText : (<Skeleton width={60}/>)}</SecondaryLabel>
        </TextColumn>
    </Wrapper>
)

export default HeaderInfoSmall;
