import Store from "../models/store";
import DeliveryPrice from "../models/deliveryPrice";
import mapAPICategory from "./category";
import { DELIVERY_MODALITIES } from "../constants/delivery";

interface Coordinates {
  latitude: number;
  longitude: number;
}

const getAvailableCardBrands = (brands: any) => {
  if (brands["CartoesCreditos"]) {
    if (brands["ValesRefeicao"]) {
      return brands["CartoesCreditos"].concat(brands["ValesRefeicao"]);
    }
    return brands["CartoesCreditos"];
  }
  return [];
};

export const mapAPIStoreInfo = (apiStore: any): Store => {
  return {
    id: apiStore["Id"],
    name: apiStore["Nome"],
    logo: apiStore["Imagem"]["Logo"],
    headerImage: apiStore["Imagem"]["Empresa"],
    isOpen: apiStore["Aberto"],
    openingHours: apiStore["HorarioFuncionamentoTexto"].map(
      (day: any, i: number) => ({
        day: day["Dia"],
        hours: day["Hora"],
        isToday: day["Hoje"],
      })
    ),
    deliveryEstimate: apiStore["EstimativaEntrega"],
    contactInfo: {
      phone: `(${apiStore["Contato"]["DDD"]}) ${apiStore["Contato"][
        "Telefone"
      ].replace(/(\d{4})(\d{4})/, "$1-$2")}`,
      email: apiStore["Contato"]["Email"],
    },
    availableCardBrands: getAvailableCardBrands(apiStore["Bandeiras"]),
    menu: {
      categories: apiStore["Categorias"].map((category: any) =>
        mapAPICategory(category)
      ),
    },
    address: apiStore["Localizacao"] && apiStore["Localizacao"]["Endereco"]
  }
}

export const mapDeliveryPriceInfo = (apiStore: any): DeliveryPrice => {
  const deliveryModality = apiStore["ModalidadesRetirada"].find(
    (modality: any) =>
      modality["ModalidadeRetirada"] === DELIVERY_MODALITIES.DELIVERY
  );
  const pickupModality = apiStore["ModalidadesRetirada"].find(
    (modality: any) =>
      modality["ModalidadeRetirada"] === DELIVERY_MODALITIES.PICKUP || modality["ModalidadeRetirada"] === 1
  );

  const modalities = apiStore["ModalidadesRetirada"].map((mappedItem: any) => {
    return {
      id: mappedItem['ModalidadeRetirada'],
      serviceFee: mappedItem['Valor'],
      minimumValue: mappedItem['ValorMinimoPedido']
    }
  })

  return {
    id: apiStore["Id"],
    name: apiStore["Nome"],
    modalities,
    minimumOrder: deliveryModality
      ? deliveryModality["ValorMinimoPedido"]
      : null,
    deliveryPrice: deliveryModality && deliveryModality["Valor"],
    pickupPrice: pickupModality && pickupModality["Valor"],
  };
};

export const mapClosestStoreRequest = (address: any): any => {
  const body = {} as any;
  body.Localizacao = {
    Latitude: address.latitude,
    Longitude: address.longitude,
  };
  return body;
};

export const mapClosestStoreReponse = (response: any) => {
  return {
    id:
      response.Restaurantes &&
      response.Restaurantes.length > 0 &&
      response.Restaurantes[0].Id,
  };
};

export const mapAvailableCitiesRequest = (coords: Coordinates) => {
  const body = {
    CidadeId: 0,
  } as any;
  if (coords) {
    body.Localizacao = {
      Latitude: coords.latitude,
      Longitude: coords.longitude,
    };
  }
  return body;
};

export const mapAvailableCitiesResponse = (response: any) => {
  return response.Cidades.map((city: any) => ({
    id: city.Id,
    name: city.Nome,
  }));
};

interface AvailableStoreRequestParams {
  city: number;
  coords: Coordinates;
}

export const mapAvailableStoresRequest = ({
  city,
  coords,
}: AvailableStoreRequestParams) => {
  const body = {} as any;
  if (city) {
    body.CidadeId = city;
  }
  if (coords) {
    body.Localizacao = {
      Latitude: coords.latitude,
      Longitude: coords.longitude,
    };
  }
  return body;
};

export const mapAvailableStoresResponse = (response: any) => {
  return response.Clusters.Itens.map((cluster: any) => {
    const store = cluster.Restaurantes[0];
    return {
      id: store.Id,
      name: store.Nome,
      distance: store.Localizacao.Distancia,
      address: store.Localizacao.Endereco,
    };
  });
};
