import React from 'react';
import styled from 'styled-components'
import { CartProductCard } from '.';
import { device } from '../../constants/responsive';

import CartEmptyIcon from '../../assets/cart_empty.svg'

const ListWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 15px 8px;
    @media ${device.desktop} {
        overflow: auto;
    }
`

const ProductWrapper = styled.div`
    padding-left: 24px;
    &:not(:last-child) {
        padding-bottom: 20px;
    }
`

const CartEmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    width: 140px;
`

const CartEmptyTitle = styled.span`
    color: ${props => props.theme.colors.lightGrey},
    font-style: italic;
    font-size: 13px;
    letter-spacing: 0.01em;
    padding: 10px 0 5px 0;
`

const CartEmptyDescription = styled.span`
    color: ${props => props.theme.colors.lightGrey},
    font-style: italic;
    font-size: 11px;
    letter-spacing: 0.01em;
    padding: 5px 0 10px 0;
`

const CartItemsList = ({items}) => 
(
    <ListWrapper className="cartList">
        {
            items && items.length > 0 && items.map( (item,i) => (
                <ProductWrapper key={`cart-product#${i}`}>
                    <CartProductCard 
                        product={item.product} 
                        quantity={item.quantity} 
                        checkedAddons={item.checkedAddons}
                        observation={item.observation}
                        total={item.total}
                        hash={item.hash}
                    />
                </ProductWrapper>
            ))
        }
        {
            (!items || items.length === 0) && (
                <CartEmptyWrapper>
                    <img src={CartEmptyIcon} alt="Carrinho Vazio" />
                    <CartEmptyTitle>
                        Carrinho Vazio
                    </CartEmptyTitle>
                    <CartEmptyDescription>
                        Você ainda não selecionou itens para compra.
                    </CartEmptyDescription>
                </CartEmptyWrapper> 
            )
        }
    </ListWrapper>
)

export default CartItemsList;
