import React from 'react';
import styled from 'styled-components';

import { Icon } from '../common'
import { DELIVERY_MODALITIES } from '../../constants/delivery';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    letter-spacing: -0.06em;
    padding: 15px 24px;
`

const Title = styled.span`
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    padding-bottom: 8px;
`

const DeliveryAddressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 100%;
`

const EditButton = styled.button`
    background: transparent;
    border: 0;
    padding: 0;
`

const DeliveryAddressText = styled.span`
    font-style: italic;
    font-weight: 300;
    padding-left: 10px;
    color: ${props => props.theme.colors.textPrimary};
    flex-grow: 1;
`

const SelectAddressText = styled.span`
    color: ${props => props.theme.colors.textAction};
    font-style: italic;
    font-weight: 300;
    flex-grow: 1;
    cursor: pointer;
`

const CartDeliveryAddressCard = ({address, modality, handleOpenAddressModal, showEdit, table}) => (
    <Wrapper>
        <Title>Local de Entrega</Title>
        <DeliveryAddressWrapper>
            {address && modality === DELIVERY_MODALITIES.DELIVERY && (
                <>
                    <DeliveryAddressText>
                        {`${address.street}, ${address.number} - ${address.neighborhood}`}
                        <br />
                        {`${address.city} - ${address.state}`}
                    </DeliveryAddressText>
                    <EditButton onClick={handleOpenAddressModal}>
                        {
                            showEdit && <Icon name="edit" />
                        }
                    </EditButton>
                </>)
            }

            {(!address || !table) && (modality !== DELIVERY_MODALITIES.PICKUP && modality !== DELIVERY_MODALITIES.COUNTER && modality !== DELIVERY_MODALITIES.DELIVERY) && (
                <SelectAddressText onClick={handleOpenAddressModal}>
                    Escolher
                </SelectAddressText>
            )}

            {(!table) && (modality === DELIVERY_MODALITIES.PICKUP || modality === DELIVERY_MODALITIES.COUNTER) && (
                <>
                    <DeliveryAddressText>
                        Retirada em loja
                    </DeliveryAddressText>
                    {
                        showEdit && <EditButton onClick={handleOpenAddressModal}>
                            <Icon name="edit" />
                        </EditButton>
                    }
                </>
            )}

            { modality === DELIVERY_MODALITIES.COUNTER && table && (
                <>
                    <DeliveryAddressText>
                        Mesa: <span>{ table }</span>
                    </DeliveryAddressText>
                    {
                        showEdit && <EditButton onClick={handleOpenAddressModal}>
                            <Icon name="edit" />
                        </EditButton>
                    }
                </>
            )}
        </DeliveryAddressWrapper>
    </Wrapper>
)

export default CartDeliveryAddressCard