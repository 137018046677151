import React from 'react'
import { connect } from "react-redux"

import { BALCAO, MESA, MULTI_STORE } from '../../config'

import { Modal, Notification } from '../common'

import { toggleAddressModal } from '../../redux/actions/ui'
import { navigateTo } from '../../redux/actions/pages'
import {
  setDeliveryPickup,
  getStoreInfoAndMenu,
  setDeliveryCounter,
  getClosestStore
} from '../../redux/actions/store'
import {
  setNoDelivery
} from '../../redux/actions/cart'
import {
  searchCep,
  resetCepSearch,
  saveAddress,
  clearAddress,
  saveTable,
} from '../../redux/actions/address'

import { clearCartModality } from '../../redux/actions/cart'
import { handleGTM } from '../../redux/actions/gtm'

import {
  CepSearch,
  AddressDetailForm,
  Modality,
  Table
} from './'

import AddressInvalid from './AddressInvalid'

export const MODAL_STATES = {
  CEP_SEARCH: 0,
  EDIT_ADDRESS: 1,
  INVALID_ADDRESS: 2,
  MODALITY: 3,
  TABLE: 4
}

class AddressModal extends React.Component {

  constructor(props) {
		super(props)
		
    this.state = {
      foundCepAddress: null,
      error: null
    }
  }

  componentDidMount() {
    if (this.props.deliveryModality === 1 && this.props.deliveryTable) {
      this.props.toggleAddressModal({ state: MODAL_STATES.MODALITY })

      return
    }
  }

  dismissError = () => {
    this.setState({error: null});
  }

  resetModal = () => {
    this.props.toggleAddressModal({ show: true, state: MODAL_STATES.CEP_SEARCH })
  }

  handleClose = () => {
    this.setState({ foundCepAddress: null })
    this.props.toggleAddressModal({ show: false })
    this.props.resetCepSearch()
  }

  searchCep = async (cep) => {
    const result = await this.props.searchCep(cep);
    if (result && result.address && result.success) {
      this.setState({
        foundCepAddress: result.address, 
      });
      this.props.setNoDelivery()
      this.props.toggleAddressModal({show: true, state: MODAL_STATES.EDIT_ADDRESS})
    } else {
      this.setState({ error: result.error || `Não foi possível encontrar o CEP informado` })
    }
  }

  setPickup = () => {
    this.props.setDeliveryPickup();
    this.handleClose();
  }

  saveAddress = async (address) => {
    const result = await this.props.saveAddress(address)
    if (result.success) {
      if (!result.valid) {
        this.props.toggleAddressModal({show: true, state: MODAL_STATES.INVALID_ADDRESS})

        return
      }

      this.props.getStoreInfoAndMenu(this.props.storeId)
      this.props.clearCartModality()
      this.handleClose()

      if (!this.props.storeId && MULTI_STORE === 'true') {
        await this.props.getClosestStore()
        window.location.reload(false)
      }

      return
    }

    this.setState({ error: result.error })
  }

  clearAddress = async () => {
    const result = await this.props.clearAddress()

    if (result.success) {
      this.props.setDeliveryPickup()

      this.handleClose()

      return
    }

    this.setState({ error: result.error })
  }

  saveTable = async (data) => {
    const result = await this.props.saveTable(data.table)

    if (!result) {
      this.setState({ error: result.error })
    }

    this.handleClose()
    
    if (this.props.callback && typeof this.props.callback === 'function') {
      this.props.callback()
    }
  }
  addEventGTM = (args)=>{
    this.props.handleGTM(args)
  }

  handleModality = async ({
    modality,
    table
  }) => {
    if (modality === 1 && !!table) {
      const result = await this.props.clearAddress()

      if (result.success) {
        this.props.setDeliveryCounter()
        this.props.toggleAddressModal({ state: MODAL_STATES.TABLE })
        this.props.getStoreInfoAndMenu(this.props.storeId)
        this.props.clearCartModality()

        return
      }

      this.setState({ error: result.error })
    }

    if (modality === 1 && !table) {
      const result = await this.props.clearAddress()

      if (result.success) {
        this.props.setDeliveryCounter()
        this.props.getStoreInfoAndMenu(this.props.storeId)
        this.props.clearCartModality()
        this.handleClose()

        return
      }

      this.setState({ error: result.error })
    }

    if (modality === 2 && !table) {
      const result = await this.props.clearAddress()

      if (result.success) {
        this.props.setDeliveryPickup()
        this.handleClose()
        this.props.getStoreInfoAndMenu(this.props.storeId)
        this.props.clearCartModality()

        return
      }

      this.setState({ error: result.error })
    }
  }

  handlePickupModality = async () => {
    if (BALCAO === 'true' || MESA === 'true') {
      this.props.toggleAddressModal({ state: MODAL_STATES.MODALITY })

      return
    }

    await this.props.setDeliveryPickup()
    this.handleClose()    
    this.props.getStoreInfoAndMenu(this.props.storeId)
    this.props.clearCartModality()
  }

  renderModalContent = () => {
    const {
      currentState,
      cepLoading,
      saveAddressLoading
    } = this.props

    switch(currentState) {
      case MODAL_STATES.CEP_SEARCH:
        return <CepSearch loading={cepLoading} addEventGTM={this.addEventGTM} searchCep={this.searchCep} handlePickupModality={() => { this.handlePickupModality() }} />
      case MODAL_STATES.MODALITY:
        return <Modality handleModality={this.handleModality} />
      case MODAL_STATES.TABLE:
        return <Table handleSaveTable={this.saveTable} />
      case MODAL_STATES.EDIT_ADDRESS:
        return <AddressDetailForm loading={saveAddressLoading}  addEventGTM={this.addEventGTM} saveAddress={this.saveAddress} address={this.state.foundCepAddress || this.props.address}/>
      case MODAL_STATES.INVALID_ADDRESS:
        return <AddressInvalid onBack={this.resetModal} />
      default:
        return <></>
    }
  }

  render() {
    return (
      <Modal name="address" isOpen={this.props.isOpen} onClose={this.handleClose} disableClose={this.props.disableClose}>
        <Notification type="error" text={this.state.error} onDismiss={this.dismissError} timeout={5} />

        {this.renderModalContent()}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    storeId: state.store && state.store.id,
    isOpen: state.ui.modals.addressModal.show,
    currentState: state.ui.modals.addressModal.state,
    cepLoading: state.requests.searchCep.loading,
    saveAddressLoading: state.requests.saveAddress.loading,
    deliveryModality: state.cart.delivery.modality,
    deliveryTable: state.cart.delivery.table,
    address: state.requests.saveAddress && state.requests.saveAddress.address
  }
}

export default connect(
  mapStateToProps, {
    toggleAddressModal,
    setDeliveryPickup,
    setDeliveryCounter,
    searchCep,
    resetCepSearch,
    saveAddress,
    clearAddress,
    clearCartModality,
    saveTable,
    navigateTo,
    getClosestStore,
    setNoDelivery,
    handleGTM,
    getStoreInfoAndMenu
  }
)(AddressModal)
