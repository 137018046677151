import React from 'react'
import styled from 'styled-components'

import {
  TextInput,
  Button
} from '../common'
import { notEmpty } from '../common/form/validationFunctions'
import { Form } from '../common/form/'
import { device } from '../../constants/responsive'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  @media ${device.desktop} {
    width: 300px;
  }
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
  color: #545454;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #949494;
  margin: 0px;
  margin-bottom: 20px;
  padding: 0px;
`;

const SingleColumn = styled.div`
  width: 100%;
  text-align: center;

  > div {
    width: 150px;
    margin: 0px auto;
    display: block;
    height: auto;
    margin-bottom: 25px;

    > div {
      :first-child {
        font-size: 28px;
      }
    }
  }

  input {
    margin: 0px;
    margin-top: 10px;
    width: 100%;
    font-size: 28px;
  }
`;

class CVVForm extends React.Component {
  constructor(props) {
    super(props)
    
    this.form = this.setup()
  }

  handleSaveCVV = (cvv) => {
    this.props.saveCVV(cvv.cardCVV)

    if(typeof this.props.callback === 'function') {
      this.props.callback()
    }
  }

  setup = () => [{
    id: 'cardCVV',
    validations: [notEmpty],
    Component: (formInputProps) => (
      <TextInput {...formInputProps} type='text' name='cardCVV' placeholder='CVV' center 
        inputProps={{
          maxLength: 4
        }}
      />
    )
  }]

  render() {
    return (
      <Wrapper>
        <Form formInputs={this.form} onSubmit={this.handleSaveCVV}>
          {
            (inputs) => {
              return (
                <div>
                  <Title>Confirmação de Compra</Title>

                  <Paragraph>Confirme sua compra digitando o código de segurança de seu cartão</Paragraph>
                  
                  <SingleColumn>
                    {<inputs.cardCVV />}
                  </SingleColumn>

                  <Button type='submit' className='primary'>
                    CONFIRMAR
                  </Button>
                </div>
              )
            }
          }
        </Form>
      </Wrapper>
    );
  }
}

CVVForm.propTypes = {}

CVVForm.defaultProps = {}

export default CVVForm
