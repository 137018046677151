import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from '../../common';
import { getCardBrandImageById } from '../../../utils';
import { CARD_BRANDS_IDS, CARD_BRANDS_NAMES } from '../../../constants/payment';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
    text-align: center;
` 

const TitleText = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.colors.textPrimary};
`

const DescriptionText = styled.span`
    padding-top: 56px;
    padding-bottom: 52px;
    font-size: 14px;
    color: ${props => props.theme.colors.textPrimary};
`

const SliderWrapper = styled.div`
    width: 100%;
`

const BrandWrapper = styled.div`
    max-width: 52px;
`

const PrevArrow = styled.div`
    position: absolute;
    left: -15px !important;
    top: 0 !important;
`

const NextArrow = styled.div`
    position: absolute;
    right: -15px !important;
    top: 0 !important;
`

const BrandButton = styled.button`
    background: none;
    border: 1px solid white;
    border-radius: 5px;

    ${props => props.selected && `
        border: 1px solid ${props.theme.colors.primary};
    `}
`

const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: (<PrevArrow><Icon name="arrowLeft" /></PrevArrow>),
    nextArrow: (<NextArrow><Icon name="arrowRight" /></NextArrow>),
  };

export const CardSelectBrandForm = ({selectedBrand, onSelect}) => (
    <Wrapper>
        <TitleText>SELECIONE A BANDEIRA DO SEU CARTÃO</TitleText>
        <DescriptionText>
            Não conseguimos reconhecer sua bandeira. 
            <br />
            Por favor, indique-a abaixo:
        </DescriptionText>
        <SliderWrapper>
            <Slider {...sliderSettings}>
                {Object.values(CARD_BRANDS_IDS).map(brandId => (
                    <BrandWrapper key={`selectBrands#${brandId}`}>
                        <BrandButton onClick={(e) => onSelect(brandId)} selected={selectedBrand === brandId}>
                            <img src={getCardBrandImageById(brandId)} alt={CARD_BRANDS_NAMES[brandId]} />
                        </BrandButton>
                    </BrandWrapper>
                ))}
            </Slider>
        </SliderWrapper>
    </Wrapper>
)

export default CardSelectBrandForm;