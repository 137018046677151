import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../common';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const MainText = styled.span`
    font-size: 14px;
    color: ${props => props.theme.colors.mediumGrey}
`

const Cpf = styled.span`
    font-size: 14px;
    color: ${props => props.theme.colors.textPrimary}
    padding-left: 10px;
`

const CpfSelection = ({cpf, checked, onCheck}) => (
    <Wrapper>
        <Checkbox checked={checked} primary onCheck={onCheck}>
            <MainText>CPF na nota?</MainText>
            <Cpf>{cpf}</Cpf>
        </Checkbox>
    </Wrapper>
)

export default CpfSelection;