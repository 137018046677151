import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  MESA
} from '../config'

import { AddressModal } from '../components/address'
import { MODAL_STATES } from '../components/address/AddressModal'
import { DELIVERY_MODALITIES } from '../constants/delivery'
import { StoreSelectModal } from '../components/storeSelect'
import { Loading } from '../components/common'

import { toggleAddressModal } from '../redux/actions/ui'
import {
  getClosestStore,
  setSelectedStore,
	setDeliveryCounter,
  getAvailableCities,
  getAvailableStores,
  getStoreInfoAndMenu
} from '../redux/actions/store'
import {
  saveTable
} from '../redux/actions/address'

import {
	GetQueryString
} from '../common/helpers'
import {handleGTM} from '../redux/actions/gtm'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${props => props.theme.backgroundImage});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
`

const Overlay = styled.div`
  background-color: ${props => props.theme.colors.primary};
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
const BoxWrapper = styled.div`
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
`

class StoreSelectPage extends React.Component {

  /* async componentDidUpdate(prevProps) {
    if (!this.props.addressModalOpen && prevProps.addressModalOpen && this.props.deliveryModality === DELIVERY_MODALITIES.DELIVERY) {
      await this.props.getClosestStore()
		}
  } */

  async componentDidMount() {
    const queryString = GetQueryString()

		const table = !Object.keys(queryString).length ? null : {
      number: queryString.mesa || false
    }

		if (MESA === 'true' && table && table.number & table.number !== '0') {
			await this.props.setDeliveryCounter()
			await this.props.saveTable(table.number)

			this.props.toggleAddressModal({show: true, state: MODAL_STATES.CEP_SEARCH})

			return
		}

		if (!this.props.deliveryModality || this.props.deliveryModality === DELIVERY_MODALITIES.DELIVERY) {
			this.props.toggleAddressModal({show: true, state: MODAL_STATES.CEP_SEARCH})
			
			return
		}
		
		this.props.toggleAddressModal({show: false})
  }

  renderContent() {
    const {
      closestStoreLoading
    } = this.props

    if (closestStoreLoading) {
      return <Loading />
    } else if (this.props.deliveryModality === DELIVERY_MODALITIES.PICKUP || this.props.deliveryModality === DELIVERY_MODALITIES.COUNTER) {
      return <StoreSelectModal
        setSelectedStore={this.props.setSelectedStore}
        setDeliveryCounter={this.props.setDeliveryCounter}
        getAvailableCities={this.props.getAvailableCities}
        getAvailableStores={this.props.getAvailableStores}
        getStoreInfoAndMenu={this.props.getStoreInfoAndMenu}
        handleGTM={this.props.handleGTM}
      />
    } else {
      return <AddressModal selectStore={true} disableClose />
    }
  }

  render() {
    return (
      <Wrapper>
        <Overlay />
        <BoxWrapper>
          {this.renderContent()}
        </BoxWrapper>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    addressModalOpen: state.ui.modals.addressModal.show,
    deliveryModality: state.cart.delivery.modality,
    closestStoreLoading: state.requests.closestStore.loading,
    closestStoreLoaded: state.requests.closestStore.success,
    closestStoreError: state.requests.closestStore.error
  }
}

export default connect(mapStateToProps, {
	toggleAddressModal,
	setDeliveryCounter,
	getClosestStore,
  saveTable,
  setSelectedStore,
  handleGTM,
  getAvailableCities,
  getAvailableStores,
  getStoreInfoAndMenu
})(StoreSelectPage)
