import React from 'react'
import { connect } from 'react-redux'

import {
  Notification
} from '../common'
import { saveCVV } from '../../redux/actions/payment'

import { CVVForm } from './'

class CVV extends React.Component {
  constructor(props) {
		super(props)

    this.state = {
			filled: false,
			valid: false,
      error: null
    }
  }

  dismissError = () => {
    this.setState({ error: null })
  }

  saveCVV = async (cvv) => {
    const result = await this.props.saveCVV(cvv)
		
		if (result.success) {
      this.props.onSuccess()

			return
		}
		
		this.setState({ error: result.error })
  }

  render() {
    return (
      <>
        <Notification type="error" text={this.state.error} onDismiss={this.dismissError} timeout={5} />

        <CVVForm callback={this.props.onSuccess} saveCVV={this.saveCVV} />
      </>
    )
  }
}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {
	saveCVV
})(CVV)
