import moment from 'moment';
import LoginFormModel from '../models/loginFormModel';
import SignUpFormModel from '../models/signUpFormModel';
import User from '../models/user';

interface authResponse {
    user?: User,
    success: boolean
    message: string
}

interface CodeFormModel {
    code: string
}

interface PhoneFormModel {
    phone: string
}

interface UpdatePhoneFormModel {
    phone: string
    userId?: string | number
}

export const mapLoginFormToAPI = (form: LoginFormModel) : any => {
    return {
        "Email": form.email,
        "Senha": form.pass
    }
}

export const mapPhoneFormToAPI = (form: PhoneFormModel) : any => {
    return {
        "Telefone": form.phone
    }
}

export const mapUpdatePhoneFormToAPI = (form: UpdatePhoneFormModel) : any => {
    return {
        "Telefone": form.phone,
        "UsuarioId": form.userId
    }
}

export const mapSignUpFormToAPI = (form: SignUpFormModel) : any => {
    return {
        "Nome": form.name,
        "SobreNome": form.lastname,
        "Email": form.email,
        "Senha": form.pass,
        "ConfirmaSenha": form.confirm,
        "DataNascimento": form.birthdate,
        "Cpf": form.cpf,
        "Telefone": form.phone
    }
}

export const mapCodeFormToAPI = (form: CodeFormModel) : any => {
    return {
        "Codigo": form.code
    }
}

export const mapSaveForm = (form: any) : any => {
    return {
        ...form
    }
}

export const mapFbLoginFormToAPI = (form: any) : any => {
    return {
        "FacebookToken": form.accessToken,
        "Email": form.email,
        "FacebookUsuarioId": form.userID
    }
}


export const mapGoogleLoginFormToAPI = (form: any) : any => {
    return {
        "GoogleToken": form.accessToken,
        "Email": form.profileObj.email,
        "GoogleUsuarioId": form.googleId
    }
}

export const mapAuthAPIResponse = (response: any) : authResponse => {
    const expiresSeconds = response["Token"] && response["Token"]["ExpiresIn"];
    return {
        user: {
            id: response["UsuarioId"],
            authToken: response["AuthenticationToken"],
            name: response["Nome"],
            lastname: response["SobreNome"],
            email: response["Email"],
            cpf: response["Cpf"],
            phone: response["Telefone"],
            birthdate: response["DataNascimento"],
            mustUpdate: response["AtualizacaoObrigatoria"],
            hasUpdate: response["TemAtualizacao"],
            signUpStatus: response["StatusCadastro"],
            signUp: response["Cadastrar"],
            updateData: response["AtualizarDados"],
            loginType: response["TipoLogin"],
            token: {
                accessToken: response["Token"] && response["Token"]["AccessToken"],
                type: response["Token"] && response["Token"]["TokenType"],
                expires: moment().add("seconds", expiresSeconds).toISOString(),
            }
        },
        success: response["Sucesso"],
        message: response["Mensagem"],
    }
}

export const mapForgotPasswordFormToAPI = (email: string) : any => {
    return {
        "Email": email
    }
}