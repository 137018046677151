import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import GlobalStyle from './GlobalStyle'

import { getStore } from './redux/store'

import { THEME } from './config'
import Routes from './Routes'
import history from './history'
import { NotificationCenter } from './components/common'

const CURRENT_VERSION = '1.0.1'

class App extends React.Component {
  constructor(props) {
    super(props)

    const localStorageVQP = localStorage.getItem('vqp') ? JSON.parse(window.localStorage.getItem('vqp')) : null

    if (localStorageVQP && localStorageVQP.version && localStorageVQP.version !== CURRENT_VERSION) {
      window.localStorage.removeItem('vqp')
      window.localStorage.removeItem('checkout')
      window.localStorage.removeItem('cart')
      window.localStorage.removeItem('store')
      window.localStorage.removeItem('deviceToken')
      window.localStorage.removeItem('auth')
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('gtm')

      const initialLocalStorageVersion = { version: CURRENT_VERSION }

      window.localStorage.setItem('vqp', JSON.stringify(initialLocalStorageVersion))
    }

    if (!localStorageVQP) {
      const initialLocalStorageVersion = { version: CURRENT_VERSION }

      window.localStorage.removeItem('vqp')
      window.localStorage.removeItem('checkout')
      window.localStorage.removeItem('cart')
      window.localStorage.removeItem('store')
      window.localStorage.removeItem('deviceToken')
      window.localStorage.removeItem('auth')
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('gtm')
      window.localStorage.setItem('vqp', JSON.stringify(initialLocalStorageVersion))
    }

    const localStorageStore = JSON.parse(localStorage.getItem('store'))
    const pathname = window.location.pathname
    const url = pathname.split('/')
    const storeId = url && url[1]

    if (pathname === '/') {
      window.localStorage.removeItem('gtm')
    }
    
    this.basePath = window.location.pathname
    this.storeId = parseInt(Number(storeId)) ? storeId : (localStorageStore && localStorageStore.id)

    console.warn('storeId', this.storeId)
  }

  render() {
    const store = getStore()

    return (
      <Router history={history} basePath={this.basePath}>
        <ThemeProvider theme={THEME}>
          <Provider store={store}>
            <GlobalStyle />
            <NotificationCenter />
            <Routes storeId={this.storeId} />
          </Provider>
        </ThemeProvider>
      </Router>
    )
  }
}

export default App
