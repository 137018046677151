import React from 'react'
import styled from 'styled-components'

import FormInput from './FormInput';

const Wrapper = styled.form`
    min-width: 100%;
    text-align: center;
    flex: 1;
    flex-direction: column;
`

class Form extends React.Component {

    constructor(props) {
        super(props);
        const values = {}

        if (this.props.formInputs) {
            this.props.formInputs.forEach(input => values[input.id] = input.defaultValue || '');
        }

        if (this.props.phoneFormInputs) {
            this.props.phoneFormInputs.forEach(input => values[input.id] = input.defaultValue || '');
        }

        if (this.props.codeFormInputs) {
            this.props.codeFormInputs.forEach(input => values[input.id] = input.defaultValue || '');
        }

        this.state = {
            values
        }
        this.form = React.createRef();
        this.refs = {}

        this.inputs = null
        this.phoneInputs = null
    }

    componentWillMount() {
        if (this.props.formInputs) {
            this.setupFormInputs();
        }

        if (this.props.phoneFormInputs) {
            this.setupPhoneFormInputs();
        }

        if (this.props.codeFormInputs) {
            this.setupCodeFormInputs();
        }
    }

    setupFormInputs = () => {
        this.inputs = {}
        this.props.formInputs.forEach( (input, i) => {
            const formInput = () => <FormInput 
                value={this.state.values[input.id]}
                onRef={ref => {
                    const refs = {...this.refs}
                    refs[input.id] = ref
                    this.refs = refs
                }}
                id={input.id}
                onChange={this.handleInputChange}
                validations={input.validations}
                inputProps={input.inputProps}
                InputComponent={input.Component}
            />
            this.inputs[input.id] = formInput;
            this.inputs[i] = formInput;
        })
    }

    setupPhoneFormInputs = () => {
        this.phoneInputs = {}
        this.props.phoneFormInputs.forEach( (input, i) => {
            const formInput = () => <FormInput 
                value={this.state.values[input.id]}
                onRef={ref => {
                    const refs = {...this.refs}
                    refs[input.id] = ref
                    this.refs = refs
                }}
                id={input.id}
                onChange={this.handlePhoneInputChange}
                inputProps={input.inputProps}
                InputComponent={input.Component}
            />
            this.phoneInputs[input.id] = formInput;
            this.phoneInputs[i] = formInput;
        })
    }

    setupCodeFormInputs = () => {
        this.codeInputs = {}
        this.props.codeFormInputs.forEach( (input, i) => {
            const formInput = () => <FormInput 
                value={this.state.values[input.id]}
                onRef={ref => {
                    const refs = {...this.refs}
                    refs[input.id] = ref
                    this.refs = refs
                }}
                id={input.id}
                onChange={this.handleCodeInputChange}
                inputProps={input.inputProps}
                InputComponent={input.Component}
            />
            this.codeInputs[input.id] = formInput;
            this.codeInputs[i] = formInput;
        })
    }

    handleInputChange = (e) => {
        const newValues = {...this.state.values}
        newValues[e.target.id] = e.target.value;
        this.setState({values: newValues})
        if (this.props.onChange) {
            this.props.onChange(e.target.id, e.target.value);
        }
    }

    handlePhoneInputChange = (e) => {
        const newValues = {...this.state.values}
        newValues[e.target.id] = e.target.value;
        this.setState({values: newValues})
        if (this.props.onChange) {
            this.props.onChange(e.target.id, e.target.value);
        }
    }

    handleCodeInputChange = (e) => {
        const newValues = {...this.state.values}
        newValues[e.target.id] = e.target.value;
        this.setState({values: newValues})
        if (this.props.onChange) {
            this.props.onChange(e.target.id, e.target.value);
        }
    }
    
    validateInput = (inputId) => {
        if (this.refs[inputId]) {
            return this.refs[inputId].validate();
        }
        return null;
    }

    validateForm = () => {
        let allValid = true;
        Object.values(this.refs).forEach(input => {
            if (input && !input.validate()) {
                allValid = false;
            }
        }, true)
        return allValid;
    }

    getFormValues = () => {
        return this.state.values;
    }
    
    submit = () => {
        this.handleSubmit(new Event('submit'));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.props.formInputs && this.validateForm()) {
            const values = {}
            this.props.formInputs.forEach(input => {
                if (input.beforeSubmit) {
                    values[input.id] = input.beforeSubmit(this.state.values[input.id]);
                } else {
                    values[input.id] = this.state.values[input.id];
                }
            })
            if (this.props.onSubmit) {
                this.props.onSubmit(values)
            }   

            return
        }

        if (this.props.phoneFormInputs) {
            const values = {}
            this.props.phoneFormInputs.forEach(input => {
                if (input.beforeSubmit) {
                    values[input.id] = input.beforeSubmit(this.state.values[input.id]);
                } else {
                    values[input.id] = this.state.values[input.id];
                }
            })
            if (this.props.onSubmit) {
                this.props.onSubmit(values)
            }   
        }

        if (this.props.codeFormInputs) {
            const values = {}
            this.props.codeFormInputs.forEach(input => {
                if (input.beforeSubmit) {
                    values[input.id] = input.beforeSubmit(this.state.values[input.id]);
                } else {
                    values[input.id] = this.state.values[input.id];
                }
            })
            if (this.props.onSubmit) {
                this.props.onSubmit(values)
            }   
        }
    }

    render () {
        const renderMethod = this.props.children

        if (this.inputs) {
            return (
                <Wrapper autoComplete={this.props.autoComplete} onSubmit={this.handleSubmit} ref={this.form}>
                    { renderMethod(this.inputs) }
                </Wrapper>
            );
        }

        if (this.phoneInputs) {
            return (
                <Wrapper autoComplete={this.props.autoComplete} onSubmit={this.handleSubmit} ref={this.form}>
                    { renderMethod(this.phoneInputs) }
                </Wrapper>
            );
        }

        if (this.codeInputs) {
            return (
                <Wrapper autoComplete={this.props.autoComplete} onSubmit={this.handleSubmit} ref={this.form}>
                    { renderMethod(this.codeInputs) }
                </Wrapper>
            );
        }

        return <></>
    }
}

export default Form;