import { createStore, applyMiddleware } from "redux";
import uuidv4 from "uuid/v4"
import reducers from "./reducers";
import thunk from 'redux-thunk';
import axios from 'axios';
import { composeWithDevTools } from 'redux-devtools-extension';
import { APP_ID, API_URL, PLATAFORM_ID } from '../config'

const getDeviceToken = () => {
  const savedToken = localStorage.getItem('deviceToken');
  if (savedToken) return savedToken
  
  const newToken = uuidv4();
  localStorage.setItem('deviceToken', newToken)
  return newToken
}

const api = axios.create({ //all axios can be used, shown in axios documentation
    responseType: 'json',
    baseURL: API_URL,
    headers: {
      "Content-Type":"application/json",
      "Idioma":"pt-br",
      "Plataforma": PLATAFORM_ID,
      "Versao":"3.12",
      "DeviceToken": getDeviceToken(),
      "AplicativoId": APP_ID,
      "OneSignalPlayerId": ""
    }
});

const store = createStore(
    reducers, 
    composeWithDevTools(
      applyMiddleware(thunk.withExtraArgument(api))
    ),
);

export const getStore = () => createStore(
  reducers, 
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument(api))
  ),
);

export default store;