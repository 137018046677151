import React from 'react';
import styled from 'styled-components';
import { Modal, TextInput, Button, Box } from '../common';
import { numberToCurrency, currencyToNumber } from '../../utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 40px;
    min-width: 320px;
`

const TitleText = styled.span`
    font-size: 18px;
    font-weight: 700;   
    color: ${props => props.theme.colors.primary};
    padding: 20px 0;
`

const DescriptionText = styled.span`
    font-size: 13px;
    color: ${props => props.theme.colors.textPrimary};
`

const InputWrapper = styled.div`
    padding: 20px 0;
    width: 100%;
`

const ButtonWrapper = styled.div`
    padding: 20px 0;
    width: 100%;
`

class CashChangeModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cashChange: null
        }
    }

    handleConfirm = (e) => {
        if (this.props.onConfirm) {
            this.props.onConfirm(currencyToNumber(this.state.cashChange))
        }
    }

    handleChange = (e) => {
        this.setState({
            cashChange: e.target.value
        })
    }

    render() {
        return (
            <Modal name="cashChange" isOpen={this.props.isOpen} onClose={this.props.onClose}>
                <Box>
                    <Wrapper>
                        <TitleText>Precisa de troco?</TitleText>
                        <DescriptionText>
                        Seu pedido deu {numberToCurrency(this.props.total)}. <br />Se precisar de troco informe abaixo o valor que vai pagar para levarmos o seu troco.
                        </DescriptionText>
                        <InputWrapper>
                            <TextInput value={this.state.cashChange} onChange={this.handleChange} fontSize={"19px"} center placeholder="000,00" />
                        </InputWrapper>
                        <ButtonWrapper>
                            <Button stretch className="action" onClick={this.handleConfirm}>CONFIRMAR</Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Button stretch className="primary" onClick={this.props.onClose}>NÃO PRECISO DE TROCO</Button>
                        </ButtonWrapper>
                    </Wrapper>
                </Box>
            </Modal>
        )
    }
}

export default CashChangeModal;