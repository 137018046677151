import React, {
  useState,
  useEffect
} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  fetchFridgeData
} from '../../redux/actions/store'

import {
  Header
} from './header'

import {
  Content
} from './content'

const AppWrapper = styled.div`
  display : flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  flex: 1;
`

function Fridge(props) {
  const [content, setContent] = useState('home')

  const currentContent = { value: null }

  useEffect(() => {
    async function getFridge() {
      await props.fetchFridgeData()
    }

    getFridge()
  }, [props])

  if (typeof content === 'object') {
    Object.assign(currentContent, {
      value: {
        ...content
      }
    })
  }

  if (typeof content === 'string') {
    Object.assign(currentContent, {
      value: content
    })
  }

  return <AppWrapper>
    <Header name={'Pegue & Pague'} />

    <Content name={typeof content === 'object' ? currentContent.value.content : currentContent.value} setContent={setContent} fridgeId={currentContent.value.fridgeId} />
  </AppWrapper>
}

const mapStateToProps = (state) => {
  return {
    storeId: state.store.id,
    storeName: state.store.name
  }
}

export default connect(mapStateToProps, {
  fetchFridgeData
})(withRouter(Fridge))
