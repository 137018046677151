import React from 'react'
import styled from 'styled-components'

import {
  CheckOutReceipt
} from '..'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  margin-top: 0px;
`

function Content(props) {
  const {
    name,
    setContent
  } = props

  const setHighOrderContent = (content) => {
    setContent(content)
  }

  return (
    <Wrapper>
      { name === 'checkout-receipt' && <CheckOutReceipt setContent={setHighOrderContent} /> }
    </Wrapper>
  )
}

export default Content
