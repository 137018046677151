import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const AvailableCardsText = styled.span`
    color: ${props => props.theme.colors.textBold};
    font-size: 14px;
    font-style: italic;
    padding-bottom: 15px;
`

const BrandsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 18px;
`

const BrandImage= styled.img`
    max-width: 30px;
`

const AvailableCardBrandsBox = ({images}) => (
    <Wrapper>
        <AvailableCardsText>
            Bandeiras disponíveis
        </AvailableCardsText>
        <BrandsGrid>
            {images && images.map(image => (<BrandImage key={image} src={image} />))}
        </BrandsGrid>
    </Wrapper>
)

export default AvailableCardBrandsBox;