import React, {
  useState,
  useEffect
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import { device } from '../../../constants/responsive'

import { navigateTo } from '../../../redux/actions/pages'
import { clearCartStorage, resetCart } from '../../../redux/actions/cart'

import {
  fetchEcommerceCheckoutData,
  fetchEcommerceCheckoutRequestNumber,
  fetchEcommerceCheckoutOrderStatus,
  postEcommerceCheckoutFinishOrder
} from '../../../redux/actions/ecommerce'

import {
  PickupReceipt,
  DeliveryReceipt,
  CounterReceipt,
  TableReceipt
} from './'

const extractTimeFromDeliveryEstimate = (deliveryEstimate) => {
  if (typeof deliveryEstimate === 'string') {
    const formatted = deliveryEstimate.replace('Previsão de entrega:', '').trim();

    return formatted;
  }
}

const Wrapper = styled.div`
  background: #FFF;
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 960px;
  margin-top: 0px;
  padding: 40px 0px;

  @media ${ device.desktop } {
    background: #F9F9F9;
  }
`;

function CheckOutReceipt(props) {
  const {
    userName,
    cpf,
    cardBrand,
    cardNumber,
    useCpf,
    modality,
    checkoutId,
    deliveryTime,
    deliveryTable,
    cashChange,
    storeAddress
  } = props

  const [status, setStatus] = useState(null)
  const [notification, setNotification] = useState(null)
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [orderDate, setOrderDate] = useState(null)
  const [requestNumber, setRequestNumber] = useState(null)
  const [requestNumberStatus, setRequestNumberStatus] = useState(false)
  const [products, setProducts] = useState([])

  useEffect(() => {
    const localStorageCheckout = localStorage || '{}'

    async function getOrderStatus() {
      const result = await props.fetchEcommerceCheckoutOrderStatus({
        checkoutId,
        modality
      })

      setNotification(result.notification)

      if (modality === 1 && result.completed) {
        setStatus(2)

        const localStorageCheckoutData = JSON.parse(localStorageCheckout.getItem('checkout'))
        const localStorageCheckoutDataString =  { ...localStorageCheckoutData, status: 2 }

        localStorage.setItem('checkout', JSON.stringify(localStorageCheckoutDataString))

        return
      }

      if (modality === 1 && !result.completed) {
        setTimeout(() => getOrderStatus(), 5000)

        return
      }

      if (modality === 2 && result.completed) {
        setStatus(2)

        const localStorageCheckoutData = JSON.parse(localStorageCheckout.getItem('checkout'))
        const localStorageCheckoutDataString =  { ...localStorageCheckoutData, status: 2 }

        localStorage.setItem('checkout', JSON.stringify(localStorageCheckoutDataString))

        return
      }

      if (modality === 2 && !result.completed) {
        setTimeout(() => getOrderStatus(), 5000)

        return
      }

      if (modality === 4
        && result.notification
        && result.notification.status >= 5
        && result.notification.statuses[3].active === true) {
        setStatus(2)

        const localStorageCheckoutData = JSON.parse(localStorageCheckout.getItem('checkout'))
        const localStorageCheckoutDataString =  { ...localStorageCheckoutData, status: 2 }

        localStorage.setItem('checkout', JSON.stringify(localStorageCheckoutDataString))

        return
      }

      if (modality === 4 && result.notification && result.notification.status < 5) {
        setTimeout(() => getOrderStatus(), 5000)

        return
      }
    }

    async function getRequestNumber() {
      const result = await props.fetchEcommerceCheckoutRequestNumber(checkoutId)

      const {
        requestNumber
      } = result

      if (requestNumber > -1) {
        setRequestNumber(requestNumber)
        setRequestNumberStatus(true)

        const localStorageCheckoutData = JSON.parse(localStorageCheckout.getItem('checkout') || '{}')
        const localStorageCheckoutDataString =  { ...localStorageCheckoutData, requestNumber }

        localStorage.setItem('checkout', JSON.stringify(localStorageCheckoutDataString))

        await getOrderStatus()

        return
      }

      setTimeout(() => getRequestNumber(), 5000)
    }

    async function getStatus() {
      if (requestNumber) {
        return
      }
      
      const result = await props.fetchEcommerceCheckoutData({
        checkoutId,
        modality
      })

      if (!result) {
        return
      }

      setProducts({
        list: result.list,
        summary: result.summary
      })
      setNotification(result.notification)
      setDeliveryAddress({
        address: result.deliveryAddress,
        modality
      })
      setOrderDate(result.orderDate)

      localStorage.setItem('checkout', `{"products": ${JSON.stringify({
        list: result.list,
        summary: result.summary
      })}, "orderDate": ${JSON.stringify(result.orderDate)}, "deliveryAddress": ${JSON.stringify(result.deliveryAddress)}, "notification": ${JSON.stringify(result.notification)}, "id": ${checkoutId}}`)

      setStatus(1)

      if (modality === 1 && result.requestNumber > -1) {
        setStatus(1)

        await getRequestNumber()

        return
      }

      if (modality === 2 && result.requestNumber > -1) {
        setStatus(1)

        await getRequestNumber()

        return
      }

      if (modality === 4 && result.status >= 1) {
        setStatus(1)

        await getOrderStatus()

        return
      }

      if (!requestNumber) {
        setTimeout(() => getStatus(), 5000)
      }
    }

    const localStorageCheckoutData = JSON.parse(localStorageCheckout.getItem('checkout'))

    if (localStorageCheckoutData && localStorageCheckoutData.id === checkoutId && localStorageCheckout.status === 2) {
      setProducts(localStorageCheckout.products)
      setStatus(localStorageCheckout.status)
      setNotification(localStorageCheckout.notification)
      setDeliveryAddress({
        address: localStorageCheckout.deliveryAddress,
        modality
      })
      setOrderDate(localStorageCheckout.orderDate)
    }
    
    if (!requestNumberStatus) {
      setTimeout(() => getStatus(), 5000)
    }
  }, [props, checkoutId, modality, requestNumber, requestNumberStatus])

  const handleStatus = (status) => {
    setStatus(status)
  }

  const handleFinishOrder = async (finishOrderRequest) => {
    setStatus(null)

    props.clearCartStorage()
    props.resetCart()
    props.navigateTo('/')
    
    if (!!finishOrderRequest) {
      await props.postEcommerceCheckoutFinishOrder(checkoutId)
    }
  }

  return <Wrapper>
    {
      modality === 1 && deliveryTable && <TableReceipt
        userName={userName}
        status={status}
        requestNumber={requestNumber}
        orderDate={orderDate}
        deliveryTime={deliveryTime}
        products={products}
        cashChange={cashChange}
        cpf={cpf}
        useCpf={useCpf}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        tableNumber={deliveryTable}
        handleFinishOrder={() => handleFinishOrder(true)}
      />
    }

    {
      modality === 1 && !deliveryTable && <CounterReceipt
        userName={userName}
        status={status}
        requestNumber={requestNumber}
        orderDate={orderDate}
        deliveryTime={deliveryTime}
        products={products}
        cashChange={cashChange}
        cpf={cpf}
        useCpf={useCpf}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        handleFinishOrder={() => handleFinishOrder(true)}
      />
    }

    {
      modality === 2 && <PickupReceipt
        userName={userName}
        status={status}
        storeAddress={storeAddress}
        requestNumber={requestNumber}
        orderDate={orderDate}
        deliveryTime={deliveryTime}
        products={products}
        cashChange={cashChange}
        cpf={cpf}
        useCpf={useCpf}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        handleFinishOrder={() => handleFinishOrder(true)}
      />
    }

    {
      modality === 4 && <DeliveryReceipt
        userName={userName}
        status={status}
        requestNumber={requestNumber}
        orderDate={orderDate}
        deliveryTime={deliveryTime}
        products={products}
        cpf={cpf}
        useCpf={useCpf}
        cashChange={cashChange}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        notification={notification}
        deliveryAddress={deliveryAddress}
        handleFinishOrder={() => handleFinishOrder(false)}
        handleStatus={handleStatus}
      />
    }
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeName: state.store && state.store.name,
    storeAddress: state.store && state.store.address,
    userName: state.user && state.user.name,
    cpf: state.user && state.user.cpf,
    useCpf: state.cart.useCpf,
    cashChange: state.cart.cashChange,
    cardBrand: state.cart && state.cart.payment && state.cart.payment.card && state.cart.payment.card.brandId,
    cardNumber: state.cart && state.cart.payment && state.cart.payment.card && state.cart.payment.card.cardNumber,
    checkoutId: state.cart && state.cart.order && state.cart.order.id,
    modality: state.cart && state.cart.delivery && state.cart.delivery.modality,
    total: state.cart.total,
    paymentType: state.cart.payment.type,
    deliveryTime: state.cart.order.deliveryEstimate && extractTimeFromDeliveryEstimate(state.cart.order.deliveryEstimate),
    deliveryTable: state.cart.delivery && state.cart.delivery.table
  }
}

export default connect(
  mapStateToProps, {
    navigateTo,
    clearCartStorage,
    resetCart,
    fetchEcommerceCheckoutData,
    fetchEcommerceCheckoutRequestNumber,
    fetchEcommerceCheckoutOrderStatus,
    postEcommerceCheckoutFinishOrder
  }
)(withRouter(CheckOutReceipt))
