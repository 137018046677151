import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { goToAnchor } from 'react-scrollable-anchor'
import { handleGTM } from '../../../redux/actions/gtm'

import { CARDAPIO_CONSULTA } from "../../../config";

import { debounce } from '../../../utils/'
import { applyMenuFilter } from '../../../redux/actions/store'

import {
    SelectInput,
    Box
} from '../../common/'

import { SearchBar } from '../'

const NavBox = styled(Box)`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 75px;
    padding: 20px 42px 20px 42px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.16);
`

const NavPlaceholder = styled(NavBox)`
    margin-top: 20px;
`

const Wrapper = styled.div`
    background: #fff;
    padding-top: 20px;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    ${props => props.fixed && css`
        position: fixed;
        top: ${props.theme.dimensions.headerHeightDesktop}px;
        width: 80%;
        margin-left: -2px;
        padding-left: 2px;
        ${NavBox} {
            width: auto;
            margin-right: -2px;
        }
        ${CartSpacePlaceholder} {
            display: block;
        }
    `}
`

const CartSpacePlaceholder = styled.div`
    width: 326px;
    margin-left: 15px;
    display: none;
`

const CategoriesWrapper = styled.div`
    width: 50%;
    padding-right: 10px;
`

const SearchWrapper = styled.div`
    width: 50%;
    padding-left: 10px;
`

const AlternativeCategoriesWrapper = styled.div`
    width: 35%;
    padding-right: 10px;
    margin-left: 15%;
`

const AlternativeSearchWrapper = styled.div`
    width: 35%;
    padding-left: 10px;
    margin-right: 15%;
`

class MenuNavDesktop extends React.Component {

    handleSelect = (i) => {    
        
        if (i !== -1) {
            goToAnchor(this.props.categories[i].name);
        }
    }

    handleSearch = (term) => {
        debounce(this.props.applyMenuFilter, 300)(term)
    };

    render() {
        const { categories, fixed, selectedCategory } = this.props;
        const options = categories && categories.map(category => ({
            name: category.name,
            value: category.name
        }))
        return (
            <>
                <NavPlaceholder />
                <Wrapper fixed={fixed}>
                    {
                        CARDAPIO_CONSULTA === "false" && 
                        <>
                            <NavBox>
                                <CategoriesWrapper>
                                    <SelectInput options={options} selected={selectedCategory} onSelect={this.handleSelect} placeholder="Escolha a categoria do produto" />
                                </CategoriesWrapper>
                                <SearchWrapper>
                                    <SearchBar 
                                        icon="search" 
                                        onSearch={this.handleSearch} 
                                        placeholder="Buscar por produto"
                                    />
                                </SearchWrapper>
                            </NavBox> 
                            
                            <CartSpacePlaceholder />
                        </>
                    }

                    { CARDAPIO_CONSULTA === "true" && <NavBox>
                        <AlternativeCategoriesWrapper>
                            <SelectInput options={options} selected={selectedCategory} onSelect={this.handleSelect} placeholder="Escolha a categoria do produto" />
                        </AlternativeCategoriesWrapper>
                        <AlternativeSearchWrapper>
                            <SearchBar 
                                icon="search" 
                                onSearch={this.handleSearch} 
                                placeholder="Buscar por produto"
                            />
                        </AlternativeSearchWrapper>
                    </NavBox> }
                </Wrapper>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const selectedCategory = state.ui.navCurrentList;
    return {
        selectedCategory
    }
}

export default connect(
    mapStateToProps,
    { applyMenuFilter, handleGTM },
)(MenuNavDesktop);