export const actionTypes = {
  SET_NO_DELIVERY: 'SET_NO_DELIVERY',
  SET_DELIVERY_COUNTER: 'SET_DELIVERY_COUNTER',
  SET_DELIVERY_PICKUP: 'SET_DELIVERY_PICKUP',
  SET_DELIVERY_TABLE: 'SET_DELIVERY_TABLE',
  SET_DELIVERY_ADDRESS: 'SET_DELIVERY_ADDRESS',
  REMOVE_ITEM: 'REMOVE_ITEM',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
  SET_CPF_ON_RECEIPT: 'SET_CPF_ON_RECEIPT',
  SET_CASH_CHANGE: 'SET_CASH_CHANGE',
  SET_CART_COUPON: 'SET_CART_COUPON',
  SET_ORDER_COMPLETED: 'SET_ORDER_COMPLETED',
  CLEAR_CART_STORAGE: 'CLEAR_CART_STORAGE',
  DELETE_CART_COUPON: 'DELETE_CART_COUPON',
  RESET_CART: 'RESET_CART',
  CLEAR_CART_MODALITY: 'CLEAR_CART_MODALITY',
  SET_UNAVAILABLE_PRODUCTS: 'SET_UNAVAILABLE_PRODUCTS',
  REMOVE_PRODUCTS_ERROR: 'REMOVE_PRODUCTS_ERROR'
}

export const setNoDelivery = () => ({
    type: actionTypes.SET_NO_DELIVERY,
    payload: {
        pickupPrice: 0,
        deliveryPrice: 0
    }
});

export const setDeliveryPickup = () => ({
  type: actionTypes.SET_DELIVERY_PICKUP,
  payload: {
    pickupPrice: 0,
    deliveryPrice: 0
  }
})

export const setDeliveryCounter = () => ({
    type: actionTypes.SET_DELIVERY_COUNTER,
    payload: {
        pickupPrice: 0,
        deliveryPrice: 0
    }
});

export const setDeliveryAddress = ({
  address,
  modality
}) => ({
  type: actionTypes.SET_DELIVERY_ADDRESS,
  payload: {
    address,
    modality
  }
})

export const setUnavailableProducts = ({ error_items, errorType }) => ({
  type: actionTypes.SET_UNAVAILABLE_PRODUCTS,
  payload: {
    errorType,
    error_items
  }
})

export const setDeliveryTable = (table) => ({
    type: actionTypes.SET_DELIVERY_TABLE,
    payload: table
});

export const removeProductsError = (products_error) => ({
  type: actionTypes.REMOVE_PRODUCTS_ERROR,
  payload: products_error
});

export const removeCartItem = (product) => ({
  type: actionTypes.REMOVE_ITEM,
  payload: {
    product
  }
})

export const setPaymentType = ({type, card, isOffline}) => ({
  type: actionTypes.SET_PAYMENT_TYPE,
  payload: {
    type,
    card,
    isOffline
  }
})

export const addCartItem = ({product, quantity, checkedAddons, observation}) => ({
  type: actionTypes.ADD_ITEM,
  payload: {
    product,
    quantity,
    checkedAddons,
    observation,
  }
})

export const updateCartItem = ({product, quantity, checkedAddons, observation, hash}) => ({
  type: actionTypes.UPDATE_ITEM,
  payload: {
    product,
    quantity,
    checkedAddons,
    observation,
    hash
  }
})

export const setCpfOnReceit = (set) => ({
  type: actionTypes.SET_CPF_ON_RECEIPT,
  payload: set
})

export const setPaymentCashChange = (change) => ({
  type: actionTypes.SET_CASH_CHANGE,
  payload: change
})

export const setOrderCompleted = (orderResponse) => ({
  type: actionTypes.SET_ORDER_COMPLETED,
  payload: {
    orderId: orderResponse.orderId,
    deliveryEstimate: orderResponse.deliveryEstimate,
    deliveryStatus: orderResponse.deliveryStatus
  }
})

export const clearCartStorage = () => ({
  type: actionTypes.CLEAR_CART_STORAGE
})

export const clearCartModality = () => ({
  type: actionTypes.CLEAR_CART_MODALITY
})

export const resetCart = () => ({
  type: actionTypes.RESET_CART
})

export const setCartCoupon = ({id, code, value, type}) => ({
  type: actionTypes.SET_CART_COUPON,
  payload: {
    id,
    code,
    value,
    type
  }
})

export const deleteCartCoupon = () => ({
  type: actionTypes.DELETE_CART_COUPON
})
