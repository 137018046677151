
import mastercard from '../assets/card_brand_mastercard.svg';
import visa from '../assets/card_brand_visa.svg';
import amex from '../assets/card_brand_amex.svg';
import elo from '../assets/card_brand_elo.svg';
import hipercard from '../assets/card_brand_hipercard.svg';
import alelo from '../assets/card_brand_alelo.svg';
import ben from '../assets/card_brand_ben.svg';
import diners from '../assets/card_brand_diners.svg';
import sodexo from '../assets/card_brand_sodexo.svg';
import ticket from '../assets/card_brand_ticket.svg';
import vr from '../assets/card_brand_vr.svg';
import cash from '../assets/cash_payment.svg';

import { CARD_BRANDS_IDS } from '../constants/payment';

const images = {
    1: cash,
    [CARD_BRANDS_IDS.MASTERCARD]: mastercard,
    [CARD_BRANDS_IDS.VISA]: visa,
    [CARD_BRANDS_IDS.AMEX]: amex,
    [CARD_BRANDS_IDS.ELO]: elo,
    [CARD_BRANDS_IDS.HIPERCARD]: hipercard,
    [CARD_BRANDS_IDS.ALELO]: alelo,
    [CARD_BRANDS_IDS.BEN]: ben,
    [CARD_BRANDS_IDS.DINERS]: diners,
    [CARD_BRANDS_IDS.SODEXO]: sodexo,
    [CARD_BRANDS_IDS.TICKET_VR]: ticket,
    [CARD_BRANDS_IDS.TICKET_VA]: ticket,
    [CARD_BRANDS_IDS.VR]: vr,
    [CARD_BRANDS_IDS.CASH]: cash,
}

const getCardBrandImageById = (brandId) => {
    return images[brandId];
}

export default getCardBrandImageById;